import {
  InnerHeaderDataWrapper,
  InnerLine,
  BlockDetailsWrapper,
  DetailsTransactionTitle,
  DetailsTransactionSubTitle,
  IconColorWrapper,
  FormPayButton,
  FormDescription,
  InputFormActionWrapper,
} from "../../../styles";
import {
  ChallengeMainTitle,
  ChallengeUssdInfoWrapper,
  ChallengeNumberTitle,
  UssdCurrencyAmountWrapper,
} from "../style";
import Trans from "../../Trans";
import Tooltip from "../../Tooltip/Tooltip";
import IconCopy from "../../../assets/icons/iconCopy";

const ChallengeUssdDetails = ({
  challenge,
  transactionIdRef,
  reference,
  handleCopy,
  currency,
  amount,
}) => {
  const ussdCode = challenge?.ussd?.code;

  const handleDialCode = () => {
    if (ussdCode) {
      window.location.href = `tel:${ussdCode}`;
    }
  };

  return (
    <>
      <InnerHeaderDataWrapper style={{ paddingTop: "20px" }}>
        <ChallengeMainTitle style={{ marginBottom: "16px" }}>
          <Trans message="depositInfoMainTitle" />
        </ChallengeMainTitle>
        <ChallengeUssdInfoWrapper style={{ marginBottom: "12px" }}>
          <ChallengeNumberTitle>1</ChallengeNumberTitle>
          <span>
            <Trans message="dial" /> {challenge?.ussd?.code}{" "}
            <Trans message="onYourMobileDevice" />
          </span>
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper style={{ marginBottom: "20px" }}>
          <ChallengeNumberTitle>2</ChallengeNumberTitle>
          <span>
            <Trans message="paymentNotificationUssd" />
          </span>
        </ChallengeUssdInfoWrapper>
        <InnerLine style={{ width: "100%" }} />
        <BlockDetailsWrapper>
          <DetailsTransactionTitle
            style={{ width: "138px", textAlign: "justify" }}
          >
            <Trans message="transactionIdTitle" />
          </DetailsTransactionTitle>
          <span style={{ display: "flex", alignItems: "center" }}>
            <DetailsTransactionSubTitle
              style={{ marginRight: "8px" }}
              ref={transactionIdRef}
            >
              {reference}
            </DetailsTransactionSubTitle>
            <span
              onClick={() => handleCopy(reference, "transactionIdCopy")}
              style={{
                cursor: "pointer",
                color: "blue",
              }}
            >
              <IconColorWrapper>
                <Tooltip text={"Сopy"}>
                  <IconCopy />
                </Tooltip>
              </IconColorWrapper>
            </span>
          </span>
        </BlockDetailsWrapper>
      </InnerHeaderDataWrapper>

      <InputFormActionWrapper style={{ margin: "16px" }}>
        <FormDescription style={{ margin: "0 0 8px 32px" }}>
          <Trans message="amountToPay" />
        </FormDescription>
        <UssdCurrencyAmountWrapper>
          <b>{amount}</b>
          <span>{currency}</span>
        </UssdCurrencyAmountWrapper>
      </InputFormActionWrapper>
      <FormPayButton style={{ marginTop: "32px" }} onClick={handleDialCode}>
        {<Trans message="dial" />} {ussdCode}
      </FormPayButton>
    </>
  );
};

export default ChallengeUssdDetails;
