import StepLoader from "./StepLoader";
import GooeyLoader from "./GooeyLoader";
import FlexLoader from "./FlexLoader";
import BallLoader from "./BallLoader";
import AsquadLoader from "./AsquadLoader";
import LoaderLine from "./LoaderLine";
import KombineLoader from "./KombineLoader";
import { LoaderCenteredWrapper } from "../../styles";
import { useColorScheme } from "../../services/hooks";
import { state } from "../../services";
import { h } from "preact";

const loaders = {
  StepLoader: (props) => <StepLoader key={0} {...props} />,
  GooeyLoader: (props) => <GooeyLoader key={1} {...props} />,
  FlexLoader: (props) => <FlexLoader key={2} {...props} />,
  BallLoader: (props) => <BallLoader key={3} {...props} />,
  AsquadLoader: (props) => <AsquadLoader key={4} {...props} />,
  KombineLoader: (props) => <KombineLoader key={5} {...props} />,
};

const Loader = ({ size = "default" }) => {
  const { options } = state.get();

  const { colorTheme } = useColorScheme(options?.theme);

  const SelectedLoader =
    loaders[colorTheme?.loaderName] ||
    loaders[colorTheme?.hpp_loader_name] ||
    (() => <LoaderLine />);

  return (
    <LoaderCenteredWrapper>
      <SelectedLoader size={size} />
    </LoaderCenteredWrapper>
  );
};

export default Loader;
