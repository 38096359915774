import enIcon from "../assets/icons/enIcon.svg";
import uaIcon from "../assets/icons/uaIcon.svg";
import ruIcon from "../assets/icons/ruIcon.svg";
import ptIcon from "../assets/icons/ptIcon.svg";
import jptIcon from "../assets/icons/japanIcon.svg";
import trIcon from "../assets/icons/turkeyIcon.svg";
import plIcon from "../assets/icons/polandIcon.svg";
import esIcon from "../assets/icons/spainIcon.svg";
import koIcon from "../assets/icons/koreaIcon.svg";
import kzIcon from "../assets/icons/kzIcon.svg";

const LANGUAGE_LIST = [
  { locale: "en", icon: enIcon, title: "English" },
  { locale: "uk", icon: uaIcon, title: "Ukrainian" },
  { locale: "es", icon: esIcon, title: "Spanish" },
  { locale: "pt", icon: ptIcon, title: "Portuguese" },
  { locale: "pl", icon: plIcon, title: "Polish" },
  { locale: "tr", icon: trIcon, title: "Turkish" },
  { locale: "jp", icon: jptIcon, title: "Japanese" },
  { locale: "ko", icon: koIcon, title: "Korean" },
  { locale: "ru", icon: ruIcon, title: "russian" },
  { locale: "kz", icon: kzIcon, title: "Kazakh" },
];

export default LANGUAGE_LIST;
