import Trans from "../Trans";
import {
  CardLabelNumber,
  CardLabelDate,
  ActionButtonCardList,
  ActionButtonCardEditWrapper,
  DeleteCardPopupTitle,
  CardLabelNumberContainer,
} from "../../styles";
import { COLORS } from "../../constants/themeConstants";
import ModalItem from "../Modals/ModalComponents/ModalItem";
import { CARD_BRANDS } from "../../constants/cardBrand";

const ManageCardDefault = ({ card, handleRemoveCard, handleCancel }) => {
  return (
    <>
      <ModalItem
        icon={CARD_BRANDS[card?.brand]}
        iconWidth="32px"
        hasArray={false}
      >
        <CardLabelNumberContainer>
          <CardLabelNumber>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
            ?.toString()
            ?.slice(4, 6)}** **** ${card?.last4}`}</CardLabelNumber>
        </CardLabelNumberContainer>
        <CardLabelDate style={{ marginRight: 0, marginLeft: "auto" }}>{`${
          card?.exp_month
        }/${
          card?.exp_year?.length === 4
            ? card?.exp_year?.toString()?.slice(2, 4)
            : card.exp_year
        }`}</CardLabelDate>
      </ModalItem>
      <DeleteCardPopupTitle>
        <Trans message="dltCardSystemTitle" />
      </DeleteCardPopupTitle>
      <ActionButtonCardEditWrapper>
        <ActionButtonCardList
          type="button"
          background={COLORS.LIGHT_GRAY}
          color={COLORS.GRAY}
          onClick={handleCancel}
        >
          <Trans message="cancel" />
        </ActionButtonCardList>

        <ActionButtonCardList
          type="button"
          background={COLORS.DANGER}
          color={COLORS.DEFAULT_WHITE}
          width="288px"
          onClick={() => handleRemoveCard(card.identifier)}
        >
          <Trans message="delete" />
        </ActionButtonCardList>
      </ActionButtonCardEditWrapper>
    </>
  );
};

export default ManageCardDefault;
