import { useState, useEffect, useContext } from "react";
import {
  InnerHeaderDataWrapper,
  InnerLine,
  ButtonWrapper,
  BaseButton,
  InputFormActionWrapper,
  FormDescription,
  FormLoaderWrapper,
} from "../../../styles";
import Trans from "../../Trans";
import {
  ChallengeMainTitle,
  StyledInfoImage,
  ChallengeInfoWrapper,
} from "../style";
import InfoIcon from "../../../assets/icons/infoIcon";
import { Context } from "../../../app";
import { api, logger } from "../../../services";
import StepsList from "../ChallengeStepsList/StepsList";
import AmountDisplay from "./amountDisplay";
import AmountButtons from "./amountButtons";
import { getClosestAmounts } from "../../../constants/amountUtils";
import Loader from "../../Loaders";
import { ContextUI } from "../../../providers/UIProvider";
import { useMemo } from "preact/hooks";
import { ChallengeInfoMainTitleContainer } from "./style";

const BankTransferJapan = ({ challenge, amount, sessionId, signature }) => {
  const { getFormattedAmountParts } = useContext(Context);
  const { setNotificationData } = useContext(ContextUI);
  const [isLoading, setIsLoading] = useState(false);
  const suggestedAmounts = challenge?.select_amount?.amounts || [];

  const [selectedAmount, setSelectedAmount] = useState("");

  const steps = [
    { number: 1, title: "selectAmountToPay" },
    {
      number: 2,
      title: "clickSubmitToConfirm",
    },
    {
      number: 3,
      title: "afterSelectingAmount",
    },
  ];

  const closestAmounts = useMemo(() => {
    if (suggestedAmounts?.length === 1) {
      return [suggestedAmounts[0]];
    }

    return getClosestAmounts(suggestedAmounts, amount);
  }, [suggestedAmounts, amount]);

  useEffect(() => {
    if (closestAmounts?.length > 0 && !selectedAmount) {
      const numericAmount = parseFloat(amount);

      const filteredAmounts = closestAmounts.filter(
        (item) => parseFloat(item.amount) >= numericAmount,
      );

      const closest = (
        filteredAmounts?.length > 0 ? filteredAmounts : closestAmounts
      )?.reduce((prev, curr) =>
        Math?.abs(parseFloat(curr.amount) - numericAmount) <
        Math?.abs(parseFloat(prev.amount) - numericAmount)
          ? curr
          : prev,
      );

      setSelectedAmount(closest?.amount.toString());
    }
  }, [amount, closestAmounts, selectedAmount]);

  const handleSubmit = async (amountToSubmit) => {
    const challengeUrl = `/api/payment/challenge/select-amount/${sessionId}/${signature}`;
    const formattedValues = {
      amount: amountToSubmit?.toString(),
    };
    setIsLoading(true);
    try {
      if (formattedValues.amount) {
        await api.submitChallengeForm({
          sessionId,
          signature,
          values: formattedValues,
          challengeUrl,
          currentMethod: "bankTransfer",
        });
      }
    } catch (error) {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: true,
        status: "error",
        text: error,
        isCloseAutomatically: true,
      }));
      logger.log("Challenge select amount: complete payment failed", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const matchAmount = suggestedAmounts?.find(
      (item) => parseFloat(item.amount) === parseFloat(amount),
    );

    if (matchAmount) {
      setSelectedAmount(matchAmount.amount);
      handleSubmit(matchAmount.amount);
    }
  }, [amount]);

  return (
    <>
      <InnerHeaderDataWrapper pt="20px">
        <ChallengeInfoWrapper mt="20px">
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>

          <ChallengeInfoMainTitleContainer>
            <Trans message="initiatedAmountDeclinedTitle" />
            <Trans message="initiatedAmountDeclined" />
          </ChallengeInfoMainTitleContainer>
        </ChallengeInfoWrapper>

        <InnerLine $mt="16px" themeBackground />

        <ChallengeMainTitle $mb="16px">
          <Trans message="toCompleteThePaymentFollowSteps" />
        </ChallengeMainTitle>

        <StepsList steps={steps} />
      </InnerHeaderDataWrapper>

      <InputFormActionWrapper $margin="16px">
        <FormDescription $fw="700" $fs="14px" $margin="0 0 8px 32px">
          <Trans message="amountToPay" />
        </FormDescription>
        <AmountDisplay
          getFormattedAmountParts={getFormattedAmountParts}
          selectedAmount={selectedAmount}
        />{" "}
        <AmountButtons
          amounts={closestAmounts}
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
          getFormattedAmountParts={getFormattedAmountParts}
        />{" "}
      </InputFormActionWrapper>

      {!isLoading ? (
        <ButtonWrapper>
          <BaseButton
            textTransform="initial"
            onClick={() => handleSubmit(selectedAmount)}
            marginBottom="22px"
            textColor="#fff"
            disabled={!selectedAmount}
          >
            <Trans message="submit" />
          </BaseButton>
        </ButtonWrapper>
      ) : (
        <FormLoaderWrapper>
          <Loader />
        </FormLoaderWrapper>
      )}
    </>
  );
};

export default BankTransferJapan;
