import { FunctionalComponent } from "preact";
import Trans from "../Trans";
import { DetailsCryptoTitle } from "./style";
import { formatCryptoCurrency } from "../../constants/CryptoCurrencyIcon";

interface CryptoDisclaimerProps {
  currency: string;
}

const CryptoDisclaimer: FunctionalComponent<CryptoDisclaimerProps> = ({
  currency,
}) => (
  // @ts-ignore
  <DetailsCryptoTitle $textAlign="start" $mt="16px">
    <Trans message="pleaseSend" /> <b>{formatCryptoCurrency(currency, true)}</b>{" "}
    <Trans message="byCopyCryptoData" />
  </DetailsCryptoTitle>
);

export default CryptoDisclaimer;
