import { useState, useEffect } from "preact/hooks";
import { state, processing, i18n, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import controlDateInput from "../components/InputDate/InputDateControl";
import {
  FormErrorWrapper,
  FormSelectWrapper,
  FormErrorTitleCvv,
} from "../styles";

const InputDate = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRefMonth,
  nextStep,
  style,
  checkedCard,
}) => {
  const [focused, setFocused] = useState(false);
  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);
  const ControlInputDate =
    controlDateInput[currentTheme] || controlDateInput.default;
  const { showDateTooltip } = currentTheme || {};

  useEffect(() => {
    if (!ControlInputDate) {
      logger.error("Page not found");
    }
  }, []);

  const onDateInput = (e) => {
    let val = e.target.value.replace(/\s/g, "").replace(/[^\d]/g, "");

    const pointer = inputRefMonth.current.selectionStart;

    if (val.length > 4) {
      val = val.replace(/^(\d{2})(\d*)(\d{2})$/g, "$1$3");
    }

    setValues((prev) => ({ ...prev, date: val }));

    setTimeout(() => {
      inputRefMonth.current.selectionStart =
        pointer >= 3 ? pointer + 4 : pointer;
      inputRefMonth.current.selectionEnd = pointer >= 3 ? pointer + 4 : pointer;
    }, 0);

    if (val.length === 4) {
      if (!processing.checkDates(val.slice(0, 2), val.slice(2, 4))) {
        return setErrors((prev) => ({
          ...prev,
          date: CONST_ERROR_LIST.wrong_date,
        }));
      }

      setTimeout(() => {
        nextStep && nextStep.current && nextStep.current.focus();
      }, 0);

      return setErrors((prev) => {
        delete prev.date;

        return prev;
      });
    }
  };
  const onDateBlur = () => {
    setFocused(false);

    if (values.date.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        date: CONST_ERROR_LIST.required,
      }));
    }

    if (values.date.length < 4) {
      return setErrors((prev) => ({
        ...prev,
        date: CONST_ERROR_LIST.wrong_date,
      }));
    }

    if (values.date.length === 4) {
      if (
        !processing.checkDates(values.date.slice(0, 2), values.date.slice(2, 4))
      ) {
        return setErrors((prev) => ({
          ...prev,
          date: CONST_ERROR_LIST.wrong_date,
        }));
      }

      return setErrors((prev) => {
        delete prev.date;

        return prev;
      });
    }
  };

  const errorChecking = () => {
    if (errors.date) {
      if (focused) {
        return values.date.length === 4;
      }

      return true;
    }

    return false;
  };

  const addMask = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{2,4}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    let i;
    let len;

    for (i = 0, len = match.length; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join(" / ");
    }

    return value;
  };

  return (
    <FormSelectWrapper>
      <ControlInputDate
        style={style}
        errorChecking={errorChecking}
        addMask={addMask}
        onDateInput={onDateInput}
        setFocused={setFocused}
        onDateBlur={onDateBlur}
        inputRefMonth={inputRefMonth}
        focused={focused}
        values={values}
        options={options}
        checkedCard={checkedCard}
        errors={errors}
        showDateTooltip={showDateTooltip}
        currentTheme={currentTheme}
      />
      {errorChecking() && (
        <FormErrorWrapper>
          <div>
            <FormErrorTitleCvv>
              {i18n.getMessage({ message: errors.date })}
            </FormErrorTitleCvv>
          </div>
        </FormErrorWrapper>
      )}
    </FormSelectWrapper>
  );
};

export default InputDate;
