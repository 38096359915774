import { useContext } from "preact/hooks";
import { state, theme } from "../../services";
import Trans from "../Trans";
import {
  PaymentDetailsRowWrapper,
  PaymentDetailsLabel,
  TransactionCurrencyTitle,
  TransactionDecimalTitle,
  TransactionSumContainer,
  TransactionSumTitle,
  TransactionSumWrapper,
} from "./style";
import { ContextUI } from "../../providers/UIProvider";
import { IconColorWrapper } from "../../styles";
import IconCopy from "../../assets/icons/iconCopy";
import Tooltip from "../Tooltip/Tooltip";

interface PaymentDetailsAmountProps {
  hasError?: boolean;
  wholeNumber: string;
  decimal?: string;
  currency: string;
}

const PaymentDetailsAmount = ({
  hasError,
  wholeNumber,
  decimal,
  currency,
}: PaymentDetailsAmountProps) => {
  // TODO: need fix after move state.js to state.ts
  // @ts-ignore
  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);

  const { handleCopy } = useContext(ContextUI);

  // TODO: need fix after move theme.js to theme.ts
  // @ts-ignore
  const { isShowCurrencyCopy, totalDueTitle } = currentTheme || {};

  return (
    <PaymentDetailsRowWrapper $alignItems="center">
      <PaymentDetailsLabel $controlledLength>
        <Trans message={totalDueTitle || "TotalDue"} />
      </PaymentDetailsLabel>
      <TransactionSumWrapper>
        <TransactionSumContainer $hasError={hasError}>
          <TransactionSumTitle data-role="amount_integer">
            {wholeNumber}
          </TransactionSumTitle>
          {decimal && (
            <TransactionDecimalTitle data-role="amount_decimal">
              .{decimal}
            </TransactionDecimalTitle>
          )}
          <TransactionCurrencyTitle
            data-role="currency"
            style={{ marginLeft: "8px" }}
          >
            {currency}
          </TransactionCurrencyTitle>
        </TransactionSumContainer>
        {isShowCurrencyCopy ? (
          <span
            onClick={() => {
              if (handleCopy && typeof handleCopy === "function") {
                handleCopy(
                  `${wholeNumber}${decimal ? `.${decimal}` : ""}`,
                  "amountCopy",
                );
              }
            }}
            style={{
              cursor: "pointer",
              color: "blue",
            }}
          >
            <IconColorWrapper>
              {/* @ts-ignore */}
              <Tooltip text={"Copy"}>
                <IconCopy />
              </Tooltip>
            </IconColorWrapper>
          </span>
        ) : null}
      </TransactionSumWrapper>
    </PaymentDetailsRowWrapper>
  );
};

export default PaymentDetailsAmount;
