import { useState, useEffect } from "preact/hooks";
import challengeFormConfig from "./ChallengeFormConfig";
import { api, processing } from "../../services";
import FormInputs from "./ChallengeFormInputs";
import Notification from "../Notification";

const LAST_CHALLENGE_TYPE_KEY = "lastChallengeType";

const ChallengeForm = ({
  challengeType,
  sessionId,
  signature,
  challengeUrl,
  onFormSubmit,
  onFormSubmitSuccess,
  onFormSubmitFail,
  onFormSubmitFinally,
  currentMethod,
  challenge,
}) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [isReadyForSubmit, setIsReadyForSubmit] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const currentSessionChallengeType = sessionStorage.getItem(
    LAST_CHALLENGE_TYPE_KEY,
  );

  useEffect(() => {
    const newValues = Object.keys(challengeFormConfig).reduce(
      (acc, fieldName) => {
        const fieldConfig = challengeFormConfig[fieldName];

        if (
          fieldConfig.supportedChallengeTypes &&
          !fieldConfig.supportedChallengeTypes.includes(challengeType)
        ) {
          return acc;
        }

        acc[fieldConfig.name] = "";

        return acc;
      },
      {},
    );

    setValues(newValues);
  }, [challengeType, currentSessionChallengeType]);

  const isMatchedSessionChallengeCurrentType =
    currentSessionChallengeType &&
    currentSessionChallengeType === challengeType &&
    challengeType;

  useEffect(() => {
    if (
      currentSessionChallengeType === challengeType &&
      !["card_pin", "pin"].includes(challengeType)
    ) {
      setShowErrorNotification(true);
      const timeoutId = setTimeout(() => {
        setShowErrorNotification(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentSessionChallengeType, challengeType]);

  useEffect(() => {
    setIsReadyForSubmit(
      Object.values(values)?.filter((val) => !!val).length > 0,
    );
  }, [values]);

  const handleSubmit = () => {
    const renderedFields = Object.values(challengeFormConfig).filter((field) =>
      field.supportedChallengeTypes.includes(challengeType),
    );

    renderedFields.forEach((field) => {
      field.isActive = true;
    });

    const submitData = Object.keys(values)?.reduce((acc, item) => {
      if (typeof values[item] === "object") {
        const configField = renderedFields.find((field) => field.name === item);

        if (configField && configField.inputOptions.key) {
          acc[item] = values[item][configField.inputOptions?.key];
        }
      } else {
        acc[item] = values[item];
      }

      return acc;
    }, {});

    const validationResults = processing.validate({
      values: submitData || values,
      errors,
      method: "challenge",
      fields: renderedFields,
      isManuallyTriggered: true,
    });

    if (validationResults) {
      sessionStorage.setItem(LAST_CHALLENGE_TYPE_KEY, challengeType);

      if (typeof onFormSubmit === "function") {
        onFormSubmit();
      }

      api
        .submitChallengeForm({
          values: submitData || values,
          sessionId,
          signature,
          challengeUrl,
          currentMethod,
          challengeType,
        })
        .then((data) => {
          if (typeof onFormSubmitSuccess === "function") {
            onFormSubmitSuccess(data);
          }
        })
        .catch((data) => {
          if (typeof onFormSubmitFail === "function") {
            onFormSubmitFail(data);
          }
        })
        .finally((data) => {
          if (typeof onFormSubmitFinally === "function") {
            onFormSubmitFinally(data);
          }
        });
    } else {
      processing
        .getFieldsErrors({ fields: renderedFields, values: submitData })
        .forEach(({ key, value }) => {
          setErrors((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
    }

    setShowErrorNotification(false);
  };

  return (
    <>
      {isMatchedSessionChallengeCurrentType && showErrorNotification && (
        <Notification data={{ text: "incorrect_value", status: "fail" }} />
      )}

      <FormInputs
        challengeFormConfig={challengeFormConfig}
        challengeType={challengeType}
        challenge={challenge}
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        sessionId={sessionId}
        signature={signature}
        handleSubmit={handleSubmit}
        isDisabled={!isReadyForSubmit}
      />
    </>
  );
};

export default ChallengeForm;
