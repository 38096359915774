import { useEffect, useState } from "preact/hooks";
import { FormLoaderWrapper } from "../../../../styles";
import { GoBackButtonWrapper } from "../../style";
import Trans from "../../../Trans";
import Loader from "../../../Loaders";
import useApmTransaction from "../../../../hooks/useApmTransaction";
import Instruction from "../../../Instruction";
import { api, state } from "../../../../services";
import Button from "../../../../ui/Button";
import ChallengeComplete from "../../ChallengeComplete";

interface IProps {
  data: { account: string; institution: string };
  status: "challenge" | "challenge_accepted" | "challenge_succeeded";
}

const ChallengeAccountRequisites = ({ data, status }: IProps) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");

  useEffect(() => {
    if (status === "challenge_succeeded") {
      setIsPaymentCompleted(true);
    }
  }, [status]);

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    state.setLoading({ status: true, eventName: "submit_account_requisites" });

    api
      .submitAccountRequisites()
      .then(() => {
        setIsPaymentCompleted(true);
      })
      .finally(() => {
        state.setLoading({
          status: false,
          eventName: "submit_account_requisites",
        });
      });

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  if (isPaymentCompleted) {
    return <ChallengeComplete processingTime={60} />;
  }

  return data ? (
    <>
      <Instruction configName="account_requisites" instructionData={data} />
      <GoBackButtonWrapper>
        <Button onClick={handlePaymentMade}>
          <Trans message="madePayment" />
        </Button>
      </GoBackButtonWrapper>
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeAccountRequisites;
