import { i18n, state } from "../../services";
import Trans from "../Trans";
import {
  OuterBlockHeader,
  TotalInfoWrapper,
  ContainerDetailsWrapper,
  DetailsTransactionTitle,
  TransactionSumTitle,
  TransactionDecimalTitle,
  TransactionCurrencyTitle,
  TransactionSumWrapper,
  TransactionSumContainer,
  HelperForChooseMethod,
  TransactionInfoErrorBlockKomb,
  ErrorTitle,
  KombineErrorWrapper,
  ErrorListItem,
} from "../../styles";
import InfoIcon from "../../assets/icons/infoIcon";

const FormHeaderKombine = ({
  currency,
  wholeNumber,
  decimal,
  titleError,
  hasData,
  selectedMethod,
}) => {
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const hasError = !canReturnToMethods && !!titleError;

  return (
    <OuterBlockHeader>
      {hasData ? (
        <>
          <TotalInfoWrapper $pd="4px 16px 16px 16px">
            <ContainerDetailsWrapper pb="16px" alignItems="center">
              <DetailsTransactionTitle
                mb="4px"
                style={{ textAlign: "center" }}
                $controlledLength
              >
                <Trans message="Total amount" />
              </DetailsTransactionTitle>
              <TransactionSumWrapper>
                <TransactionSumContainer $hasError={hasError}>
                  <TransactionSumTitle color="#fff">
                    {wholeNumber}
                  </TransactionSumTitle>
                  {decimal && (
                    <TransactionDecimalTitle color="#fff">
                      .{decimal}
                    </TransactionDecimalTitle>
                  )}
                  <TransactionCurrencyTitle color="#9ba2af" $ml="8px">
                    {currency}{" "}
                  </TransactionCurrencyTitle>
                </TransactionSumContainer>
              </TransactionSumWrapper>
            </ContainerDetailsWrapper>
          </TotalInfoWrapper>

          {/* Failed retry popup */}

          {hasError && (
            <TransactionInfoErrorBlockKomb mb="24px" background="#FFFFFF">
              <KombineErrorWrapper>
                <InfoIcon />
                <ErrorTitle textAlign="left" color="#E53956" ml="12px">
                  <Trans message={i18n.getMessage({ message: titleError })} />
                </ErrorTitle>
              </KombineErrorWrapper>
              <ErrorTitle mb="12px">Try the following:</ErrorTitle>

              <ErrorListItem>
                {i18n.getMessage({ message: "pleasePaymentAgain" })}
              </ErrorListItem>
            </TransactionInfoErrorBlockKomb>
          )}
        </>
      ) : null}
      {!selectedMethod && !hasError ? (
        <HelperForChooseMethod>
          {i18n.getMessage({ message: "chooseMethod" })}
        </HelperForChooseMethod>
      ) : null}
    </OuterBlockHeader>
  );
};

export default FormHeaderKombine;
