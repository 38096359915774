export const getClosestAmounts = (suggestedAmounts, amount) => {
  const parsedAmount = parseFloat(amount);

  const lesserAmounts = suggestedAmounts
    .filter((item) => parseFloat(item.amount) < parsedAmount)
    .sort((min, max) => parseFloat(max.amount) - parseFloat(min.amount));

  const greaterAmounts = suggestedAmounts
    .filter((item) => parseFloat(item.amount) > parsedAmount)
    .sort((min, max) => parseFloat(min.amount) - parseFloat(max.amount));

  let closestAmounts = [];

  if (lesserAmounts.length > 0 && greaterAmounts.length > 0) {
    const closestLesser = lesserAmounts.slice(0, 1);
    const closestGreater = greaterAmounts.slice(0, 2);

    closestAmounts = [...closestLesser, ...closestGreater];
  } else if (greaterAmounts.length > 0) {
    closestAmounts = greaterAmounts.slice(0, 3);
  } else if (lesserAmounts.length > 0) {
    closestAmounts = lesserAmounts.slice(0, 3);
  }

  if (closestAmounts.length === 1 && lesserAmounts.length > 1) {
    closestAmounts = [greaterAmounts[0], lesserAmounts[0], lesserAmounts[1]];
  }

  if (
    closestAmounts.length === 2 &&
    lesserAmounts.length > 1 &&
    greaterAmounts.length > 0
  ) {
    closestAmounts = [lesserAmounts[0], lesserAmounts[1], greaterAmounts[0]];
  }

  while (closestAmounts.length < 3) {
    if (closestAmounts.length < 3 && lesserAmounts.length > 0) {
      closestAmounts.push(lesserAmounts.shift());
    } else if (closestAmounts.length < 3 && greaterAmounts.length > 0) {
      closestAmounts.push(greaterAmounts.shift());
    }
  }

  const uniqueAmounts = Array?.from(
    new Set(closestAmounts.map((item) => item.amount)),
  ).map((amount) => closestAmounts.find((item) => item.amount === amount));

  return uniqueAmounts;
};
