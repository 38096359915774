import Trans from "../Trans";
import asquadSvgLockIcon from "../../assets/icons/asquadLockIcon.svg";
import cvvIconCardIcon from "../../assets/icons/cvvTooltipCardIcon.svg";
import { useState } from "preact/hooks";
import {
  FormDescription,
  FormCvvWrapper,
  FormInputDate,
  TooltipCvvWrapper,
  TooltipText,
  FormIconCvvLock,
  CvvTooltipSubTitle,
  CvvTooltipMainTitle,
  InvalidCvvTitle,
} from "../../styles";

const InputCvvDefault = ({
  focused,
  errorChecking,
  onCvvBlur,
  onCvvInput,
  setFocused,
  values,
  inputRef,
  errors,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <FormDescription $focused={focused}>
        <Trans message="CVV2" />
      </FormDescription>
      <FormCvvWrapper>
        <FormInputDate
          data-role="card_cvv"
          $focused={focused}
          $errorDate={errorChecking()}
          type="password"
          autocomplete="cc-csc"
          value={values.cvv}
          id="cvv"
          onInput={onCvvInput}
          onFocus={() => setFocused(true)}
          onBlur={onCvvBlur}
          pattern="[0-9\s]{3}"
          maxlength="3"
          size="3"
          ref={inputRef}
          data-transaction-name="InputCvv"
        />
      </FormCvvWrapper>
      <TooltipCvvWrapper
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <FormIconCvvLock src={asquadSvgLockIcon} />

        {tooltipVisible && (
          <TooltipText>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <CvvTooltipSubTitle>
                <Trans message="last3Digits" />
              </CvvTooltipSubTitle>
              <img alt="cvv" src={cvvIconCardIcon} />
            </div>
            <CvvTooltipMainTitle>
              <Trans message="sslHintTitle" />
            </CvvTooltipMainTitle>
            <CvvTooltipSubTitle>
              <Trans message="sslHintDescription" />
            </CvvTooltipSubTitle>
          </TooltipText>
        )}
      </TooltipCvvWrapper>

      {errorChecking() && (
        <InvalidCvvTitle>
          <Trans message={errors?.cvv} />
        </InvalidCvvTitle>
      )}
    </>
  );
};

export default InputCvvDefault;
