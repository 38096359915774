import { logger } from "../../services";

const ZEN_SCRIPT_URL = "https://cdn-secure.zen.com/zen-web-collector.latest.js";
const SCRIPT_LOAD_TIMEOUT = 10 * 1000;
const CONFIGURE_TIMEOUT = 5 * 1000;

export const SEON_SESSION_REQUEST_TIMEOUT_MESSAGE =
  "Seon session request timeout";

let scriptLoadTimeoutId = null;
let collectorDataTimeoutId = null;

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    scriptLoadTimeoutId = setTimeout(() => {
      logger.error("Zen Fingerprint: script load timeout");
      reject();
    }, SCRIPT_LOAD_TIMEOUT);

    const script = document.createElement("script");

    script.src = src;

    script.onload = () => {
      if (scriptLoadTimeoutId) {
        clearTimeout(scriptLoadTimeoutId);
        scriptLoadTimeoutId = null;
      }

      resolve("Script loaded successfully");
    };

    script.onerror = () => {
      if (scriptLoadTimeoutId) {
        clearTimeout(scriptLoadTimeoutId);
        scriptLoadTimeoutId = null;
      }

      reject("Failed to load the script");
    };

    document.body.appendChild(script);
  });
};

const loadZenFingerprintScript = (sessionId) => {
  return new Promise((resolve, reject) => {
    loadScript(ZEN_SCRIPT_URL)
      .then(() => {
        if (typeof getCollectorData !== "function") {
          logger.error("Zen Fingerprint: getCollectorData is not a function");

          return Promise.reject();
        }

        collectorDataTimeoutId = setTimeout(() => {
          logger.error("Zen Fingerprint: collectorData timeout");
          reject();
        }, CONFIGURE_TIMEOUT);

        return Promise.resolve()
          .then(() => getCollectorData(sessionId))
          .then((fingerPrintId) => {
            clearTimeout(collectorDataTimeoutId);

            if (!fingerPrintId) {
              logger.error("Zen Fingerprint: no fingerPrintId");
              reject();
            }

            return fingerPrintId;
          });
      })
      .then(resolve)
      .catch(reject);
  });
};

export default loadZenFingerprintScript;
