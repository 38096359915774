import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { api, crypto } from "../../services";
import CryptoHeaderForm from "./CryptoHeaderForm";
import CryptoContent from "./CryptoContent";

const CryptoWidget = ({
  locale,
  currentState,
  handleCopy,
  onChangeLanguage,
}) => {
  const { matchesData, setCurrentCryptoState } = useContext(Context);
  const { type, signature, sessionId } = matchesData?.matches || {};

  const onAmountChange = (updatedData) => {
    crypto.set(updatedData);

    return api
      .getInitDataCrypto({
        type,
        sessionId,
        signature,
        updatedData,
      })
      .then((res) => {
        setCurrentCryptoState((state) => ({
          ...state,
          ...(res?.data || res || {}),
        }));

        return res;
      });
  };

  return (
    <>
      <CryptoHeaderForm locale={locale} onChangeLanguage={onChangeLanguage} />
      <CryptoContent
        isCryptoFormVisible={currentState?.exchange_allow_calculator}
        onAmountChange={onAmountChange}
        currentState={currentState}
        handleCopy={handleCopy}
      />
    </>
  );
};

export default CryptoWidget;
