import { i18n } from "../../services";
import Trans from "../Trans";
import {
  FormInputContainer,
  FormInput,
  InvalidCardErrorTitle,
} from "../../styles";

const CardHolderInputDefault = ({
  values,
  onInput,
  onBlur,
  setFocused,
  focused,
  errorChecking,
  inputRef,
  errors,
  placeholder,
}) => {
  return (
    <>
      <FormInputContainer>
        <FormInput
          value={values.cardHolder}
          onInput={onInput}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          $focused={focused}
          autofocus
          $errorCard={errorChecking()}
          id="cardholder"
          type="text"
          autocomplete="cc-name"
          placeholder={i18n.getMessage({ message: placeholder })}
          ref={inputRef}
          data-transaction-name="CardHolderInput"
        />
      </FormInputContainer>
      {errorChecking() && (
        <InvalidCardErrorTitle>
          <Trans message={errors?.cardHolder} />
        </InvalidCardErrorTitle>
      )}
    </>
  );
};

export default CardHolderInputDefault;
