export const BIRTHDAY_PLACEHOLDERS = {
  popypara: "1977-05-25",
  parazula: "1977-05-25",
  pep: "1977-05-25",
  papara: "1977-05-25",
  mefete: "1977-05-25",
  payfix: "1977-05-25",
  parolapara: "1977-05-25",
  bank_transfer: "1977-05-25",
};

export const FIRST_NAME_PLACEHOLDER = {
  bank_transfer_japan: "ヤロスラフ",
};

export const LAST_NAME_PLACEHOLDER = {
  bank_transfer_japan: "ザヴァリュク",
};
