const MIN_NAME_LENGTH = 2;
const MIN_LAST_NAME_LENGTH = 2;
const MAX_LENGTH = 2 * 24; // first name + last name

const validateNameForKombine = (value) => {
  const trimmedValue = value?.trim()?.replace(/\s+/g, " ");
  const parts = trimmedValue?.split(" ");
  const firstName = parts[0] || "";
  const lastName = parts[1] || "";

  return (
    parts.length === 2 &&
    firstName?.length >= MIN_NAME_LENGTH &&
    lastName?.length >= MIN_LAST_NAME_LENGTH &&
    trimmedValue?.length <= MAX_LENGTH
  );
};

const validateDefault = (value) => {
  const toValidate = value?.replace(/\s/g, "");

  return (
    toValidate?.length >= MIN_NAME_LENGTH && toValidate?.length <= MAX_LENGTH
  );
};

const validationConfig = {
  default: validateDefault,
  kombine: validateNameForKombine,
};

export const validateCardHolder = (validationType, value) => {
  const validate = validationConfig[validationType];

  if (validate) {
    return validate(value);
  }
};
