import { RENDER_CHALLENGE_TYPES } from "../../constants/challengeTypes";

const headerFieldsConfig = {
  fields: [
    {
      condition: (props) =>
        props.currentState?.description && !props.isHideDescription,
      label: "description",
      value: (props) => props.currentState?.description,
    },
    {
      condition: (props) =>
        [
          RENDER_CHALLENGE_TYPES.BANK_TRANSFER_ARGENTINA,
          RENDER_CHALLENGE_TYPES.INTERAC_ETRANSFER,
        ].includes(props.type) &&
        (props.first_name || props.last_name),
      label: "payer_name",
      value: (props) => `${props.first_name} ${props.last_name}`,
    },
    {
      condition: (props) =>
        props.type === RENDER_CHALLENGE_TYPES.BANK_TRANSFER_ARGENTINA &&
        props.customerItn,
      label: "CUIT/CUIL",
      value: (props) => props.itn,
    },
    {
      condition: (props) =>
        props.currentState?.reference && !props.isHideTransactionId,
      label: "transactionIdTitle",
      value: (props) => props.reference,
      copyable: true,
      copyText: "transactionIdCopy",
    },
    {
      condition: (props) => props.mobileMoneyPhone,
      label: "phone",
      value: (props) => props.mobileMoneyPhone,
    },
  ],
};

export default headerFieldsConfig;
