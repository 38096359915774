import styled, { keyframes, css } from "styled-components";

const animationData = {
  default: {
    height: "50px",
    width: "50px",
    ballHeight: "20px",
    ballWidth: "20px",
    boxShadow: "30px",
    translate: "15px",
    mt: "-20px",
    mb: "10px",
  },
  small: {
    height: "24px",
    width: "24px",
    ballHeight: "10px",
    ballWidth: "10px",
    boxShadow: "14px",
    translate: "7px",
    mt: "-9px",
    mb: "5px",
  },
};

const loaderAnimationSpeed = "1s";

const rotate = keyframes`
  0% {
    transform: rotate(0deg) scale(0.7);
  }
  50% {
    transform: rotate(360deg) scale(1.25);
  }
  100% {
    transform: rotate(720deg) scale(0.75);
  }
`;

const ball1 = (props) => keyframes`
  0% {
    box-shadow: ${props.animation.boxShadow}  0 0 ${props.theme.mainColor};
  }
  50% {
    box-shadow: 0 0 0 ${props.theme.formHeaderBackground};
    margin-bottom: 0;
    transform: translate(${props.animation.translate}, ${props.animation.translate});
  }
  100% {
    box-shadow: ${props.animation.boxShadow} 0 0 ${props.theme.formHeaderBackground};
    margin-bottom: ${props.animation.mb};
    background-color: ${props.theme.mainColor};
  }
`;

const ball2 = (props) => keyframes`
  0% {
    box-shadow: ${props.animation.boxShadow} 0 0 ${props.theme.formHeaderBackground};
  }
  50% {
    box-shadow: 0 0 0 ${props.theme.formHeaderBackground};
    margin-top: ${props.animation.mt};
    transform: translate(${props.animation.translate}, ${props.animation.translate});
  }
  100% {
    box-shadow: ${props.animation.boxShadow} 0 0 ${props.theme.formHeaderBackground};
    margin-top: 0;
    background-color: ${props.theme.formHeaderBackground};
  }
`;

const FlexLoader = styled.div`
  animation: ${rotate} ${loaderAnimationSpeed} infinite;
  height: ${(props) => props.animation.height};
  width: ${(props) => props.animation.width};
`;

const Ball1 = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.formHeaderBackground};
  box-shadow: ${(props) => props.animation.boxShadow} 0 0
    ${(props) => props.theme.formHeaderBackground};
  height: ${(props) => props.animation.ballHeight};
  width: ${(props) => props.animation.ballWidth};
  animation: ${(props) => css`
    ${ball1(props)} ${loaderAnimationSpeed} infinite
  `};
  margin-bottom: ${(props) => props.animation.mb};
`;

const Ball2 = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.mainColor};
  box-shadow: ${(props) => props.animation.boxShadow} 0 0
    ${(props) => props.theme.formHeaderBackground};
  height: ${(props) => props.animation.ballHeight};
  width: ${(props) => props.animation.ballWidth};
  animation: ${(props) => css`
    ${ball2(props)} ${loaderAnimationSpeed} infinite
  `};
`;

const FlexLoad = ({ size = "default", ...props }) => {
  const animation = animationData[size];

  return (
    <FlexLoader animation={animation} {...props}>
      <Ball1 animation={animation} {...props} />
      <Ball2 animation={animation} {...props} />
    </FlexLoader>
  );
};

export default FlexLoad;
