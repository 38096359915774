import styled from "styled-components";

export const EnterInformationTitle = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: center;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0025em;
    font-family: var(--mainFont);
    text-align: center;
  }
`;
