import { COLORS } from "../../constants/themeConstants";

const InfoIcon = ({
  color = COLORS.DEFAULT_WHITE,
  background = COLORS.BLUE,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2303_29831)">
      <circle cx="8" cy="8" r="8" fill={background} />
      <path
        d="M7 6C8.10457 6 9 6.89543 9 8V12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12V6Z"
        fill={color}
      />
      <path
        d="M9 4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2303_29831">
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
