import logger from "../services/logger";
import googlePay from "../services/googlePay";
import GooglePayButton from "@google-pay/button-react";
import { deepEqual } from "../services/deepEqual";
import { memo } from "preact/compat";
import { GoogleApplePayTestButton } from "../styles";

const GooglePay = memo(
  ({ amount, currency, description, identity, locale }) => {
    const mapMethods = identity?.allowed_auth_methods?.reduce((acc, val) => {
      return (acc = [...acc, val?.toUpperCase()]);
    }, []);

    const mapNetworks = identity?.allowed_card_networks?.reduce((acc, val) => {
      return (acc = [...acc, val?.toUpperCase()]);
    }, []);

    return (
      <GoogleApplePayTestButton>
        <GooglePayButton
          environment={identity?.environment?.toUpperCase()}
          buttonType="buy"
          buttonSizeMode="fill"
          buttonLocale={locale}
          style={{ width: "100%", height: 40 }}
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                  allowedAuthMethods: mapMethods,
                  allowedCardNetworks: mapNetworks,
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                  parameters: {
                    gateway: identity?.gateway,
                    gatewayMerchantId: identity?.gateway_merchant_id,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId:
                identity?.merchant_name === "Ibox Gpay"
                  ? "01415593990397374268"
                  : identity?.merchant_id,
              merchantName: identity?.merchant_name,
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: description?.toString() || "",
              totalPrice: amount?.toString(),
              currencyCode: currency?.toString(),
              countryCode: identity?.country,
            },
          }}
          onLoadPaymentData={(paymentRequest) => {
            if (paymentRequest) {
              googlePay.onGooglePayButtonClick({
                paymentRequest,
              });
            }
          }}
          onError={(error) => {
            logger.error(error);
          }}
        />
      </GoogleApplePayTestButton>
    );
  },
  deepEqual,
);

export default GooglePay;
