import styled from "styled-components";

export const FormInputIconWrapper = styled.div`
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  margin-top: -2px;
  cursor: pointer;
`;
