import ModalItem from "../Modals/ModalComponents/ModalItem";
import Trans from "../Trans";
import empty from "../../assets/icons/dropdownEmpty.svg";
import { DropdownEmpty } from "./style";
import { state } from "../../services";

const List = ({
  options,
  selected,
  onChange,
  foreignKey,
  showBankIcon,
  display,
  inputOptions,
}) => {
  const selectedMethod = state.getSelectedPaymentMethod();

  if (options.length === 0) {
    return (
      <DropdownEmpty>
        <img src={empty} alt="icon" />
        <Trans message={"Nothing was found. Try another query"} />
      </DropdownEmpty>
    );
  }

  return (
    options &&
    Array.isArray(options) &&
    options.map((option) => {
      return (
        <ModalItem
          key={option[foreignKey]}
          isActive={selected[foreignKey] === option[foreignKey]}
          handleClick={() => onChange(option)}
          title={
            display && typeof display === "function"
              ? display({ option })
              : option.name
          }
          subTitle={option?.subName}
          withoutIcon={!showBankIcon}
          isBankCode={showBankIcon}
          name={
            (inputOptions.getMethodNameForIcon &&
              typeof inputOptions.getMethodNameForIcon === "function" &&
              inputOptions.getMethodNameForIcon()) ||
            option.code ||
            option.name
          }
          method={selectedMethod?.method}
        />
      );
    })
  );
};

export default List;
