import { state, logger } from "../../services";
import controlRememberMeIcon from "../RememberMeIcon/RememberMeIconControl";
import { useEffect } from "preact/hooks";

const RememberMe = ({ checkedCard, checked, setRememberMe }) => {
  const options = state.get().options;
  const ControlRememberMe =
    controlRememberMeIcon[options?.theme] || controlRememberMeIcon.default;

  useEffect(() => {
    if (!ControlRememberMe) {
      logger.error("Component not found");
    }
  }, []);

  if (options?.card && options?.card.enabled_remember_me) {
    return (
      <>
        <ControlRememberMe
          checkedCard={checkedCard}
          options={options}
          checked={checked}
          setRememberMe={setRememberMe}
        />
      </>
    );
  }

  return null;
};

export default RememberMe;
