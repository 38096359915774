import { useEffect } from "preact/hooks";
import applePay from "../services/applePay";
import { deepEqual } from "../services/deepEqual";
import { memo } from "preact/compat";
import { ApplePayButtonWrapper } from "../styles";

const ApplePayButton = memo(
  ({ amount, currency, description, identity, reference, session, locale }) => {
    const sessionString = JSON.stringify(session);

    useEffect(() => {
      if (session) {
        applePay.completeMerchantValidation(session);
      }
    }, [session, sessionString]);

    if (!window.ApplePaySession) {
      return null;
    }

    return (
      <ApplePayButtonWrapper>
        <apple-pay-button
          style={{ width: "100% !important" }}
          buttonstyle="black"
          type="plain"
          locale={locale}
          onClick={() => {
            applePay.onApplePayButtonClick({
              amount,
              currency,
              description,
              identity,
              reference,
            });
          }}
        />
      </ApplePayButtonWrapper>
    );
  },
  deepEqual,
);

export default ApplePayButton;
