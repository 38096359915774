import { useContext } from "preact/hooks";
import { state } from "../../services";
import { CopyActionWrapper, CopyWrapper, IconColorWrapper } from "../../styles";
import Trans from "../Trans";
import {
  InnerLine,
  PaymentDetailsLabel,
  PaymentDetailsRowWrapper,
  PaymentDetailsValueWrapper,
} from "./style";
import { ContextUI } from "../../providers/UIProvider";
import Tooltip from "../Tooltip/Tooltip";
import IconCopy from "../../assets/icons/iconCopy";

interface PaymentDetailsContentProps {
  data: Array<{
    name?: string;
    label: string;
    value: string;
    condition?: boolean;
    copyable?: boolean;
    copyText?: string;
  }>;
  isOpen: boolean;
  hasUnderline?: boolean;
}

const PaymentDetailsContent = ({
  data,
  isOpen,
  hasUnderline = true,
}: PaymentDetailsContentProps) => {
  const { handleCopy } = useContext(ContextUI);
  const selectedMethod = state.getSelectedPaymentMethod();

  if (!(isOpen || !selectedMethod)) {
    return null;
  }
  return (
    <>
      {data.map((field) => {
        if (field.condition === false || !field.value) {
          return null;
        }

        return (
          <PaymentDetailsRowWrapper key={field.name}>
            <PaymentDetailsLabel $controlledLength>
              <Trans message={field.label} withoutWrapper />
            </PaymentDetailsLabel>
            <PaymentDetailsValueWrapper>
              <PaymentDetailsLabel>{field.value}</PaymentDetailsLabel>
              {field.copyable && (
                <CopyWrapper>
                  <CopyActionWrapper
                    onClick={() => handleCopy(field.value, field.copyText)}
                  >
                    <IconColorWrapper>
                      {/* @ts-ignore */}
                      <Tooltip text={"Сopy"}>
                        <IconCopy />
                      </Tooltip>
                    </IconColorWrapper>
                  </CopyActionWrapper>
                </CopyWrapper>
              )}
            </PaymentDetailsValueWrapper>
          </PaymentDetailsRowWrapper>
        );
      })}
      {hasUnderline ? <InnerLine /> : null}
    </>
  );
};

export default PaymentDetailsContent;
