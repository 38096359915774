import { useState, useEffect } from "preact/hooks";
import {
  InnerHeaderDataWrapper,
  TotalInfoWrapper,
  DetailsTransactionTitle,
  BankTransferAfricaInfoWrapper,
} from "../../styles";
import { ChallengeMainTitle } from "./style";
import Trans from "../Trans";
import Loader from "../Loaders";

const DEFAULT_PROCESSING_TIME = 300;

const ChallengeComplete = ({ processingTime }) => {
  const [timer, setTimer] = useState(processingTime || DEFAULT_PROCESSING_TIME);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);

          return 0;
        }

        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const timeExpired = timer === 0;

  return (
    <InnerHeaderDataWrapper>
      <TotalInfoWrapper>
        <BankTransferAfricaInfoWrapper>
          {timeExpired ? (
            <>
              <ChallengeMainTitle $mb="8px">
                <Trans message="transactionStatus" />
              </ChallengeMainTitle>
              <DetailsTransactionTitle>
                <Trans message="paymentsSlowMessage" />
              </DetailsTransactionTitle>
              <DetailsTransactionTitle>
                <Trans message="receiptPaymentConfirmation" />
              </DetailsTransactionTitle>
            </>
          ) : (
            <>
              <DetailsTransactionTitle $mb="8px">
                <Trans message="confirmingTransfer" /> {minutes}{" "}
                <Trans message="mins" /> {seconds} <Trans message="secs" />
              </DetailsTransactionTitle>
              <DetailsTransactionTitle $mb="22px">
                <Trans message="doNotRefreshPage" />
              </DetailsTransactionTitle>
              <Loader />
            </>
          )}
        </BankTransferAfricaInfoWrapper>
      </TotalInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default ChallengeComplete;
