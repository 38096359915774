import { createContext } from "preact";
import { useState } from "preact/hooks";
import FormPopup from "../components/FormPopup";
import Notification from "../components/Notification";
import { state } from "../services";

const MODAL_DATA_INIT = {
  isOpen: false,
  content: null,
  header: null,
  title: "",
  subTitle: "",
  isFullView: false,
  modalName: "",
};

const NOTIFICATION_DATA_INIT = {
  isOpen: false,
  isError: false,
  text: "",
  status: "success",
  isCloseAutomatically: true,
  buttonConfig: null,
};

export const ContextUI = createContext({
  notificationData: null,
  setNotificationData: () => {},
  modalData: null,
  setModalData: () => {},
  handleCloseModal: () => {},
  handleCopy: (_copy, _text) => {},
  handleCLoseNotification: () => {},
});

const UIProvider = ({ children }) => {
  const [modalData, setModalData] = useState(MODAL_DATA_INIT);
  const [notificationData, setNotificationData] = useState(
    NOTIFICATION_DATA_INIT,
  );

  const handleCloseModal = () => {
    setModalData(MODAL_DATA_INIT);
  };

  const handleCloseNotification = () => {
    setNotificationData(NOTIFICATION_DATA_INIT);

    state.set({ notification: null });
  };

  const handleCopy = (value, text) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    const notificationData = {
      isOpen: true,
      status: "success",
      text,
      isCloseAutomatically: true,
    };

    setNotificationData((prev) => ({
      ...prev,
      ...notificationData,
    }));
  };

  const ui = {
    notificationData,
    setNotificationData,
    modalData,
    setModalData,
    handleCloseModal,
    handleCloseNotification,
    handleCopy,
  };

  return (
    <ContextUI.Provider value={ui}>
      <Notification />
      <FormPopup />
      {children}
    </ContextUI.Provider>
  );
};

export default UIProvider;
