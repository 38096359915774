import InfoIcon from "../../assets/icons/infoIcon";
import { INFORMATION_ABOUT_PAYMENT_METHODS } from "../../constants/paymentMethodsInfo";
import { state } from "../../services";
import { InnerHeaderDataWrapper } from "../../styles";
import Trans from "../Trans";
import {
  FormFieldsTitle,
  InfoBlockContainer,
  InfoBlockImage,
  InfoBlockMainTitle,
  InfoBlockWrapper,
} from "./style";

const FormInfoBlock = ({ fields }: { fields: { isActive: boolean }[] }) => {
  const selectedMethod = state.getSelectedPaymentMethod();
  const { method } = selectedMethod || {};
  const currentInfo =
    INFORMATION_ABOUT_PAYMENT_METHODS[method] ||
    INFORMATION_ABOUT_PAYMENT_METHODS["default"];
  const { isAvailable, content, formFieldsTitle, link } = currentInfo;

  // @ts-ignore
  return isAvailable({ fields }) ? (
    <>
      {
        // @ts-ignore
        <InnerHeaderDataWrapper $top="0" $padding="0">
          <InfoBlockContainer>
            <InfoBlockWrapper>
              <InfoBlockImage>
                <InfoIcon />
              </InfoBlockImage>
              <InfoBlockMainTitle>
                {typeof content === "function" ? content() : null}
              </InfoBlockMainTitle>
            </InfoBlockWrapper>
            {link ? (
              <a href={link.href} target={link.target}>
                <Trans message={link.message} withoutWrapper />
              </a>
            ) : null}
          </InfoBlockContainer>
        </InnerHeaderDataWrapper>
      }
      {formFieldsTitle ? (
        <FormFieldsTitle>
          {typeof formFieldsTitle === "function"
            ? formFieldsTitle()
            : formFieldsTitle}
        </FormFieldsTitle>
      ) : null}
    </>
  ) : null;
};

export default FormInfoBlock;
