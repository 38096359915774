import Trans from "../Trans";
import { FormPayButton, TransactionSumTitle } from "../../styles";

const ChallengeFormSubmitButton = ({ handleSubmit, isDisabled }) => (
  <FormPayButton
    name="cardFormPayButton"
    type="submit"
    onClick={handleSubmit}
    style={{ width: "100%" }}
    disabled={isDisabled}
  >
    <TransactionSumTitle>
      <Trans message="submit" />
    </TransactionSumTitle>
  </FormPayButton>
);

export default ChallengeFormSubmitButton;
