import {
  ChangeCardMobDesktopWrapper,
  CircleIconWrapper,
  SavedCardMobileWrapper,
  IconColorWrapper,
} from "../../styles";
import Tooltip from "../Tooltip/Tooltip";
import IconSavedCard from "../../assets/icons/iconSavedCard";
import Trans from "../Trans";
import rightBlueArrow from "../../assets/icons/rightBlueIcon.svg";

const InputSavedCardDefault = ({
  conditionForShowArrow,
  smallMobileButton,
  openModal,
}) => {
  return (
    <>
      {conditionForShowArrow &&
        (!smallMobileButton ? (
          <ChangeCardMobDesktopWrapper
            onClick={() => openModal()}
            data-role="card_dropdown_list"
          >
            <CircleIconWrapper>
              <IconColorWrapper>
                <Tooltip text={"savedCard"}>
                  <IconSavedCard />
                </Tooltip>
              </IconColorWrapper>
            </CircleIconWrapper>
          </ChangeCardMobDesktopWrapper>
        ) : (
          <SavedCardMobileWrapper
            onClick={() => openModal()}
            data-role="card_dropdown_list"
          >
            <span>
              <Trans message="savedCard" />
            </span>{" "}
            <img
              style={{ marginLeft: "6px" }}
              src={rightBlueArrow}
              alt="arrow"
            />{" "}
          </SavedCardMobileWrapper>
        ))}
    </>
  );
};

export default InputSavedCardDefault;
