import * as Preact from "preact";
import { CheckboxWrapper, CheckboxInput, CheckboxLabel } from "./style";

interface ICheckboxProps {
  name?: string;
  checked: boolean;
  children: Preact.ComponentChildren;
  onChange: (e: Preact.JSX.TargetedEvent<HTMLInputElement>) => void;
}

const CheckboxUI = ({
  name,
  checked,
  onChange,
  children,
}: ICheckboxProps): Preact.JSX.Element => {
  return (
    <CheckboxWrapper>
      <CheckboxInput
        id={name || ""}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <CheckboxLabel for={name || ""}>{children}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default CheckboxUI;
