import { useState, useEffect, useContext } from "preact/hooks";
import CryptoForm from "./CryptoForm";
import CryptoDisclaimer from "./CryptoDisclaimer.tsx";
import Qr from "./Qr";
import {
  FormLoaderWrapper,
  InnerHeaderDataWrapper,
  ButtonWrapper,
  BaseButton,
} from "../../styles";
import {
  CryptoFormContainer,
  CryptoContentInfoTitle,
  CryptoInfoBlockWrapper,
  CryptoPaymentProgressDescription,
  CryptoPaymentProgress,
  CryptoHelperBlock,
  CryptoHelperBlockDescription,
  CryptoCurrencyNotActiveWrapper,
  CryptoHelperBlockIcon,
  CryptoMinMaxTitle,
} from "./style";
import Loader from "../Loaders";
import { ChallengeInfoWrapper, StyledInfoImage } from "../Challenge/style";
import Trans from "../Trans";
import { ContextUI } from "../../providers/UIProvider.js";
import Tooltip from "../Tooltip/Tooltip.js";
import InfoIcon from "../../assets/icons/infoIcon";
import { COLORS } from "../../constants/themeConstants.ts";
import Instruction from "../Instruction/index.tsx";
import { Context } from "../../app.jsx";

const POLLING_TIME = 2000;

export const formatNumberWithSpaces = (number, label) => {
  if (isNaN(number) && number !== "") {
    return "";
  }

  const parts = number?.toString()?.split(".");

  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const maxDecimalLength = label === "send" ? 6 : 2;

  if (parts[1]) {
    parts[1] = parts[1]?.slice(0, maxDecimalLength);
  }

  return parts.join(".");
};

const CryptoContent = ({
  onAmountChange,
  currentState,
  isCryptoFormVisible,
  redirectUrl,
  exchangeData,
}) => {
  const [changedField, setChangedField] = useState("currency_amount");
  const [cryptoInputData, setUserInputData] = useState({
    currency_amount: currentState?.currency_amount,
    pay_amount: currentState?.pay_amount,
  });

  const [isLoading, setIsLoading] = useState({
    pay_amount: false,
    currency_amount: false,
  });

  const { onGoBackButtonClick } = useContext(Context);
  const { setModalData, handleCopy } = useContext(ContextUI);

  const {
    active,
    amount,
    waiting_time_minutes = 5,
    network_fee,
  } = exchangeData?.data?.info || {};

  const { min } = amount || {};
  const isAmountBelowMin = +cryptoInputData.pay_amount < +min;
  const hasNetworkFee =
    network_fee && !isNaN(+network_fee) && +network_fee !== 0;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserInputData((prevData) => {
      const newData = { ...prevData, [name]: value };

      const oppositeField =
        name === "pay_amount" ? "currency_amount" : "pay_amount";

      setIsLoading({
        [name]: false,
        [oppositeField]: true,
      });

      return newData;
    });

    setChangedField(name);
  };

  useEffect(() => {
    if (changedField && !isNaN(cryptoInputData[changedField])) {
      const timer = setTimeout(() => {
        onAmountChange({
          [changedField]: cryptoInputData[changedField],
        }).finally(() => {
          setTimeout(() => {
            setIsLoading({
              pay_amount: false,
              currency_amount: false,
            });
          }, 100);
        });
      }, POLLING_TIME);

      return () => clearTimeout(timer);
    }
  }, [changedField, cryptoInputData[changedField], isLoading]);

  useEffect(() => {
    setUserInputData({
      currency_amount: currentState?.currency_amount,
      pay_amount: currentState?.pay_amount,
    });
  }, [currentState?.currency_amount, currentState?.pay_amount]);

  const handleGoBack = () => {
    setModalData({
      isOpen: false,
      title: "",
      content: null,
    });

    onGoBackButtonClick(redirectUrl);
  };

  const handlePaymentClick = () => {
    setModalData({
      isOpen: true,
      title: "paymentInProgress",
      content: (
        <CryptoPaymentProgress>
          <CryptoPaymentProgressDescription>
            <Trans message="paymentInProgressDescriptionStart" withoutWrapper />{" "}
            <b>
              {waiting_time_minutes} <Trans message="minutes" withoutWrapper />
            </b>
            <Trans message="paymentInProgressDescriptionEnd" withoutWrapper />
          </CryptoPaymentProgressDescription>
          <BaseButton
            textColor="#fff"
            data-role="back_to_store_button"
            onClick={handleGoBack}
            style={{ marginTop: "16px" }}
          >
            <Trans message="backToWebsite" />
          </BaseButton>
        </CryptoPaymentProgress>
      ),
    });
  };

  if (active === false) {
    return (
      <CryptoCurrencyNotActiveWrapper>
        <Instruction
          configName="crypto_currency_not_active"
          instructionData={{}}
        />
        <ButtonWrapper style={{ marginTop: "auto" }}>
          <BaseButton
            textTransform="initial"
            onClick={() =>
              onGoBackButtonClick("", { canReturnToMethods: true })
            }
            marginTop="auto"
          >
            <Trans message="backToMethods" />
          </BaseButton>
        </ButtonWrapper>
      </CryptoCurrencyNotActiveWrapper>
    );
  }

  return !isCryptoFormVisible ||
    !currentState?.pay_currency ||
    !currentState?.network ||
    !currentState?.address ? (
    <FormLoaderWrapper height="100%">
      <Loader />
    </FormLoaderWrapper>
  ) : (
    <>
      <CryptoFormContainer>
        <CryptoHelperBlock>
          <CryptoHelperBlockDescription>
            {!!min && !isNaN(+min) && +min !== 0 && (
              <>
                <Trans message="cryptoTopup" withoutWrapper />{" "}
                <CryptoMinMaxTitle isAmountBelowMin={isAmountBelowMin}>
                  <Trans message="min" /> {min} {currentState?.pay_currency}
                </CryptoMinMaxTitle>
                {". "}
              </>
            )}
            <Trans message="paymentMinimumLimit" withoutWrapper />
          </CryptoHelperBlockDescription>
          <CryptoHelperBlockIcon>
            <Tooltip
              width="211px"
              fs="12px"
              bottom="-310%"
              textAlign="center"
              left="-478%"
              leftArrow="90%"
              top="-15%"
              rotate="180deg"
              text={() => (
                <>
                  <Trans message="cryptoInfoTooltip" withoutWrapper />{" "}
                  {currentState?.currency}
                  {" ("}
                  {currentState?.pay_currency}{" "}
                  <Trans message="rate" withoutWrapper />
                  {")"}
                  {hasNetworkFee ? (
                    <>
                      {" + "}
                      <Trans message="networkFee" withoutWrapper />
                    </>
                  ) : null}
                </>
              )}
            >
              <InfoIcon color={COLORS.BLUE} background={COLORS.DEFAULT_WHITE} />
            </Tooltip>
          </CryptoHelperBlockIcon>
        </CryptoHelperBlock>
        {isCryptoFormVisible ? (
          <>
            <CryptoForm
              isAmountBelowMin={isAmountBelowMin}
              pay_currency={currentState?.pay_currency}
              currency={currentState?.currency}
              userInputData={cryptoInputData}
              formatNumberWithSpaces={formatNumberWithSpaces}
              handleInputChange={handleInputChange}
              handleCopy={handleCopy}
              isLoading={isLoading}
            />
          </>
        ) : null}
      </CryptoFormContainer>
      <InnerHeaderDataWrapper $noShadow>
        {currentState?.pay_currency && currentState?.network ? (
          <CryptoDisclaimer
            currency={currentState?.pay_currency}
            network={currentState?.network}
          />
        ) : null}

        {currentState?.address ? (
          <Qr
            address={currentState?.address}
            address_tag={currentState?.address_tag}
            address_qr_code_image={currentState?.address_qr_code_image}
            handleCopy={handleCopy}
          />
        ) : null}

        <ChallengeInfoWrapper>
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>
          <CryptoInfoBlockWrapper>
            <CryptoContentInfoTitle>
              <Trans message="correctNetworkInfoTitle" />
            </CryptoContentInfoTitle>

            <CryptoContentInfoTitle $bold>
              <Trans message="mistakeLostCryptoFound" />
            </CryptoContentInfoTitle>
          </CryptoInfoBlockWrapper>
        </ChallengeInfoWrapper>
      </InnerHeaderDataWrapper>

      <ButtonWrapper>
        <BaseButton
          textTransform="initial"
          onClick={handlePaymentClick}
          marginBottom="22px"
        >
          <Trans message="iHaveMadePayment" />
        </BaseButton>
      </ButtonWrapper>
    </>
  );
};

export default CryptoContent;
