import * as Preact from "preact";
import { ButtonStyled } from "./style";

interface IProps {
  children: Preact.ComponentChildren;
  onClick: Preact.JSX.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ children, onClick }: IProps) => (
  <ButtonStyled onClick={onClick}>{children}</ButtonStyled>
);

export default Button;
