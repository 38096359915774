const IconSavedCard = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1322_74620)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12.0724V8.07235H9.25882C8.47917 8.8848 8 9.98782 8 11.2027C8 12.4177 8.47921 13.5208 9.25893 14.3332H2.26087C1.01426 14.3332 0 13.319 0 12.0724ZM12.5446 6.68105H15.3043V6.15931C15.3043 4.9127 14.2901 3.89844 13.0435 3.89844H2.26087C1.01426 3.89844 0 4.9127 0 6.15931V6.68105H12.4988C12.5065 6.68101 12.5141 6.68099 12.5217 6.68099C12.5294 6.68099 12.537 6.68101 12.5446 6.68105Z"
        fill="#B3C4C9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5212 7.72461C10.6003 7.72461 9.04297 9.28192 9.04297 11.2029C9.04297 13.1238 10.6003 14.6811 12.5212 14.6811C14.4422 14.6811 15.9995 13.1238 15.9995 11.2029C15.9995 9.28192 14.4422 7.72461 12.5212 7.72461ZM14.1471 9.93799C14.2165 9.93799 14.2858 9.9644 14.3388 10.0174C14.4447 10.1234 14.4447 10.2946 14.3388 10.4006L12.1707 12.5687C12.1178 12.6215 12.0484 12.6481 11.9791 12.6481C11.9097 12.6481 11.8403 12.6215 11.7875 12.5687L10.7034 11.4846C10.5975 11.3787 10.5975 11.2074 10.7034 11.1014C10.8094 10.9955 10.9807 10.9955 11.0866 11.1014L11.9791 11.9939L13.9555 10.0174C14.0085 9.9644 14.0778 9.93799 14.1471 9.93799Z"
        fill="#B3C4C9"
      />
    </g>
    <defs>
      <clipPath id="clip0_1322_74620">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.898438)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconSavedCard;
