import { useContext } from "preact/hooks";
import instructions from "../../assets/configs/instructions";
import {
  BlockDetailsWrapper,
  IconColorWrapper,
  InnerHeaderDataWrapper,
  InnerLine,
} from "../../styles";
import {
  ChallengeInfoWrapper,
  ChallengeMainTitle,
  StyledInfoImage,
} from "../Challenge/style";
import Trans from "../Trans";
import {
  InstructionStepCopyIconWrapper,
  InstructionStepDataText,
  InstructionStepDataWrapper,
  InstructionStepNumber,
  InstructionStepTitle,
  InstructionStepTitleWrapper,
  InstructionStepWrapper,
  InstructionWrapper,
  InstructionStepSecondaryTitle,
} from "./style";
import { ContextUI } from "../../providers/UIProvider";
import Tooltip from "../Tooltip";
import IconCopy from "../../assets/icons/iconCopy";
import { Context } from "../../app";
import InfoIcon from "../../assets/icons/infoIcon";
import ExpirationDate from "../BankInfo/ExpirationDate";

type TInstructionConfig = keyof typeof instructions;
type TInstructionData = { [key: string]: string };

const Instruction = ({
  configName,
  instructionData,
}: {
  configName: TInstructionConfig;
  instructionData: TInstructionData;
}) => {
  const { currentState } = useContext(Context);
  const { handleCopy } = useContext(ContextUI);
  const config = instructions[configName];

  if (!config) {
    return null;
  }

  const {
    title,
    instructionList,
    getInfo,
    getExpirationDate,
    infoBlockHasBottomPosition = true,
  } = config || {};
  const info = getInfo && getInfo({ data: instructionData });
  const expirationDate =
    getExpirationDate && getExpirationDate({ data: instructionData });

  const renderInfo = () => {
    if (info) {
      return (
        <>
          <BlockDetailsWrapper
            // @ts-ignore
            $mb="0"
            width="100%"
            style={{ justifyContent: "center" }}
          >
            <ChallengeInfoWrapper>
              <StyledInfoImage>
                <InfoIcon />
              </StyledInfoImage>
              <ChallengeMainTitle>
                <Trans message={info} />
              </ChallengeMainTitle>
            </ChallengeInfoWrapper>
          </BlockDetailsWrapper>
          {!infoBlockHasBottomPosition ? (
            <InnerLine style={{ marginBottom: 0 }} />
          ) : null}
        </>
      );
    }

    return null;
  };

  return (
    // @ts-ignore
    <InnerHeaderDataWrapper $top="0" $padding="20px" $gap="16px">
      {!infoBlockHasBottomPosition && renderInfo()}
      <ChallengeMainTitle>
        <Trans message={title} />:
      </ChallengeMainTitle>
      <InstructionWrapper>
        {instructionList?.map((step, index) => {
          const { title, secondaryTitle, getPaymentData, getCopyInfo } =
            step || {};
          const paymentData =
            getPaymentData &&
            getPaymentData({
              data: instructionData,
              state: currentState || {},
            });
          const { copy, text } =
            (getCopyInfo &&
              getCopyInfo({
                data: instructionData,
                state: currentState || {},
              })) ||
            {};

          return (
            <InstructionStepWrapper>
              <InstructionStepTitleWrapper key={index}>
                <InstructionStepNumber>{index + 1}</InstructionStepNumber>
                <InstructionStepTitle>
                  <Trans message={title} withoutWrapper />
                  {/* // TODO: Refactor to use a translation function */}
                  {secondaryTitle && (
                    <InstructionStepSecondaryTitle bold={secondaryTitle?.bold}>
                      {" "}
                      <Trans message={secondaryTitle.text} />
                    </InstructionStepSecondaryTitle>
                  )}
                </InstructionStepTitle>
              </InstructionStepTitleWrapper>
              {paymentData && (
                <InstructionStepDataWrapper>
                  <InstructionStepDataText>
                    {paymentData}
                  </InstructionStepDataText>
                  {copy && (
                    <InstructionStepCopyIconWrapper
                      onClick={() => handleCopy(copy, text)}
                    >
                      <IconColorWrapper>
                        <Tooltip text={"Сopy"}>
                          <IconCopy />
                        </Tooltip>
                      </IconColorWrapper>
                    </InstructionStepCopyIconWrapper>
                  )}
                </InstructionStepDataWrapper>
              )}
            </InstructionStepWrapper>
          );
        })}
      </InstructionWrapper>
      {expirationDate && <ExpirationDate expiration_date={expirationDate} />}
      {infoBlockHasBottomPosition && renderInfo()}
    </InnerHeaderDataWrapper>
  );
};

export default Instruction;
