import { useState, useEffect, useMemo } from "preact/hooks";
import { state, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import CustomerInputControl from "./CustomerInput/index.js";
import Trans from "./Trans";
import {
  FormDescription,
  InputFormActionWrapper,
  FormInputContainerWrapper,
} from "../styles/index.js";

const CustomerInput = ({
  nameKey,
  label,
  autocomplete,
  prefix,
  filterPattern,
  pattern,
  validationMessage,
  nonRequired,
  autofocus,
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  type,
  placeholder,
  onClick,
  inputDisplay,
  isDropdown,
}) => {
  const [focused, setFocused] = useState(false);

  const selectedMethod = state.getSelectedPaymentMethod();
  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);

  const ThemedCustomerInput =
    CustomerInputControl[currentTheme] || CustomerInputControl.default;

  const currentPrefix = useMemo(() => {
    if (prefix && typeof prefix === "function") {
      return prefix({ method: selectedMethod });
    }

    return prefix;
  }, [prefix, selectedMethod]);

  useEffect(() => {
    if (!ThemedCustomerInput) {
      logger.error("Component not found");
    }
  }, []);

  const isValid = (value) => {
    if (!nonRequired && !value) {
      return false;
    }

    if (!!value && pattern) {
      if (typeof pattern === "function") {
        return pattern({ method: selectedMethod }).test(value);
      }

      if (typeof pattern.test === "function") {
        return pattern.test(value);
      }

      return false;
    }

    return true;
  };

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev[nameKey];

      return prev;
    });
  };

  const onInput = (e) => {
    let val = e.target.value || "";

    if (currentPrefix && typeof currentPrefix === "string") {
      if (
        val.startsWith(currentPrefix) &&
        val.slice(currentPrefix.length).startsWith(currentPrefix)
      ) {
        val = val.slice(currentPrefix.length);
      } else if (val.length <= currentPrefix.length) {
        val = currentPrefix;
      } else if (!val?.startsWith(currentPrefix)) {
        const inputPrefix = val.slice(0, currentPrefix.length);

        let matchingLength = 0;
        let usedIndexes = new Set();

        for (let i = 0; i < currentPrefix.length; i++) {
          for (let j = 0; j < inputPrefix.length; j++) {
            if (currentPrefix[i] === inputPrefix[j] && !usedIndexes.has(j)) {
              matchingLength++;
              usedIndexes.add(j);
              break;
            }
          }
        }

        const inputValue = val.slice(matchingLength);

        val = currentPrefix + inputValue;
      }
    }

    if (filterPattern) {
      val = val?.match(filterPattern)?.join("");
    }

    if (isValid(val)) {
      clearErrors();
    }

    setValues((prev) => ({ ...prev, [nameKey]: val }));
  };

  const onBlur = () => {
    setFocused(false);
    const toValidate = (values[nameKey] || "").trim();

    setValues((prev) => ({ ...prev, [nameKey]: toValidate }));

    if (isValid(toValidate)) {
      return clearErrors();
    }

    return setErrors((prev) => ({
      ...prev,
      [nameKey]: toValidate
        ? validationMessage || CONST_ERROR_LIST.pattern
        : CONST_ERROR_LIST.required,
    }));
  };

  const errorChecking = () => {
    if (!errors[nameKey]) {
      return false;
    }

    return !focused;
  };

  const validationValue = () => {
    if (
      (state.get()?.status === "failed_retry" ||
        values[nameKey] === undefined ||
        values[nameKey] === "") &&
      currentPrefix &&
      pattern
    ) {
      let needClean = false;

      if (pattern && typeof pattern === "function") {
        needClean = !pattern({ method: selectedMethod }).test(values[nameKey]);
      }

      if (typeof pattern.test === "function") {
        needClean = !pattern.test(values[nameKey]);
      }

      if (needClean) {
        onInput({
          target: {
            value: "",
          },
        });
      }
    }
  };

  useEffect(() => {
    validationValue();
  }, []);

  if (!ThemedCustomerInput) {
    return;
  }

  return (
    <InputFormActionWrapper
      onClick={onClick}
      isNoEditable={!!onClick}
      $margin="0 auto 16px"
    >
      <FormDescription $errorChecking={errorChecking()} $focused={focused}>
        {<Trans message={label} />}
      </FormDescription>
      <FormInputContainerWrapper>
        <ThemedCustomerInput
          nameKey={nameKey}
          value={
            inputDisplay && typeof inputDisplay === "function"
              ? inputDisplay(values[nameKey])
              : values[nameKey]
          }
          type={type}
          autocomplete={autocomplete}
          placeholder={placeholder || label}
          onInput={onInput}
          onBlur={onBlur}
          autofocus={autofocus}
          $focused={focused}
          setFocused={setFocused}
          error={errors[nameKey] || errors}
          errorChecking={errorChecking}
          inputRef={inputRef}
          isDropdown={isDropdown}
        />
      </FormInputContainerWrapper>
    </InputFormActionWrapper>
  );
};

export default CustomerInput;
