import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  0% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: 66px center;
  }
  25% {
    margin-left: -25px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: 66px center;
  }
  50% {
    margin-left: -33px;
    transform: translateY(-50%) rotate(-360deg);
    transform-origin: 66px center;
  }
  50.01% {
    transform-origin: -12px center;
    margin-left: -33px; 
  }
  75% {
    margin-left: -36px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: -12px center;
  }
  100% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: -12px center;
  }
`;

const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 300px;
  height: 170px;
  filter: blur(15px) contrast(30);
  background: #fff;
  margin: 0 auto;
`;

const Ball = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: 66px center;
  margin-left: 40px;
`;

const InnerBall = styled.div`
  content: "";
  width: 68px;
  height: 68px;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  border-radius: 100%;
  background: ${(props) => props.theme.mainColor};
`;

const Ball1 = styled(Ball)`
  margin-left: -50px;
`;

const Ball2 = styled(Ball)`
  margin-left: 25px;
  transform: translateY(-50%) rotate(180deg);
  margin-left: -100px;
  animation: ${rotateAnimation} 1.7s linear infinite;
  z-index: 2;
`;

const Ball3 = styled(Ball)`
  margin-left: 120px;
`;

const GooeyAnimation = () => (
  <Wrapper>
    <Ball1>
      <InnerBall />
    </Ball1>
    <Ball2>
      <InnerBall />
    </Ball2>
    <Ball3>
      <InnerBall />
    </Ball3>
  </Wrapper>
);

export default GooeyAnimation;
