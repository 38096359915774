import { useState, useEffect, useContext, useRef } from "preact/hooks";
import {
  InputWrapper,
  CurrencySymbol,
  AmountInput,
  IconColorWrapper,
  FormLoaderWrapper,
} from "../../styles";
import {
  CryptoCurrencyInputTitle,
  CryptoCurrencyInputTitleWrapper,
} from "./style";
import IconCopy from "../../assets/icons/iconCopy";
import { formatCryptoCurrency } from "../../constants/CryptoCurrencyIcon";
import { ContextUI } from "../../providers/UIProvider";
import { i18n } from "../../services";
import { adjustFontSize } from "../../services/utils";
import FlexLoad from "../Loaders/FlexLoader";

const CryptoInput = ({
  label,
  name,
  currency,
  value,
  onChange,
  formatNumberWithSpaces,
  copyValue,
  autofocus,
  disabled,
  isAmountBelowMin,
  isLoading,
}) => {
  const ref = useRef(null);
  const { handleCopy } = useContext(ContextUI);
  const [formattedValue, setFormattedValue] = useState(
    formatNumberWithSpaces(value, label),
  );

  const labelTranslate = i18n.getMessage({ message: label });

  useEffect(() => {
    const formatted = formatNumberWithSpaces(value, label);
    setFormattedValue(formatted);
  }, [value, label]);

  const handleInputChange = (e) => {
    let newValue = e.target.value.replace(/\s/g, "");

    if (/[^0-9.]/.test(newValue)) {
      return;
    }

    if ((newValue.match(/\./g) || []).length > 1) {
      return;
    }

    if (newValue.includes("..")) {
      return;
    }

    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  useEffect(() => {
    if (ref?.current) {
      adjustFontSize({ ref, size: 14, length: 5 });
    }
  }, [ref, labelTranslate]);

  return (
    <>
      <InputWrapper disabled={disabled} isAmountBelowMin={isAmountBelowMin}>
        <CurrencySymbol ref={ref}>{labelTranslate}</CurrencySymbol>
        <CryptoCurrencyInputTitle>
          <CryptoCurrencyInputTitleWrapper>
            {formatCryptoCurrency(currency)}
          </CryptoCurrencyInputTitleWrapper>
        </CryptoCurrencyInputTitle>
        {isLoading ? (
          <FormLoaderWrapper height="24px" style={{ marginLeft: "auto" }}>
            <FlexLoad size="small" />
          </FormLoaderWrapper>
        ) : (
          <>
            <AmountInput
              type="text"
              autofocus={autofocus}
              readOnly={disabled}
              value={formattedValue}
              onInput={handleInputChange}
            />
            {copyValue && (
              <span
                onClick={() => handleCopy(copyValue, "Amount copied")}
                style={{
                  cursor: "pointer",
                  color: "blue",
                }}
              >
                <IconColorWrapper style={{ margin: "4px 4px 0px 0px" }}>
                  <IconCopy />
                </IconColorWrapper>
              </span>
            )}
          </>
        )}
      </InputWrapper>
    </>
  );
};

export default CryptoInput;
