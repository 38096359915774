import {
  InnerHeaderDataWrapper,
  InnerLine,
  BlockDetailsWrapper,
  DetailsTransactionSubTitle,
  IconColorWrapper,
  DetailsTransactionTitleBankTransferArgentina,
  CopyWrapper,
  CopyActionWrapper,
} from "../../../styles";
import {
  ChallengeMainTitle,
  ChallengeUssdInfoWrapper,
  ChallengeNumberTitle,
  PaymentMethodTitle,
  ChallengeInfoWrapper,
  StyledInfoImage,
} from "../style";
import Trans from "../../Trans";
import Tooltip from "../../Tooltip/Tooltip";
import IconCopy from "../../../assets/icons/iconCopy";
import InfoIcon from "../../../assets/icons/infoIcon";

const ChallengeArgentinaInfo = ({
  challenge,
  transactionIdRef,
  handleCopy,
}) => {
  const accountNumber = challenge?.account_number;
  const accountNumberAlias = challenge?.account_number_alias;

  return (
    <>
      <InnerHeaderDataWrapper pt="20px">
        <PaymentMethodTitle $mr="auto" $mb="21px">
          <Trans message="paymentInstructions" />
        </PaymentMethodTitle>
        <ChallengeMainTitle $mb="16px">
          <Trans message="paymentSteps" />
        </ChallengeMainTitle>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>1</ChallengeNumberTitle>
          <Trans message="copyAlias" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>2</ChallengeNumberTitle>
          <Trans message="openBankApp" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>3</ChallengeNumberTitle>
          <Trans message="selectMoneyTransfer" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>4</ChallengeNumberTitle>
          <Trans message="selectNewClient" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>5</ChallengeNumberTitle>
          <Trans message="pasteCopiedCbuCode" />
        </ChallengeUssdInfoWrapper>
        <InnerLine />
        <BlockDetailsWrapper mb="22px">
          <ChallengeInfoWrapper>
            <StyledInfoImage>
              <InfoIcon />
            </StyledInfoImage>
            <ChallengeMainTitle>
              <Trans message="amountComplete15Mins" />
            </ChallengeMainTitle>
          </ChallengeInfoWrapper>
        </BlockDetailsWrapper>
        <BlockDetailsWrapper width="100%">
          <DetailsTransactionTitleBankTransferArgentina>
            <Trans message="CBU/CVU" />
          </DetailsTransactionTitleBankTransferArgentina>
          <CopyWrapper>
            <DetailsTransactionSubTitle $mr="8px">
              {accountNumber}
            </DetailsTransactionSubTitle>
            <CopyActionWrapper
              onClick={() => handleCopy(accountNumber, "CBUCVUCopy")}
            >
              <IconColorWrapper>
                <Tooltip text={"Сopy"}>
                  <IconCopy />
                </Tooltip>
              </IconColorWrapper>
            </CopyActionWrapper>
          </CopyWrapper>
        </BlockDetailsWrapper>
        <BlockDetailsWrapper width="100%">
          <DetailsTransactionTitleBankTransferArgentina>
            <Trans message="alias" />
          </DetailsTransactionTitleBankTransferArgentina>
          <CopyWrapper>
            <DetailsTransactionSubTitle mr="8px" ref={transactionIdRef}>
              {accountNumberAlias}
            </DetailsTransactionSubTitle>
            <CopyActionWrapper
              onClick={() => handleCopy(accountNumberAlias, "aliasCopy")}
            >
              <IconColorWrapper>
                <Tooltip text={"Сopy"}>
                  <IconCopy />
                </Tooltip>
              </IconColorWrapper>
            </CopyActionWrapper>
          </CopyWrapper>
        </BlockDetailsWrapper>
      </InnerHeaderDataWrapper>
    </>
  );
};

export default ChallengeArgentinaInfo;
