import Trans from "../Trans";
import cvvTooltip from "../../assets/icons/cvvTooltipPrivat.png";
import { TooltipTextCvv, TooltipCvvText, TooltipImg } from "../../styles";

const InputCvvTooltipDefault = () => {
  return (
    <TooltipTextCvv>
      <TooltipCvvText>
        <Trans message="privat.lastThreeDigits" />
      </TooltipCvvText>
      <TooltipImg src={`${cvvTooltip}`} alt="cvv-code" />
    </TooltipTextCvv>
  );
};

export default InputCvvTooltipDefault;
