import { useState } from "preact/hooks";
import { FormLoaderWrapper, BaseButton } from "../../styles";
import { GoBackButtonWrapper } from "./style";
import Trans from "../Trans";
import Loader from "../Loaders";
import BankInfo from "../BankInfo/BankInfo";
import ChallengeComplete from "./ChallengeComplete";
import useApmTransaction from "../../hooks/useApmTransaction";

const ChallengeBankTransferAfrica = ({ bankTransferData, handleCopy }) => {
  const {
    bank_name,
    account_number,
    bank_code,
    account_name,
    expiration_date,
  } = bankTransferData || {};
  const bankNameIcon = `${bank_name?.toLowerCase()?.replace(/\s+/g, "_")}`;

  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    setIsPaymentCompleted(true);

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  return bankTransferData ? (
    <>
      {isPaymentCompleted ? (
        <ChallengeComplete />
      ) : (
        <>
          <BankInfo
            bankNameIcon={bankNameIcon}
            bankName={bank_name}
            bankCode={bank_code}
            accountName={account_name}
            accountNumber={account_number}
            expiration_date={expiration_date}
            handleCopy={handleCopy}
            mainTitle="virtualAccountTitle"
          />
          <GoBackButtonWrapper>
            <BaseButton onClick={handlePaymentMade} marginBottom="22px">
              <Trans message="madePayment" />
            </BaseButton>
          </GoBackButtonWrapper>
        </>
      )}
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeBankTransferAfrica;
