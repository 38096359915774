import { useContext, useEffect } from "preact/hooks";
import Loader from ".";
import { LoaderContainer, LoaderWrapper } from "./style";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import { state } from "../../services";
import { Context } from "../../app";
import useTimer from "../../hooks/useTimer";
import { ContextUI } from "../../providers/UIProvider";

const StatusLoader = () => {
  const { currentState, onGoBackButtonClick } = useContext(Context);
  const { notificationData, setNotificationData } = useContext(ContextUI);

  const handleTimeout = () => {
    const cancelUrl = state.get().options?.cancel_url;

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "error",
      text: "cancelPaymentText",
      isCloseAutomatically: false,
      buttonConfig: {
        hasButton: true,
        buttonText: "cancelPayment",
        buttonAction: () => onGoBackButtonClick(cancelUrl),
      },
    }));
  };

  useEffect(() => {
    if (
      !currentState?.loadingData?.status &&
      !!notificationData?.buttonConfig
    ) {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: false,
        isCloseAutomatically: true,
        buttonConfig: null,
      }));
    }
  }, [currentState?.loadingData?.status]);

  useTimer({
    status: currentState?.loadingData?.status,
    action: handleTimeout,
    repeatTrigger: !notificationData?.isOpen,
  });

  const selectedMethod = state.getSelectedPaymentMethod();

  useEffect(() => {
    if (!currentState || !currentState.appIsMounted) {
      const selectedCrypto = currentState?.crypto?.find(
        (item) => item.currency === selectedMethod?.currency,
      );

      const cryptoMethodLoaded = !!(
        selectedMethod?.method === PAYMENT_METHODS.CRYPTO &&
        !selectedCrypto?.error_code &&
        !selectedCrypto?.address
      );

      const anotherMethodsLoaded = !!(
        currentState?.status !== "new" &&
        (currentState?.identifier || currentState?.error)
      );

      const isTestMode = state.isTestMode();

      if (
        currentState?.loadingData?.status &&
        (anotherMethodsLoaded || cryptoMethodLoaded || isTestMode)
      ) {
        state.set({ appIsMounted: true });

        state.setLoading({ status: false, eventName: "app_init" });
      }
    }
  }, [
    currentState?.identifier,
    currentState?.loadingData?.status,
    currentState?.status,
    currentState?.selected_method_id,
    selectedMethod,
  ]);

  useEffect(() => {
    if (currentState?.status === "form_submitted") {
      state.setLoading({ status: false, eventName: "submit" });
    }
  }, [currentState?.status]);

  useEffect(() => {
    if (
      !["challenge_accepted", "challenge_succeeded"].includes(
        currentState?.status,
      )
    ) {
      state.setLoading({ status: false, eventName: "challengeSubmit" });
    }
  }, [currentState?.status]);

  return currentState?.loadingData?.status ? (
    <LoaderContainer $hasBlur={currentState?.loadingData?.hasBlur}>
      <LoaderWrapper>
        <Loader />
        {currentState?.loadingData?.text ? (
          <span>{currentState?.loadingData?.text}</span>
        ) : null}
      </LoaderWrapper>
    </LoaderContainer>
  ) : null;
};

export default StatusLoader;
