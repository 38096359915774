const InfoCombineIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41722 10.2001C7.59513 10.2001 7.74549 10.1426 7.86829 10.0276C7.9911 9.9126 8.0525 9.7701 8.0525 9.6001V6.8001C8.0525 6.6301 7.99233 6.4876 7.87199 6.3726C7.75164 6.2576 7.60252 6.2001 7.42463 6.2001C7.24672 6.2001 7.09636 6.2576 6.97356 6.3726C6.85075 6.4876 6.78935 6.6301 6.78935 6.8001V9.6001C6.78935 9.7701 6.84951 9.9126 6.96985 10.0276C7.0902 10.1426 7.23933 10.2001 7.41722 10.2001ZM7.41722 5.0001C7.59513 5.0001 7.74549 4.94294 7.86829 4.82861C7.9911 4.71428 8.0525 4.57261 8.0525 4.40361C8.0525 4.2346 7.99233 4.09176 7.87199 3.9751C7.75164 3.85843 7.60252 3.8001 7.42463 3.8001C7.24672 3.8001 7.09636 3.85726 6.97356 3.97158C6.85075 4.08591 6.78935 4.22758 6.78935 4.39658C6.78935 4.56559 6.84951 4.70843 6.96985 4.8251C7.0902 4.94176 7.23933 5.0001 7.41722 5.0001ZM7.42577 13.4001C6.49856 13.4001 5.6256 13.2334 4.80689 12.9001C3.98818 12.5668 3.2718 12.1084 2.65777 11.5251C2.04373 10.9418 1.56128 10.2614 1.2104 9.48413C0.859521 8.70681 0.684082 7.87625 0.684082 6.99246C0.684082 6.10866 0.859521 5.28065 1.2104 4.50843C1.56128 3.73621 2.04373 3.05843 2.65777 2.4751C3.2718 1.89176 3.98793 1.43343 4.80615 1.1001C5.62439 0.766764 6.49866 0.600098 7.42896 0.600098C8.35928 0.600098 9.23087 0.766764 10.0437 1.1001C10.8566 1.43343 11.57 1.89176 12.1841 2.4751C12.7981 3.05843 13.2806 3.73745 13.6315 4.51216C13.9823 5.28689 14.1578 6.11466 14.1578 6.9955C14.1578 7.87634 13.9823 8.70565 13.6315 9.48343C13.2806 10.2612 12.7981 10.9418 12.1841 11.5251C11.57 12.1084 10.8553 12.5668 10.0398 12.9001C9.2243 13.2334 8.35296 13.4001 7.42577 13.4001Z"
      fill="white"
      fill-opacity="0.9"
    />
  </svg>
);

export default InfoCombineIcon;
