import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import { state } from "../../services";
import CryptoContent from "../CryptoWidgetForm/CryptoContent";
import { Context } from "../../app";

const POLLING_TIME = 2000;

const Crypto = ({ data, handleCopy }) => {
  const pollingTimerRef = useRef(null);
  const { currentState = {}, onGoBackButtonClick } = useContext(Context);
  const { amount, currency, cryptoConverted, options } = currentState || {};
  const redirectUrl = options?.redirect_url;
  const selectedMethod = state.getSelectedPaymentMethod();
  const selectedCrypto = data?.crypto || data?.data || {};

  const isCryptoMethod = selectedMethod?.method === PAYMENT_METHODS.CRYPTO;

  const selectedCryptoWithConversion = {
    ...selectedCrypto,
    ...cryptoConverted,
    currency_amount: cryptoConverted?.conversion_amount,
    currency: cryptoConverted?.conversion_currency,
  };

  const parseAmount = (amount) => {
    if (amount && typeof amount === "string") {
      amount = amount.replace(/\.$/, "");
      amount = amount ? parseFloat(amount) : null;
    }

    return amount;
  };

  const onCryptoAmountChange = ({ currency_amount, pay_amount }) => {
    currency_amount = parseAmount(currency_amount);
    pay_amount = parseAmount(pay_amount);

    const result = state.getAndSaveConversion(
      {
        conversion_currency: pay_amount ? selectedMethod?.currency : currency,
        conversion_amount: currency_amount || pay_amount || amount,
        pay_currency: pay_amount ? currency : selectedMethod?.currency,
      },
      { isInverted: !!pay_amount },
    );

    return result.then((res) => {
      setExchangeData(res);

      return res;
    });
  };

  useEffect(() => {
    if (
      isCryptoMethod &&
      selectedCrypto?.address &&
      !cryptoConverted?.pay_amount
    ) {
      onCryptoAmountChange({});
    }

    return () => {
      clearTimeout(pollingTimerRef.current);
    };
  }, [isCryptoMethod, selectedCrypto?.address, cryptoConverted?.pay_amount]);

  return (
    <CryptoContent
      isCryptoFormVisible={cryptoConverted?.pay_amount}
      onAmountChange={onCryptoAmountChange}
      currentState={selectedCryptoWithConversion}
      redirectUrl={redirectUrl}
      exchangeData={exchangeData}
    />
  );
};

export default Crypto;
