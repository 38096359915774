/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef } from "preact/hooks";
import Lottie from "lottie-react";
import asquadLoader from "../animation/loop.json";

const AsquadLoader = () => {
  const animationRef = useRef(null);

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.setSpeed(0.8);
    }
  }, []);

  return (
    <>
      <div
        style={{
          width: "64px",
          height: "64px",
          margin: "0 auto",
        }}
      >
        <Lottie
          animationData={asquadLoader}
          width={64}
          height={64}
          lottieRef={animationRef}
          loop={true}
          autoplay={true}
        />
      </div>
    </>
  );
};

export default AsquadLoader;
