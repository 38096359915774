import { useContext, useState } from "preact/hooks";
import { PaymentMethodList, StyledMethodCardContainer } from "./style";
import { state } from "../../services";
import {
  DISPLAY_PAYMENT_METHODS,
  SUPPORTED_PAYMENT_METHODS,
  PAYMENT_METHOD_SUBTITLE,
} from "../../constants/paymentMethods";
import PaymentMethodsSelection from "./PaymentMethodsSelection";
import Scroll from "../Scroll";
import { ContextUI } from "../../providers/UIProvider";

const PaymentMethods = () => {
  const { setModalData } = useContext(ContextUI);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const openModal = (content) => {
    const isProviderTitle = content[0]?.isProvider || !!content[0]?.channel;
    const contentMethod = content[0]?.method;
    const title = DISPLAY_PAYMENT_METHODS[contentMethod];
    const providerSubtitle =
      PAYMENT_METHOD_SUBTITLE[contentMethod] || "choose_provider";
    const subtitle = isProviderTitle ? providerSubtitle : "choose_currency";

    setModalData({
      isOpen: true,
      content: <PaymentMethodsSelection data={content} state={state} />,
      title: title || subtitle,
      subTitle: title ? subtitle : null,
      selectedMethod: contentMethod,
      isFullView: content?.length > 4,
    });
  };

  const filteredSupportedMethods = state
    .getAvailablePaymentMethods()
    .filter((item) => SUPPORTED_PAYMENT_METHODS.includes(item.method));

  const groupMethodsByType = (methods) => {
    const groupedMethods = {};

    methods.forEach((method) => {
      const methodType = method.method;

      if (!groupedMethods[methodType]) {
        groupedMethods[methodType] = [];
      }

      groupedMethods[methodType].push(method);
    });

    return groupedMethods;
  };

  const groupedMethods = groupMethodsByType(filteredSupportedMethods);

  return (
    <PaymentMethodList>
      <Scroll>
        <StyledMethodCardContainer>
          <PaymentMethodsSelection
            groupedMethods={groupedMethods}
            setSelectedMethod={setSelectedMethod}
            selectedMethod={selectedMethod}
            paymentLevel="method"
            data={Object.keys(groupedMethods)}
            openModal={openModal}
          />
        </StyledMethodCardContainer>
      </Scroll>
    </PaymentMethodList>
  );
};

export default PaymentMethods;
