const IconCopyCombine = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33301 12.6673C1.33301 13.1978 1.54372 13.7065 1.91879 14.0815C2.29387 14.4566 2.80257 14.6673 3.33301 14.6673H5.33301V13.334H3.33301C3.1562 13.334 2.98663 13.2637 2.8616 13.1387C2.73658 13.0137 2.66634 12.8441 2.66634 12.6673V3.33398C2.66634 3.15717 2.73658 2.9876 2.8616 2.86258C2.98663 2.73756 3.1562 2.66732 3.33301 2.66732H12.6663C12.8432 2.66732 13.0127 2.73756 13.1377 2.86258C13.2628 2.9876 13.333 3.15717 13.333 3.33398V5.33398H7.33301C6.80258 5.33398 6.29387 5.5447 5.91879 5.91977C5.54372 6.29484 5.33301 6.80355 5.33301 7.33398V16.6673C5.33301 17.1978 5.54372 17.7065 5.91879 18.0815C6.29387 18.4566 6.80258 18.6673 7.33301 18.6673H16.6663C17.1968 18.6673 17.7055 18.4566 18.0806 18.0815C18.4556 17.7065 18.6663 17.1978 18.6663 16.6673V7.33398C18.6663 6.80355 18.4556 6.29484 18.0806 5.91977C17.7055 5.5447 17.1968 5.33398 16.6663 5.33398H14.6663V3.33398C14.6663 2.80355 14.4556 2.29484 14.0806 1.91977C13.7055 1.5447 13.1968 1.33398 12.6663 1.33398H3.33301C2.80257 1.33398 2.29387 1.5447 1.91879 1.91977C1.54372 2.29484 1.33301 2.80355 1.33301 3.33398V12.6673ZM6.66634 7.33398C6.66634 7.15717 6.73658 6.9876 6.8616 6.86258C6.98663 6.73756 7.1562 6.66732 7.33301 6.66732H16.6663C16.8432 6.66732 17.0127 6.73756 17.1377 6.86258C17.2628 6.9876 17.333 7.15717 17.333 7.33398V16.6673C17.333 16.8441 17.2628 17.0137 17.1377 17.1387C17.0127 17.2637 16.8432 17.334 16.6663 17.334H7.33301C7.1562 17.334 6.98663 17.2637 6.8616 17.1387C6.73658 17.0137 6.66634 16.8441 6.66634 16.6673V7.33398Z"
      fill="white"
    />
  </svg>
);

export default IconCopyCombine;
