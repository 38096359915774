import engIcon from "../../assets/icons/enIcon.svg";
import uaIcon from "../../assets/icons/uaIcon.svg";
import ruIcon from "../../assets/icons/ruIcon.svg";
import ptIcon from "../../assets/icons/ptIcon.svg";
import jpIcon from "../../assets/icons/jpIcon.svg";
import esIcon from "../../assets/icons/spainIcon.svg";
import koIcon from "../../assets/icons/koreaIcon.svg";
import plIcon from "../../assets/icons/polandIcon.svg";
import trIcon from "../../assets/icons/turkeyIcon.svg";
import kzIcon from "../../assets/icons/kzIcon.svg";
import { StyledLanguageButton, StyledCurrentLanguageIcon } from "../../styles";
import { ContextUI } from "../../providers/UIProvider";
import { useContext } from "preact/hooks";
import LanguageModal from "../Modals/LanguageModal";
import LANGUAGE_LIST from "../LanguageModalConfig";

const FormHeaderLanguageAction = ({ locale }) => {
  const { setModalData } = useContext(ContextUI);
  const languageIcons = {
    en: engIcon,
    uk: uaIcon,
    ua: uaIcon,
    ru: ruIcon,
    pt: ptIcon,
    jp: jpIcon,
    tr: trIcon,
    pl: plIcon,
    es: esIcon,
    ko: koIcon,
    kz: kzIcon,
  };

  const image = languageIcons[locale];

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: <LanguageModal currentLocale={locale} />,
      title: "chooseLanguage",
      isFullView: LANGUAGE_LIST.length > 4,
    });
  };

  return (
    <StyledLanguageButton>
      <StyledCurrentLanguageIcon onClick={handleOpen} src={`${image}`} />
    </StyledLanguageButton>
  );
};

export default FormHeaderLanguageAction;
