import { ComponentChild } from "preact";
import FormFields from "../components/Form/FormFields";
import Trans from "../components/Trans";
import { PAYMENT_METHODS } from "./paymentMethods";

interface FormFields {
  isActive: boolean;
}

interface IsAvailableArgs {
  fields?: FormFields[];
}

interface PaymentMethodInfoLink {
  href: string;
  target: string;
  message: string;
}

interface PaymentMethodInfo {
  isAvailable: (args?: IsAvailableArgs) => boolean;
  content?: () => ComponentChild;
  link?: PaymentMethodInfoLink;
  formFieldsTitle?: () => ComponentChild;
  payButtonText?: string | null;
}

interface InfoAboutPaymentMethod {
  [key: string]: PaymentMethodInfo;
}

export const INFORMATION_ABOUT_PAYMENT_METHODS: InfoAboutPaymentMethod = {
  [PAYMENT_METHODS.CARD]: {
    isAvailable: () => false,
  },
  [PAYMENT_METHODS.BLIK]: {
    isAvailable: () => false,
    payButtonText: "proceed",
  },
  [PAYMENT_METHODS.WALLET_VEGA]: {
    isAvailable: () => true,
    content: () => (
      <>
        <Trans message="walletVegaInfoContent" withoutWrapper />
        <span>
          <Trans message="walletVegaInfoContentEnding" withoutWrapper />
        </span>
      </>
    ),
    link: {
      href: "https://vega-wallet.com/whitelist/",
      target: "_blank",
      message: "howToUseMustRead",
    },
    formFieldsTitle: () => (
      <Trans message="walletVegaFormFieldsTitle" withoutWrapper />
    ),
    payButtonText: "Confirm and Submit",
  },
  default: {
    isAvailable: ({ fields } = {}) =>
      fields ? fields.filter(({ isActive }) => isActive).length === 0 : false,
    content: () => (
      <Trans message="infoAboutPaymentMethodDefault" withoutWrapper></Trans>
    ),
    payButtonText: null,
  },
};
