import { useContext, useEffect } from "preact/hooks";
import loadZenFingerprintScript from "../Challenge/ChallengeZenFingerScript";
import { api, logger, state } from "../../services";
import ChallengeControl from "../Challenge/ChallengeControl";
import Loader from "../Loaders";
import { ChallengeMainWrapper, PendingPageTitle } from "../../styles";
import Trans from "../Trans";
import {
  CHALLENGE_TYPES,
  RENDER_CHALLENGE_TYPES,
} from "../../constants/challengeTypes";
import Scroll from "../Scroll";
import useTimer from "../../hooks/useTimer";
import { Context } from "../../app";
import { ContextUI } from "../../providers/UIProvider";
import { STATUS_TO_ROUTER_MAP } from "../../services/constants";

const PendingPageDefault = ({
  reference,
  currency,
  amount,
  countdown,
  redirect_url,
  options,
  onButtonClick,
  currentState,
  status,
  challenge,
  cancelUrl,
  sessionId,
  signature,
}) => {
  const { onGoBackButtonClick } = useContext(Context);
  const { notificationData, setNotificationData } = useContext(ContextUI);

  const isChallenge = ["challenge", "challenge_accepted"].includes(status);
  const isQrChallenge =
    isChallenge && challenge?.type === RENDER_CHALLENGE_TYPES.QR;
  const isBrChallenge =
    isChallenge && challenge?.type === RENDER_CHALLENGE_TYPES.BR;
  const isInputChallenge =
    isChallenge && CHALLENGE_TYPES.includes(challenge?.type);

  const isRenderChallengeControl =
    isQrChallenge || isBrChallenge || isInputChallenge;

  const isZenChallenge = isChallenge && challenge?.type === "zen_fingerprint";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isZenChallenge) {
          const data = await loadZenFingerprintScript(sessionId);
          api.setFingerprintZenData(data);
        }
      } catch (error) {
        api.setFingerprintZenData("not completed");

        logger.error("Error loading Zen fingerprint script:", error);
      }
    };

    fetchData();
  }, [isZenChallenge, sessionId]);

  const handleRedirectTimeout = () => {
    const redirectUrl = challenge?.url;

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "error",
      text: "redirectNotificationText",
      isCloseAutomatically: false,
      buttonConfig: {
        hasButton: true,
        buttonText: "redirectNotificationButton",
        buttonAction: () => state.changeWindowLocation(redirectUrl),
      },
    }));
  };

  const handleLongLoadingTimeout = () => {
    const cancelUrl = state.get().options?.cancel_url;

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "error",
      text: "cancelPaymentText",
      isCloseAutomatically: false,
      buttonConfig: {
        hasButton: true,
        buttonText: "cancelPayment",
        buttonAction: () => onGoBackButtonClick(cancelUrl),
      },
    }));
  };

  useEffect(() => {
    if (
      isRenderChallengeControl ||
      STATUS_TO_ROUTER_MAP[status] !== "pending"
    ) {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: false,
        isCloseAutomatically: true,
        buttonConfig: null,
      }));
    }

    return () => {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: false,
        isCloseAutomatically: true,
        buttonConfig: null,
      }));
    };
  }, [isRenderChallengeControl, STATUS_TO_ROUTER_MAP[status] === "pending"]);

  useTimer({
    status:
      !isRenderChallengeControl &&
      STATUS_TO_ROUTER_MAP[status] === "pending" &&
      challenge?.type === "redirect" &&
      challenge?.url,
    action: handleRedirectTimeout,
    timeout: 5000,
  });

  useTimer({
    status:
      !isRenderChallengeControl && STATUS_TO_ROUTER_MAP[status] === "pending",
    action: handleLongLoadingTimeout,
    repeatTrigger: !notificationData?.isOpen,
  });

  if (isRenderChallengeControl) {
    return (
      <Scroll style={{ display: "flex", flexDirection: "column" }}>
        <ChallengeControl
          reference={reference}
          currency={currency}
          amount={amount}
          countdown={countdown}
          redirect_url={redirect_url}
          options={options}
          onButtonClick={onButtonClick}
          currentState={currentState}
          isQrChallenge={isQrChallenge}
          isBrChallenge={isBrChallenge}
          cancelUrl={cancelUrl}
          challenge={challenge}
          sessionId={sessionId}
          signature={signature}
          status={status}
        />
      </Scroll>
    );
  }

  return (
    <ChallengeMainWrapper>
      <Loader />
      <PendingPageTitle>
        <Trans message="processing_your" />
        {options?.theme === "default" && (
          <br />
        )} <Trans message="payment" /> ...
      </PendingPageTitle>
    </ChallengeMainWrapper>
  );
};

export default PendingPageDefault;
