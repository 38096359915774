import { i18n } from "../../services";
import {
  ErrorTitleWrapper,
  PaymentErrorMainTitle,
  PaymentErrorTitle,
  PaymentErrorWrapper,
} from "../../styles";

interface FailedRetryInfoProps {
  hasError: boolean;
  titleError?: string;
}

const FailedRetryInfo = ({ hasError, titleError }: FailedRetryInfoProps) => {
  if (!hasError) {
    return null;
  }

  return (
    <PaymentErrorWrapper>
      <ErrorTitleWrapper>
        <PaymentErrorMainTitle>
          {/* @ts-ignore */}
          {i18n.getMessage({ message: titleError })}
        </PaymentErrorMainTitle>
      </ErrorTitleWrapper>

      <PaymentErrorTitle>
        {/* @ts-ignore */}
        {i18n.getMessage({ message: "pleasePaymentAgain" })}
      </PaymentErrorTitle>
    </PaymentErrorWrapper>
  );
};

export default FailedRetryInfo;
