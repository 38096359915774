import styled from "styled-components";

export const CurrencyCardContainer = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(123, 148, 157, 0.25);
  transition: background-color 0.3s ease;
  padding: 12px 16px 14px 16px;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover {
    background-color: #e5f0ff;
  }

  ${({ disabled }) =>
    disabled
      ? `
        pointer-events: none;
      `
      : ""}
`;

export const CurrencyImage = styled.img`
  object-fit: cover;
  height: ${({ resize }) => (resize ? resize.height : "")};
  width: ${({ resize }) => (resize ? resize.width : "24px")};
  margin-right: 8px;
`;

export const AltMethodsPaymentSubtitle = styled.p`
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
`;

export const CurrencyTitle = styled.p`
  margin-left: 12px;
  font-size: 18px;
  font-family: var(--boldFont);
`;

export const PaymentMethodList = styled.div`
  min-height: 214px;

  @media (max-height: 768px) {
    min-height: 250px;
  }
`;

export const StyledMethodCardContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  padding: 16px;
  height: 100%;

  @media (min-width: 320px) and (max-width: 351px) {
    text-align: -webkit-center;
    display: block;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    text-align: -webkit-center;
    display: block;
    /* max-height: 260px; */
  }
`;

export const InnerCardWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledSvgColoredImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: ${({ resize }) => (resize ? resize.height : "24px")};
    width: ${({ resize }) => (resize ? resize.width : "24px")};
    margin-right: 8px;
    path {
      fill: ${(props) => props.theme?.mainColor};
    }
    rect {
      stroke: ${(props) => props.theme?.mainColor};
    }
  }
`;

export const StyledPaymentIconWrapper = styled.div`
  height: ${({ resize }) => (!resize ? "24px" : "")};
`;
