import styled from "styled-components";

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme?.mainBackGroundColor};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 24px;

  ${({ $hasBlur }) => {
    if ($hasBlur) {
      return `
        background: rgba(240, 244, 245, 0.3);
        backdrop-filter: blur(5px);
      `;
    }

    return ``;
  }}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  justify-content: center;
  padding: 24px;
`;
