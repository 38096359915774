import Footer from "../Footer";
import Trans from "../Trans";
import {
  SuccessPageWrapper,
  PaymentTitle,
  TransactionInfoSecondBlock,
  BaseButton,
  LogoBrandHeader,
  SuccessWrapperKombine,
} from "../../styles";
import colorSchema from "../../colorThemes/colorSchemes";
import brand from "../../services/brand";

const SuccessPageKombine = ({ onButtonClick }) => {
  const brandData = brand.getBrand();
  const successIcon = colorSchema?.[brandData?.color_scheme]?.successIcon;

  return (
    <>
      <SuccessPageWrapper>
        <SuccessWrapperKombine>
          <div>
            <LogoBrandHeader
              style={{
                width: "40px",
                height: "40px",
              }}
              src={`${successIcon}`}
            />
          </div>

          <PaymentTitle $color="#fff" fs="20px" width="210px">
            <Trans message="paymentSuccessfully" />
          </PaymentTitle>
        </SuccessWrapperKombine>

        <TransactionInfoSecondBlock>
          <BaseButton
            textColor="#fff"
            onClick={onButtonClick}
            style={{
              marginTop: "auto",
              background: "linear-gradient(#0629DF, #00AAFF)",
              border: "none",
              height: "48px",
            }}
          >
            <Trans message="PLAY NOW" />
          </BaseButton>
        </TransactionInfoSecondBlock>
        <Footer background="#031938" />
      </SuccessPageWrapper>
    </>
  );
};

export default SuccessPageKombine;
