import { useContext, useEffect } from "preact/hooks";
import { connection } from "../services";
import { ContextUI } from "./UIProvider";

const ConnectionProvider = ({ children }) => {
  const { setNotificationData } = useContext(ContextUI);

  const setOnline = () => {
    connection.setConnectionStatus(true);

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "success",
      text: "reconnected",
      isCloseAutomatically: true,
    }));
  };

  const setOffline = () => {
    connection.setConnectionStatus(false);

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "error",
      text: "noConnection",
      isCloseAutomatically: false,
    }));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("online", setOnline);
        window.removeEventListener("offline", setOffline);
      }
    };
  }, []);

  return children;
};

export default ConnectionProvider;
