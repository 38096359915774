import { useEffect, useContext } from "preact/hooks";
import { withTransaction } from "@elastic/apm-rum-react";
import { logger } from "../../services";
import failPageControl from "../../components/FailPage/FailPageControl";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";

const Fail = () => {
  const context = useContext(Context);
  const { currentState, onGoBackButtonClick } = context;

  const { amount, currency, options, reference, request_id } =
    currentState || {};
  const { status_page, redirect_url } = options || {};
  const { enabled } = status_page || {};

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url);
  };

  useEffect(() => {
    if (enabled) {
      return;
    }

    onGoBackButtonClick(redirect_url);
  }, [redirect_url, enabled]);

  const ControlFailPage =
    failPageControl[options?.theme] || failPageControl.default;

  useEffect(() => {
    if (!ControlFailPage) {
      logger.error("Page not found");
    }
  }, []);

  return (
    <Scroll>
      <ControlFailPage
        amount={amount}
        currency={currency}
        onButtonClick={onButtonClick}
        reference={reference}
        options={options}
        request_id={request_id}
        hasGoBack
      />
    </Scroll>
  );
};

export default withTransaction("FailPage", "component")(Fail);
