import { useEffect, useContext } from "preact/hooks";
import { withTransaction } from "@elastic/apm-rum-react";
import { logger, state, theme } from "../../services";
import ErrorPageControl from "../../components/ErrorPage/ErrorPageControl";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";

const ErrorPage = () => {
  const context = useContext(Context);
  const { currentState, onGoBackButtonClick } = context;

  const { amount, currency, options, reference, error } = currentState || {};
  const { redirect_url } = options || {};

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url, {
      canReturnToMethods: !!error?.goBackMethod,
    });

    setTimeout(() => {
      if (error?.goBackMethod) {
        state.set({
          status: "opened",
          error: null,
        });
      }
    }, 500);
  };

  const currentTheme = theme.getThemeName(options?.theme);

  const ControlFailPage =
    ErrorPageControl[currentTheme] || ErrorPageControl.default;

  useEffect(() => {
    if (!ControlFailPage) {
      logger.error("Page not found");
    }
  }, []);

  if (!error) {
    return null;
  }

  return (
    <Scroll>
      <ControlFailPage
        amount={amount}
        currency={currency}
        onButtonClick={onButtonClick}
        reference={reference}
        options={options}
        title={error.title}
        description={error.description}
        hasGoBack
        goBackButtonText={error?.goBackMethod ? "backToMethods" : "goBack"}
      />
    </Scroll>
  );
};

export default withTransaction("ErrorPage", "component")(ErrorPage);
