// AmountButtons.js
import { AmountActionButton, AmountActionWrapper } from "../style";

const AmountButtons = ({
  amounts,
  selectedAmount,
  setSelectedAmount,
  getFormattedAmountParts,
}) => {
  return (
    <AmountActionWrapper $elementLength={amounts?.length}>
      {amounts?.map((item) => {
        const formattedAmount = getFormattedAmountParts(item.amount);
        const isActive = selectedAmount === item.amount;

        return (
          <AmountActionButton
            key={item.amount}
            isActive={isActive}
            onClick={() => setSelectedAmount(item.amount)}
          >
            {formattedAmount?.wholeNumber}
            {formattedAmount?.decimal && (
              <>
                <span>.</span>
                {formattedAmount.decimal}
              </>
            )}{" "}
            ¥
          </AmountActionButton>
        );
      })}
    </AmountActionWrapper>
  );
};

export default AmountButtons;
