import { state, theme } from "../../services";
import headerConfig from "./ChallengeHeaderFieldsConfig";
import { useEffect, useState } from "preact/hooks";
import Header from "../Header";
import PaymentDetails from "../PaymentDetails";

const ChallengeHeader = ({
  currentState,
  reference,
  wholeNumber,
  decimal,
  currency,
  mobileMoneyPhone,
  type,
  customer,
}) => {
  const [data, setData] = useState(headerConfig?.fields || []);
  const options = state.get().options;

  const currentTheme = theme.getThemeName(options?.theme);
  const { first_name, last_name, itn } = customer || {};

  const { isHideTransactionId, isHideDescription, isShowCurrencyCopy } =
    currentTheme || {};

  const headerFieldData = {
    type,
    currentState,
    first_name,
    last_name,
    itn,
    reference,
    mobileMoneyPhone,
    wholeNumber,
    decimal,
    currency,
    isHideTransactionId,
    isHideDescription,
    isShowCurrencyCopy,
  };

  useEffect(() => {
    const formattedData = headerConfig?.fields?.reduce((acc, item) => {
      acc = [
        ...acc,
        {
          ...item,
          condition:
            item.condition && typeof item.condition === "function"
              ? item.condition(headerFieldData)
              : item.condition,
          value:
            item.value && typeof item.value === "function"
              ? item.value(headerFieldData)
              : item.value,
        },
      ];

      return acc;
    }, []);

    setData(formattedData);
  }, [headerConfig]);

  return (
    <>
      <Header hasGradient />
      <PaymentDetails
        detailedInfo={data}
        reference={reference}
        wholeNumber={wholeNumber}
        decimal={decimal}
        currency={currency}
      />
    </>
  );
};

export default ChallengeHeader;
