import {
  PopupWrapper,
  PopupHeaderContainer,
  PopupChildrenContainer,
  PopupMainWrapper,
  SubtitlePopup,
  PopupTitle,
} from "../../styles";
import closeIcon from "../../assets/icons/closeIcon.svg";
import Trans from "../Trans";
import Scroll from "../Scroll";
import { ContextUI } from "../../providers/UIProvider";
import { useContext } from "preact/hooks";
import Overlay from "../Overlay";
import PaymentMethodsLogo from "../PaymentMethods/PaymentMethodsLogo";
import { DropdownLoading } from "../Dropdown/style";
import Loader from "../Loaders";

export const CloseIcon = ({ onClick }) => (
  <img
    style={{ width: "24px", height: "24px", marginLeft: "auto" }}
    onClick={onClick}
    src={closeIcon}
    alt="Close Icon"
  />
);

const FormPopup = () => {
  const { modalData, setModalData } = useContext(ContextUI);

  const {
    isOpen,
    content,
    header,
    title,
    subTitle,
    isFullView,
    selectedMethod,
    isLoading,
  } = modalData;

  const handleClose = () => {
    setModalData({
      isOpen: null,
      content: null,
      header: null,
      title: "",
      subTitle: "",
      isFullView: false,
      isLoading: false,
    });
  };

  return (
    <>
      {isOpen ? (
        <>
          <PopupWrapper $isFullView={isFullView}>
            <PopupHeaderContainer
              withHeader={!header || (header && !!header())}
            >
              {!header && (
                <PopupMainWrapper hasSubtitle={subTitle}>
                  {selectedMethod && (
                    <PaymentMethodsLogo
                      coloredIcon
                      selectedMethod={selectedMethod}
                    />
                  )}
                  <PopupTitle>
                    <Trans message={title} />
                  </PopupTitle>
                  <CloseIcon onClick={handleClose} />
                </PopupMainWrapper>
              )}

              {subTitle && !header ? (
                <PopupMainWrapper>
                  <SubtitlePopup>
                    <Trans message={subTitle} />
                  </SubtitlePopup>
                </PopupMainWrapper>
              ) : null}

              {header && header()}
            </PopupHeaderContainer>

            {isLoading && (
              <DropdownLoading>
                <Loader />
              </DropdownLoading>
            )}

            <Scroll>
              {content && (
                <PopupChildrenContainer>{content}</PopupChildrenContainer>
              )}
            </Scroll>
          </PopupWrapper>
          <Overlay />
        </>
      ) : null}
    </>
  );
};

export default FormPopup;
