import { useContext } from "preact/hooks";
import Trans from "../Trans";
import {
  CustomCheckboxWrapper,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxLabel,
} from "./style";
import Tooltip from "../Tooltip/Tooltip";
import { ContextUI } from "../../providers/UIProvider";

const Checkbox = ({
  name = "remember_me",
  checked,
  setRememberMe,
  checkedCard,
}) => {
  const { setNotificationData } = useContext(ContextUI);

  const handleCheckboxClick = () => {
    setNotificationData({
      text: "Card will not be saved!",
      status: "success",
      isOpen: checkedCard === null && checked,
    });

    if (checkedCard) return;

    setRememberMe((prev) => !prev);
  };

  return (
    <>
      <CustomCheckboxWrapper>
        <HiddenCheckbox
          id={name}
          checked={checkedCard || checked}
          onChange={handleCheckboxClick}
        />
        <Tooltip
          background="#10366B"
          width="280px"
          textAlign="start"
          fs="12px"
          bottom="-220%"
          top="-12%"
          left="85%"
          leftArrow="8%"
          rotate="180deg"
          text="We only store the card number and expiration date for bulk orders on our website"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyledCheckbox
              checked={checkedCard || checked}
              onClick={handleCheckboxClick}
            >
              <svg viewBox="0 0 24 24">
                <polyline points="3,12 10,17 21,6" />
              </svg>
            </StyledCheckbox>
            <CheckboxLabel htmlFor={name}>
              <Trans message="Save this card" />
            </CheckboxLabel>
          </div>
        </Tooltip>
      </CustomCheckboxWrapper>
    </>
  );
};

export default Checkbox;
