import "./finally-polyfill";
import { render } from "preact";
import App from "./app.jsx";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

render(<App />, document.getElementById("app")!);
