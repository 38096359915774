import Trans from "../Trans";
import {
  TotalInfoWrapper,
  ContainerDetailsWrapper,
  BlockDetailsWrapper,
  DetailsTransactionSubTitle,
  OuterBlockHeader,
  IconColorWrapper,
  InnerDataBoletto,
  TransactionTitle,
  BolettoDetailsSubTitle,
  ChallengeButtonWrapper,
  BaseButton,
  PaymentInfoWrapper,
} from "../../styles";
import IconCopy from "../../assets/icons/iconCopy";
import { Context } from "../../app";
import { useContext } from "preact/hooks";

const ChallengeBrBoleto = ({
  currentState,
  handleCopy,
  cancelUrl,
  handleDownloadPdf,
}) => {
  const { challenge } = currentState;
  const { onGoBackButtonClick } = useContext(Context);
  const { bank_code, pdf_url, image } = challenge?.br.fields || {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <OuterBlockHeader>
        <InnerDataBoletto style={{ marginTop: "30px" }}>
          <TransactionTitle color="#101820">
            <Trans message="boletoGenerated" />
          </TransactionTitle>

          <TotalInfoWrapper $mt="12px">
            <ContainerDetailsWrapper>
              <img style={{ marginBottom: "6px" }} src={image} />
              {bank_code && (
                <BlockDetailsWrapper
                  onClick={() => handleCopy(bank_code, "Code copied")}
                  $mb="0"
                >
                  <BolettoDetailsSubTitle>{bank_code}</BolettoDetailsSubTitle>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    <IconColorWrapper>
                      <IconCopy />
                    </IconColorWrapper>
                  </span>
                </BlockDetailsWrapper>
              )}
            </ContainerDetailsWrapper>
          </TotalInfoWrapper>
        </InnerDataBoletto>

        <ChallengeButtonWrapper pdf_url={pdf_url} width="376px">
          <BaseButton
            width={"100%"}
            marginTop="0"
            textColor="#fff"
            marginBottom="12px"
            onClick={() => handleCopy(bank_code, "Code copied")}
          >
            <Trans message="Copy" />
          </BaseButton>
          {pdf_url && (
            <BaseButton
              marginTop="0"
              marginBottom="12px"
              textColor="#fff"
              onClick={() => handleDownloadPdf()}
            >
              <Trans message="savePdf" />
            </BaseButton>
          )}
        </ChallengeButtonWrapper>

        {cancelUrl && (
          <ChallengeButtonWrapper width="376px">
            <BaseButton
              data-role="back_to_store_button"
              marginBottom="22px"
              onClick={() => onGoBackButtonClick(cancelUrl)}
            >
              <Trans message="goBack" />
            </BaseButton>
          </ChallengeButtonWrapper>
        )}
        <PaymentInfoWrapper>
          <DetailsTransactionSubTitle>
            <Trans message="boletoDetailsTitle" />
          </DetailsTransactionSubTitle>
        </PaymentInfoWrapper>
      </OuterBlockHeader>
    </div>
  );
};

export default ChallengeBrBoleto;
