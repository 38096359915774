const InactiveRememberMeIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 3.48524H5C4.72386 3.48524 4.5 3.72205 4.5 4.01417V13.9623L6.36757 12.9206C7.3892 12.3507 8.6108 12.3507 9.63243 12.9206L11.5 13.9623V4.01417C11.5 3.72205 11.2761 3.48524 11 3.48524ZM5 1.89844C3.89543 1.89844 3 2.84568 3 4.01417V14.8391C3 15.6355 3.80047 16.1463 4.46641 15.7749L7.06718 14.3242C7.65097 13.9986 8.34903 13.9986 8.93282 14.3242L11.5336 15.7749C12.1995 16.1463 13 15.6355 13 14.8391V4.01417C13 2.84568 12.1046 1.89844 11 1.89844H5Z"
      fill="#B3C4C9"
    />
  </svg>
);

export default InactiveRememberMeIcon;
