import CryptoInput from "./CryptoInput";
import { CryptoInputWrapper } from "./style";

const CryptoForm = ({
  pay_currency,
  currency,
  userInputData,
  formatNumberWithSpaces,
  handleInputChange,
  options,
  isAmountBelowMin,
  isLoading,
}) => {
  const {
    copySendAmountEnabled = true,
    copyReceiveAmountEnabled = false,
    sendLabel,
    receiveLabel,
  } = options || {};

  return (
    <>
      <CryptoInputWrapper>
        <CryptoInput
          label={sendLabel || "send"}
          isAmountBelowMin={isAmountBelowMin}
          name="pay_amount"
          currency={pay_currency}
          formatNumberWithSpaces={formatNumberWithSpaces}
          value={userInputData?.pay_amount}
          copyValue={copySendAmountEnabled && userInputData?.pay_amount}
          onChange={handleInputChange}
          disabled={true}
          isLoading={isLoading.pay_amount}
        />
        <CryptoInput
          label={receiveLabel || "receive"}
          name="currency_amount"
          currency={currency}
          autofocus={"autofocus"}
          formatNumberWithSpaces={formatNumberWithSpaces}
          value={userInputData?.currency_amount}
          copyValue={copyReceiveAmountEnabled && userInputData?.currency_amount}
          onChange={handleInputChange}
          disabled={!handleInputChange}
          isLoading={isLoading.currency_amount}
        />
      </CryptoInputWrapper>
    </>
  );
};

export default CryptoForm;
