import {
  BlockDetailsWrapper,
  DetailsTransactionSubTitle,
  CopyWrapper,
  CopyActionWrapper,
  IconColorWrapper,
  DetailsTransactionTitleBankTransferArgentina,
} from "../../../styles";
import Trans from "../../Trans";
import Tooltip from "../../Tooltip/Tooltip";
import IconCopy from "../../../assets/icons/iconCopy";
import IconCopyKombine from "../../../assets/icons/iconCopyCombine";
import { truncateText } from "../../../services/utils";

const DetailsList = ({ details, handleCopy, transactionIdRef, theme }) => (
  <>
    {details.map(({ title, value, copyTitle, bold }) => (
      <BlockDetailsWrapper width="100%" key={title}>
        <DetailsTransactionTitleBankTransferArgentina>
          <Trans message={title} />
        </DetailsTransactionTitleBankTransferArgentina>
        <CopyWrapper>
          <DetailsTransactionSubTitle
            bold={bold}
            mr="8px"
            ref={transactionIdRef}
          >
            {truncateText(value)}
          </DetailsTransactionSubTitle>
          <CopyActionWrapper onClick={() => handleCopy(value, copyTitle)}>
            <IconColorWrapper>
              <Tooltip text={"Сopy"}>
                {theme === "default" ? <IconCopy /> : <IconCopyKombine />}
              </Tooltip>
            </IconColorWrapper>
          </CopyActionWrapper>
        </CopyWrapper>
      </BlockDetailsWrapper>
    ))}
  </>
);

export default DetailsList;
