/* eslint-disable no-use-before-define */
import { useContext, useEffect, useState } from "preact/hooks";
import { CloseIcon } from "../FormPopup";
import { PopupMainWrapper, PopupTitle } from "../../styles";
import Trans from "../Trans";
import Search from "./Search";
import List from "./List";
import { api } from "../../services";
import CustomerInput from "../CustomerInput";
import { ContextUI } from "../../providers/UIProvider";

const Dropdown = ({
  nameKey,
  label,
  autocomplete,
  pattern,
  nonRequired,
  autofocus,
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  placeholder,
  inputOptions = {},
  options = [],
  staticDropdown,
}) => {
  const { modalData, setModalData } = useContext(ContextUI);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [localSelected, setLocalSelected] = useState({});
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    if (modalData?.isOpen && !localOptions.length) {
      setIsLoading(true);

      if (options.length) {
        setLocalOptions(options);
        setIsLoading(false);
      } else {
        api
          .getDropdownList({ uri: inputOptions.from || "" })
          .then((res) => setLocalOptions(res.data || res))
          .catch(() => setLocalOptions([]))
          .finally(() => setIsLoading(false));
      }
    }
  }, [modalData?.isOpen]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleChange = (option) => {
    setLocalSelected(option);

    if (inputOptions?.onChange && typeof inputOptions.onChange === "function") {
      inputOptions.onChange({ option, setValues });
    } else if (staticDropdown) {
      setValues((prev) => ({
        ...prev,
        [nameKey]: option[inputOptions.key] || option,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        [nameKey]: option[inputOptions.key || "id"],
      }));
    }

    handleClose();
  };

  useEffect(() => {
    setModalData((prev) => ({
      ...prev,
      modalName: "dropdown",
      isLoading,
    }));
  }, [isLoading]);

  useEffect(() => {
    if (modalData.modalName === "dropdown") {
      setModalData((prev) => ({
        ...prev,
        modalName: "dropdown",
        content: (
          <List
            showBankIcon={inputOptions?.showBankIcon}
            foreignKey={inputOptions.key}
            options={localOptions?.filter((option) =>
              inputOptions.search && search
                ? inputOptions.search({ option, search })
                : true,
            )}
            selected={localSelected}
            onChange={handleChange}
            display={inputOptions?.display}
            inputOptions={inputOptions}
          />
        ),
      }));
    }
  }, [localOptions, search]);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      modalName: "dropdown",
      content: (
        <List
          showBankIcon={inputOptions?.showBankIcon}
          foreignKey={inputOptions.key}
          options={localOptions?.filter((option) =>
            inputOptions.search && search
              ? inputOptions.search({ option, search })
              : true,
          )}
          selected={localSelected}
          onChange={handleChange}
          display={inputOptions?.display}
          inputOptions={inputOptions}
        />
      ),
      header: customHeader,
      isFullView: true,
      isLoading,
    });

    setErrors((prev) => {
      delete prev[nameKey];

      return prev;
    });
  };

  const handleClose = () => {
    setModalData({
      isOpen: null,
      content: null,
      header: null,
      title: "",
      modalName: "",
      subTitle: "",
      isFullView: false,
      isLoading: false,
    });
  };

  const customHeader = () => {
    return (
      <>
        <PopupMainWrapper hasSubtitle={false}>
          <PopupTitle>
            <Trans message={label} />
          </PopupTitle>
          <CloseIcon onClick={handleClose} />
        </PopupMainWrapper>
        <Search onSearch={handleSearch} />
      </>
    );
  };

  return (
    <CustomerInput
      nameKey={nameKey}
      placeholder={placeholder || "pleaseSelectOption"}
      label={label}
      autocomplete={autocomplete}
      autofocus={autofocus}
      pattern={pattern}
      nonRequired={nonRequired}
      values={values}
      setValues={setValues}
      errors={errors}
      setErrors={setErrors}
      inputRef={inputRef}
      onClick={handleOpen}
      inputDisplay={inputOptions?.inputDisplay}
      isDropdown
    />
  );
};

export default Dropdown;
