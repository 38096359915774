import { useEffect, useRef } from "preact/hooks";
import { logger } from "../services";

const useApmTransaction = (transactionName) => {
  const transactionRef = useRef(null);

  const endAndClearCurrentTransaction = () => {
    if (transactionRef.current) {
      transactionRef.current.end();
      logger.clearActiveTransaction(transactionName);
      transactionRef.current = null;
    }
  };

  useEffect(() => {
    let transaction = logger.getActiveTransaction(transactionName);

    if (!transaction) {
      transaction = logger.startTransaction(transactionName, "custom");
      logger.setActiveTransaction(transactionName, transaction);
    }

    transactionRef.current = transaction;

    window.addEventListener("beforeunload", endAndClearCurrentTransaction);

    return () => {
      endAndClearCurrentTransaction();

      window.removeEventListener("beforeunload", endAndClearCurrentTransaction);
    };
  }, [transactionName]);

  const createSpan = (spanName, spanType = "custom") => {
    if (transactionRef.current) {
      return transactionRef.current.startSpan(spanName, spanType);
    }

    return null;
  };

  return {
    createSpan,
    endTransaction: endAndClearCurrentTransaction,
  };
};

export default useApmTransaction;
