import { useEffect } from "preact/hooks";

const useInitApp = () => {
  useEffect(() => {
    const setVisibleHeight = () => {
      const vh = window?.innerHeight * 0.01;
      document?.documentElement?.style?.setProperty("--vh", `${vh}px`);
    };

    setVisibleHeight();

    window.addEventListener("resize", setVisibleHeight);

    return () => {
      window.removeEventListener("resize", setVisibleHeight);
    };
  }, []);
};

export default useInitApp;
