import { UssdCurrencyAmountWrapper, InputCurrencyTitle } from "../style";

const AmountDisplay = ({ selectedAmount, getFormattedAmountParts }) => {
  const formattedSelectedAmount = getFormattedAmountParts(selectedAmount);

  return (
    <UssdCurrencyAmountWrapper>
      <b>
        {formattedSelectedAmount.wholeNumber}
        {formattedSelectedAmount.decimal && (
          <>
            <span>.</span>
            {formattedSelectedAmount.decimal}
          </>
        )}
      </b>
      <InputCurrencyTitle>JPY</InputCurrencyTitle>
    </UssdCurrencyAmountWrapper>
  );
};

export default AmountDisplay;
