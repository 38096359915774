import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const chasingBallBounceAnimation = keyframes`
  50% {
    transform: scale(0);
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotateAnimation} 2s linear infinite;
`;

const Ball = styled.div`
  position: absolute;
  top: 0;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background: ${(props) => props.theme.mainColor};
  animation: ${chasingBallBounceAnimation} 2s linear infinite;
`;

const SecondBall = styled(Ball)`
  top: auto;
  bottom: 0;
  animation-delay: -1s;
`;

const BallChasingLoader = () => {
  return (
    <LoaderContainer>
      <Loader>
        <Ball />
        <SecondBall />
      </Loader>
    </LoaderContainer>
  );
};

export default BallChasingLoader;
