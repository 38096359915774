import styled from "styled-components";

export const ChallengeInfoMainTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-family: var(--boldFont);
    color: #101820;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.3px;
    letter-spacing: 0.01px;

    &:last-child {
      font-weight: 400;
    }
  }
`;
