const IconCopy = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_983_714)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 4.73438H8C9.38071 4.73438 10.5 5.85366 10.5 7.23438V13.2344C10.5 14.6151 9.38071 15.7344 8 15.7344H4C2.61929 15.7344 1.5 14.6151 1.5 13.2344V7.23438C1.5 5.85366 2.61929 4.73438 4 4.73438ZM4 6.23438C3.44772 6.23438 3 6.68209 3 7.23438V13.2344C3 13.7867 3.44772 14.2344 4 14.2344H8C8.55228 14.2344 9 13.7867 9 13.2344V7.23438C9 6.68209 8.55228 6.23438 8 6.23438H4Z"
        fill=""
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0.734375H8C6.61929 0.734375 5.5 1.85366 5.5 3.23438H7C7 2.68209 7.44772 2.23438 8 2.23438H12C12.5523 2.23438 13 2.68209 13 3.23438V9.23438C13 9.78666 12.5523 10.2344 12 10.2344V11.7344C13.3807 11.7344 14.5 10.6151 14.5 9.23438V3.23438C14.5 1.85366 13.3807 0.734375 12 0.734375Z"
        fill=""
      />
    </g>
    <defs>
      <clipPath id="clip0_983_714">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.234375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconCopy;
