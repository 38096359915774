import { IconColorWrapper } from "../../styles";
import {
  CryptoCurrencyTitle,
  CryptoValuesTitle,
  CryptoFormContainer,
  CryptoQrWrapper,
} from "./style";
import Trans from "../Trans";
import IconCopy from "../../assets/icons/iconCopy";

const Qr = ({ address, address_qr_code_image, address_tag, handleCopy }) => {
  const renderCopySection = (title, value) => (
    <>
      <CryptoCurrencyTitle>
        <Trans message={title} />
      </CryptoCurrencyTitle>
      <CryptoValuesTitle style={{ display: "flex" }}>
        {value}{" "}
        <span
          onClick={() => handleCopy(value, `${title} copied`)}
          style={{
            cursor: "pointer",
            color: "blue",
          }}
        >
          <IconColorWrapper style={{ marginLeft: "4px" }}>
            <IconCopy />
          </IconColorWrapper>
        </span>
      </CryptoValuesTitle>
    </>
  );

  return (
    <>
      <CryptoFormContainer $mr="auto" $pd="0" $mb>
        {address && renderCopySection("Wallet address", address)}
      </CryptoFormContainer>

      <CryptoFormContainer $mr="auto" $pd="0">
        {address_tag && renderCopySection("TAG", address_tag)}
      </CryptoFormContainer>

      <CryptoQrWrapper>
        <img src={`data:image/png+xml; base64,${address_qr_code_image}`} />
      </CryptoQrWrapper>
    </>
  );
};

export default Qr;
