import defaultCard from "../../assets/icons/anotherCardPb.svg";
import { IconCardImg } from "../../styles";
import { CARD_BRANDS } from "../../constants/cardBrand";

const IconCardDefault = ({ value, values }) => {
  return (
    <>
      {value && (
        <IconCardImg
          src={CARD_BRANDS[values.brand] || defaultCard}
          alt="brand"
        />
      )}
    </>
  );
};

export default IconCardDefault;
