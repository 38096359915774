import { SavedCardMobileWrapper } from "../../styles";
import Trans from "../Trans";
import Tooltip from "../Tooltip/Tooltip";

const InputSavedCardKombine = ({ openModal }) => {
  return (
    <>
      <SavedCardMobileWrapper width="116px" onClick={() => openModal()}>
        <Tooltip
          background="#10366B"
          width="220px"
          textAlign="start"
          fs="12px"
          bottom="-520%"
          top="-12%"
          left="-25%"
          leftArrow="80%"
          rotate="180deg"
          text="We only store the card number and expiration date for bulk orders on our website"
        >
          <Trans message="savedCard" />
        </Tooltip>
      </SavedCardMobileWrapper>
    </>
  );
};

export default InputSavedCardKombine;
