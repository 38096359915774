import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const PaymentDetailsWrapper = styled.div<{
  $padding?: string;
  $noShadow?: boolean;
  $gap?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $padding }) => $padding || "12px"};
  margin-top: auto;
  background: ${({ theme }) => theme?.infoBlockBackground};
  box-shadow: ${({ theme, $noShadow }) => ($noShadow ? "" : theme?.boxShadow)};
  border-radius: 15px;
  margin: 0 16px 24px;
  z-index: 30;
  position: relative;
  gap: ${({ $gap }) => $gap || ""};

  @media (min-width: 768px) {
    margin: 0 16px 24px;
    margin: ${({ theme }) => `0 ${theme?.infoBlockMargin} 24px`};
  }

  @media (max-height: 768px) {
    margin-bottom: 8px;
    padding: 8px 12px;
  }
`;

export const PaymentDetailsContainer = styled.div<{
  $mt?: string;
}>`
  margin-top: ${({ $mt }) => $mt || ""};
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
`;

export const InnerLine = styled.div<{
  $mt?: string;
}>`
  width: 100%;
  margin-top: ${({ $mt }) => $mt || ""};
  height: 1px;
  background: ${({ theme }) => theme?.innerLineBackground};
`;

export const PaymentDetailsHeadWrapper = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;

export const PaymentDetailsHeadTitle = styled.span<{
  fs?: string;
  mr?: string;
  mb?: string;
  color?: string;
}>`
  font-size: ${({ fs }) => fs || "18px"};
  font-family: var(--boldFont);
  margin-right: ${({ mr }) => mr || ""};
  margin-bottom: ${({ mb }) => mb || ""};
  color: ${({ color }) => color || "#101820"};
  & span {
    font-family: var(--boldFont);
    font-weight: 600;
  }
`;

export const PaymentDetailsButton = styled.div<{
  $isOpen?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background: ${COLORS.GREY_WHITE};
  transform: ${({ $isOpen }) => `rotate(${$isOpen ? "180deg" : "0deg"})`};
  cursor: pointer;
`;

export const PaymentDetailsRowWrapper = styled.div<{
  $alignItems?: string;
  width?: string;
}>`
  display: flex;
  align-items: ${({ $alignItems }) => $alignItems || ""};
  justify-content: space-between;
  width: ${({ width }) => width || ""};
`;

export const PaymentDetailsLabel = styled.span<{
  $controlledLength?: boolean;
  $width?: string;
  $bold?: boolean;
}>`
  font-size: 14px;
  font-weight: 400;
  text-align: ${(props) => (props?.$controlledLength ? "left" : "right")};
  width: ${(props) => {
    if (props?.$controlledLength) {
      return "147px";
    }

    if (props.$width) {
      return props.$width;
    }

    return "";
  }};
  word-wrap: break-word;
  font-family: ${(props) =>
    props.$bold ? "var(--boldFont)" : "var(--mediumFont)"};

  ${(props) => {
    if (props.$controlledLength) {
      return `
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
         flex-shrink: 0;
       `;
    }

    return "";
  }}

  @media (min-width: 320px) and (max-width: 340px) {
    width: 120px;
  }
`;

export const PaymentDetailsValueWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  flex-wrap: nowrap;
  gap: 4px;

  & > div {
    align-items: start;
  }
`;

export const TransactionSumWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TransactionSumContainer = styled.div<{
  $hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ $hasError, theme }) =>
    $hasError ? theme?.notificationColorError : null};
`;

export const TransactionSumTitle = styled.span<{
  fs?: string;
  color?: string;
}>`
  font-style: normal;
  font-size: ${({ fs }) => fs || "24px"};
  line-height: 24px;
  font-family: var(--boldFont);
  color: ${({ color }) => color};
  white-space: nowrap;

  span {
    font-family: var(--boldFont);
  }
`;

export const TransactionDecimalTitle = styled.span<{
  fs?: string;
  color?: string;
}>`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fs }) => fs || "24px"};
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const TransactionCurrencyTitle = styled.span<{
  fs?: string;
  color?: string;
  $ml?: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fs }) => fs || "24px"};
  line-height: 27px;
  text-align: right;
  font-family: var(--regularFont);
  margin-left: ${(props) => props.$ml || ""};
  color: ${({ color }) => color || "#5a747a"};
`;

export const ChannelTitleWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  max-width: 70%;

  span {
    font-family: var(--regularFont);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;
