import { useEffect, useRef, useState } from "preact/hooks";
import {
  ScrollBar,
  ScrollContainer,
  ScrollContent,
  ScrollThumb,
} from "./style";

let timer = null;

const Scroll = ({ children, style }) => {
  const ref = useRef();
  const thumbRef = useRef();
  const [thumbHeight, setThumbHeight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isHide, setIsHide] = useState(true);

  const updateThumb = () => {
    const { clientHeight, scrollHeight } = ref.current;
    const height = (clientHeight / scrollHeight) * clientHeight;

    setThumbHeight(height);
  };

  const handleScroll = () => {
    const { clientHeight, scrollHeight } = ref.current;

    const scrollTop = ref.current.scrollTop;
    const maxThumbTop =
      clientHeight - (clientHeight / scrollHeight) * clientHeight;
    thumbRef.current.style.top = `${
      (scrollTop / (scrollHeight - clientHeight)) * maxThumbTop
    }px`;

    setIsHide(clientHeight >= scrollHeight);
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      setIsHide(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [thumbRef.current?.style?.top]);

  useEffect(() => {
    ref.current.addEventListener("scroll", handleScroll);

    ref.current.addEventListener("resize", updateThumb);
    updateThumb();

    return () => {
      ref.current.removeEventListener("scroll", handleScroll);
      ref.current.removeEventListener("resize", updateThumb);
    };
  }, [ref, children]);

  const handleThumbMouseDown = (event) => {
    setIsDragging(true);
    const startY = event.clientY;
    const startTop = parseFloat(thumbRef.current.style.top) || 0;

    const onMouseMove = (moveEvent) => {
      const deltaY = moveEvent.clientY - startY;
      const newTop = Math.min(
        Math.max(startTop + deltaY, 0),
        ref.current.clientHeight - thumbHeight,
      );
      const scrollPosition =
        (newTop / (ref.current.clientHeight - thumbHeight)) *
        (ref.current.scrollHeight - ref.current.clientHeight);

      ref.current.scrollTop = scrollPosition;
    };

    const onMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <ScrollContainer>
      <ScrollContent style={style} ref={ref}>
        {children}
      </ScrollContent>
      <ScrollBar
        $isHide={isHide}
        $isNotExist={ref.current?.clientHeight >= ref.current?.scrollHeight}
      >
        <ScrollThumb
          ref={thumbRef}
          style={{ height: thumbHeight }}
          $isDragging={isDragging}
          onMouseDown={handleThumbMouseDown}
        />
      </ScrollBar>
    </ScrollContainer>
  );
};

export default Scroll;
