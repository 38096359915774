import styled from "styled-components";
import { ENUM_COLORS } from "../../constants/themeConstants";

interface IPropsButtonStyled {
  $width?: string;
  $mb?: string;
  $textTransform?: string;
}

export const ButtonStyled = styled.button<IPropsButtonStyled>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: ${({ $width }) => $width || "100%"};
  max-width: 376px;
  height: 40px;
  border-radius: ${(props) => props.theme.elementBorderRadius || ""};
  background: ${(props) => props.theme.buttonColor || ""};
  border: ${(props) =>
    props.theme.buttonColor ? `1px solid ${props.theme.buttonColor}` : "none"};
  color: ${(props) => props.theme.buttonTextColor || ""};
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0 auto;
  margin-bottom: ${({ $mb }) => $mb || "0px"};
  font-family: var(--regularFont);
  transition: ease 0.3s;
  cursor: pointer;
  text-transform: ${({ $textTransform }) => $textTransform || "uppercase"};

  &:hover {
    background-color: ${ENUM_COLORS.DEFAULT_BLACK};
    color: ${ENUM_COLORS.DEFAULT_WHITE};
  }
`;
