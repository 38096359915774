const ActiveRememberMeIcon = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0C0.895431 0 0 0.947245 0 2.11573V12.9407C0 13.7371 0.800471 14.2479 1.46641 13.8764L4.06718 12.4258C4.65097 12.1001 5.34903 12.1002 5.93282 12.4258L8.53359 13.8764C9.19953 14.2479 10 13.7371 10 12.9407V2.11573C10 0.947245 9.10457 0 8 0H2Z"
      fill="#0062EB"
    />
  </svg>
);

export default ActiveRememberMeIcon;
