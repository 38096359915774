import * as Preact from "preact";
import { useContext } from "preact/hooks";
import { state } from "../../services";
import CheckboxUI from "../ui/CheckboxUI";
import { CheckboxContent, PrivacyPolicyCheckboxWrapper } from "./style";
import { Context } from "../../app";
import Trans from "../Trans";

interface IPrivacyPolicyCheckbox {
  data: {
    privacy_policy_link?: string;
    terms_of_service_link?: string;
    policy_by?: string;
  };
}
interface IPrivacyPolicyCheckbox {
  data: {
    privacy_policy_link?: string;
    terms_of_service_link?: string;
    policy_by?: string;
  };
}

interface ICurrentState {
  isPrivacyPolicyAgreed?: boolean;
}

const PrivacyPolicyCheckbox = ({ data }: IPrivacyPolicyCheckbox) => {
  const { privacy_policy_link, terms_of_service_link, policy_by } = data || {};

  const { currentState = {} as ICurrentState } = useContext(Context);

  const { isPrivacyPolicyAgreed } = currentState || {};

  const handleChange = (e: Preact.JSX.TargetedEvent<HTMLInputElement>) => {
    state.set({
      isPrivacyPolicyAgreed: !!e?.currentTarget?.checked,
    });
  };

  return (
    <PrivacyPolicyCheckboxWrapper>
      <CheckboxUI
        name="privacy_policy"
        checked={!!isPrivacyPolicyAgreed}
        onChange={handleChange}
      >
        <CheckboxContent>
          <Trans message="iAgree" withoutWrapper />{" "}
          {privacy_policy_link ? (
            <a href={terms_of_service_link} target="_blank">
              <Trans message="termsOfService" withoutWrapper />
            </a>
          ) : null}
          {privacy_policy_link && privacy_policy_link ? (
            <>
              {" "}
              <Trans message="and" withoutWrapper />{" "}
            </>
          ) : null}
          {privacy_policy_link ? (
            <a href={privacy_policy_link} target="_blank">
              <Trans message="privacyPolicy" withoutWrapper />
            </a>
          ) : null}
          {policy_by ? (
            <>
              {" "}
              <Trans message="of" withoutWrapper /> {policy_by}
            </>
          ) : null}
        </CheckboxContent>
      </CheckboxUI>
    </PrivacyPolicyCheckboxWrapper>
  );
};

export default PrivacyPolicyCheckbox;
