import ChallengeBlikCode from "../components/Challenge/ChallengeBlikCode";
import ChallengeP2C from "../components/Challenge/ChallengeP2C";
import BankTransferJapan from "../components/Challenge/ChallengeBankTransferJapan/BankTransferJapan";
import PayCode from "../components/Challenge/PayCode/PayCode";
import ChallengeAccountRequisites from "../components/Challenge/ChallangeViews/ChallengeAccountRequisites";
import ChallengeBankCode from "../components/Challenge/ChallengeBankCode/ChallengeBankCode";

export const CHALLENGE_TYPES = [
  "pin",
  "card_pin",
  "address_verification",
  "phone_verification",
  "otp",
  "stk",
  "bank_transfer_africa",
  "select_amount",
  "bank_transfer_argentina",
  "wallet_opay",
  "wallet_opay_qr",
  "ussd",
  "blik_code",
  "p2c_phone",
  "p2c",
  "bank_code",
  "interac_etransfer",
  "pay_code",
  "account_requisites",
];

export const RENDER_CHALLENGE_TYPES = {
  QR: "qr",
  BR: "br",
  BANK_TRANSFER_AFRICA: "bank_transfer_africa",
  BANK_TRANSFER_ARGENTINA: "bank_transfer_argentina",
  WALLET_OPAY: "wallet_opay",
  WALLET_OPAY_QR: "wallet_opay_qr",
  STK: "stk",
  USSD: "ussd",
  BLIK_CODE: "blik_code",
  P2C_PHONE: "p2c_phone",
  BANK_CODE: "bank_code",
  INTERAC_ETRANSFER: "interac_etransfer",
  PAY_CODE: "pay_code",
};

export const CHALLENGE_TYPES_CONFIG = {
  qr: {
    hasView: true,
  },
  br: {
    hasView: true,
  },
  pin: {},
  card_pin: {},
  address_verification: {},
  phone_verification: {},
  otp: {},
  pay_code: {
    hasView: true,
    hasHeader: true,
    body: ({ challenge, handleCopy, selectedMethod, cancelUrl }) => (
      <PayCode
        challenge={challenge}
        handleCopy={handleCopy}
        selectedMethod={selectedMethod}
        cancelUrl={cancelUrl}
      />
    ),
  },
  stk: {
    hasView: true,
  },
  bank_transfer_africa: {
    hasView: true,
  },
  interac_etransfer: {
    hasView: true,
  },
  bank_transfer_argentina: {
    hasView: true,
  },
  wallet_opay: {
    hasView: true,
  },
  wallet_opay_qr: {
    hasView: true,
  },
  ussd: {
    hasView: true,
  },
  select_amount: {
    hasHeader: true,
    hasView: true,
    body: ({ challenge, sessionId, signature, amount }) => (
      <BankTransferJapan
        challenge={challenge}
        sessionId={sessionId}
        signature={signature}
        amount={amount}
      />
    ),
  },
  blik_code: {
    hasView: true,
    hasHeader: true,
    body: ({ status, challenge, sessionId, signature, currentMethod }) => (
      <ChallengeBlikCode
        status={status}
        challenge={challenge}
        sessionId={sessionId}
        signature={signature}
        currentMethod={currentMethod}
      />
    ),
  },
  bank_code: {
    hasView: true,
    hasHeader: true,
    body: ({ status, challenge, sessionId, signature, currentMethod }) => (
      <ChallengeBankCode
        status={status}
        challenge={challenge}
        sessionId={sessionId}
        signature={signature}
        currentMethod={currentMethod}
      />
    ),
  },
  p2c_phone: {
    hasView: true,
  },
  p2c: {
    hasView: true,
    hasHeader: true,
    body: ({ p2c, handleCopy }) => {
      return <ChallengeP2C p2c={p2c} handleCopy={handleCopy} />;
    },
  },
  account_requisites: {
    hasView: true,
    hasHeader: true,
    source: "account_requisites",
    body: ({ data, status, handleCopy }) => {
      return (
        <ChallengeAccountRequisites
          status={status}
          data={data}
          handleCopy={handleCopy}
        />
      );
    },
  },
};
