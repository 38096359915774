/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, useContext } from "preact/hooks";
import { withTransaction } from "@elastic/apm-rum-react";
import { state, logger, localStorageService } from "../../services";
import successPageControl from "../../components/SuccessPage/SuccessPageControl";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";

const Success = () => {
  const context = useContext(Context);
  const { style, currentState, onGoBackButtonClick, matchesData } = context;
  const { sessionId } = matchesData?.matches || {};
  const { options } = currentState || {};
  const { status_page, redirect_url } = options || {};
  const { redirect_after_sec, enabled } = status_page || {};
  const [countdown, setCountdown] = useState(redirect_after_sec);
  const sessionReferrer = localStorageService.getSessionReferrer();
  const currentSessionReferrer =
    sessionReferrer.sessionId === sessionId ? sessionReferrer.referrer : null;

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url);
  };

  useEffect(() => {
    const targetUrl = redirect_url || currentSessionReferrer;

    if (!targetUrl) {
      return;
    }

    if (countdown === 0 || !enabled) {
      state.changeLocation(targetUrl, true);
    }

    let timer;

    if (countdown) {
      timer = setInterval(
        () => setCountdown(countdown > 0 ? countdown - 1 : countdown),
        1000,
      );
    }

    return () => clearInterval(timer);
  }, [
    countdown,
    redirect_url,
    redirect_after_sec,
    enabled,
    currentSessionReferrer,
  ]);

  if (!style || !enabled) {
    return;
  }

  const ControlSuccess =
    successPageControl[options?.theme] || successPageControl.default;

  useEffect(() => {
    if (!ControlSuccess) {
      logger.error("Page not found");
    }
  }, []);

  return (
    <Scroll>
      <ControlSuccess countdown={countdown} onButtonClick={onButtonClick} />
    </Scroll>
  );
};

export default withTransaction("SuccessPage", "component")(Success);
