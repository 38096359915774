import { useEffect, useState, useContext } from "preact/hooks";
import { state, processing } from "../../services";
import cardListControl from "../CardList/CardListControl";
import manageCardControl from "../CardList/ManageCardControl";
import {
  EditCardActionWrapper,
  ManageCardActionTitle,
  ManageCardBlockTitle,
} from "../../styles";
import Trans from "../Trans";
// import anotherCard from "../../assets/icons/anotherCardPb.svg";
import closeIcon from "../../assets/icons/closeIcon.svg";
// import ModalItem from "./ModalComponents/ModalItem";
import { ContextUI } from "../../providers/UIProvider";

const CardListModal = ({
  handleCheckSavedCard,
  handleRemoveSavedCard,
  checkedCard,
}) => {
  const cardList = state.get().cards || [];
  const options = state.get().options;

  const [isManage, setIsManage] = useState(false);
  const [isPrevRemoveCard, setIsPrevRemoveCard] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);

  const { modalData, setModalData, handleCloseModal, setNotificationData } =
    useContext(ContextUI);

  const { isOpen } = modalData;

  const ControlCardList =
    cardListControl[options?.theme] || cardListControl.default;

  const ControlManageCard =
    manageCardControl[options?.theme] || manageCardControl.default;

  const handleRemoveCardPopup = (id) => {
    setIsManage(false);
    setCurrentCard(null);
    processing
      .processRemoveCard(id)
      .then(() => handleRemoveSavedCard(id))
      .then(() => {
        setNotificationData({
          text: "deletedCard",
          status: "success",
          isOpen: true,
        });
      })
      .catch((e) => {
        setNotificationData({
          text: e?.response?.reason || e.toString(),
          status: "fail",
          isOpen: true,
        });
      });
  };

  const handleCancel = () => {
    setIsManage(false);
    setCurrentCard(null);
  };

  const customHeader = () => (
    <>
      <EditCardActionWrapper>
        {isPrevRemoveCard ? (
          <ManageCardActionTitle>
            <Trans message="youWantDltCard" />
          </ManageCardActionTitle>
        ) : (
          <>
            <ManageCardActionTitle>
              <Trans message={isManage ? "manageSavedCard" : "savedCard"} />
            </ManageCardActionTitle>

            <ManageCardBlockTitle
              data-role="manager_button"
              onClick={() => setIsManage(!isManage)}
            >
              <Trans message={isManage ? "done" : "manage"} />
            </ManageCardBlockTitle>

            <img onClick={() => handleCloseModal()} src={`${closeIcon}`} />
          </>
        )}
      </EditCardActionWrapper>
    </>
  );

  useEffect(() => {
    if (isOpen) {
      setModalData((prev) => ({
        ...prev,
        header: customHeader,
        isFullView: cardList?.length > 4 && !isPrevRemoveCard,
      }));
    }
  }, [isPrevRemoveCard, isOpen]);

  useEffect(() => {
    setIsManage(false);
    setIsPrevRemoveCard(false);
  }, [isOpen]);

  useEffect(() => {
    if (!isManage) {
      setIsPrevRemoveCard(false);
    }
  }, [isManage]);

  // const removeSavedCard = () => {
  //   isOpen && handleRemoveSavedCard(checkedCard.identifier);
  //   handleCloseModal();
  // };

  return (
    <>
      {
        isPrevRemoveCard &&
          currentCard &&
          [currentCard].map((card) => {
            return (
              <ControlManageCard
                key={card.last4}
                checkedCard={checkedCard}
                card={card}
                setIsManage={setIsManage}
                handleRemoveCard={handleRemoveCardPopup}
                handleCancel={handleCancel}
              />
            );
          })

        // use another card logic ???
        // (
        //   <>
        //     {checkedCard && (
        //       <ModalItem
        //         handleClick={removeSavedCard}
        //         title="cardAnother"
        //         icon={anotherCard}
        //         iconWidth="32px"
        //       />
        //     )}
        //   </>
        // )
      }

      {cardList &&
        !currentCard &&
        Array.isArray(cardList) &&
        cardList.map((card, index) => {
          if (!card.enabled) return null;

          return (
            <ControlCardList
              key={card.last4}
              index={index}
              checkedCard={checkedCard}
              handleCheckSavedCard={handleCheckSavedCard}
              setCurrentCard={setCurrentCard}
              card={card}
              isManage={isManage}
              setIsPrevRemoveCard={setIsPrevRemoveCard}
            />
          );
        })}
    </>
  );
};

export default CardListModal;
