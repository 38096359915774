import { memo } from "preact/compat";
import googleIcon from "../assets/icons/iconGoogle.svg";
import { GoogleApplePayTestButton } from "../styles";

const GooglePayTestBtn = memo(() => {
  return (
    <GoogleApplePayTestButton>
      <img src={googleIcon} alt="apple_icon" />
      <span>Pay</span>
    </GoogleApplePayTestButton>
  );
});

export default GooglePayTestBtn;
