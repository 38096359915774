import {
  ChallengeUssdInfoWrapper,
  ChallengeNumberTitle,
  StepListSubtitle,
} from "../style";
import Trans from "../../Trans";
import { InnerLine } from "../../../styles";

const StepsList = ({ steps, showLine }) => (
  <>
    {steps.map((step) => (
      <ChallengeUssdInfoWrapper mb="12px" key={step.number}>
        <ChallengeNumberTitle>{step.number}</ChallengeNumberTitle>
        <Trans message={step.title} />{" "}
        {step.subTitle && <StepListSubtitle>{step.subTitle}</StepListSubtitle>}
      </ChallengeUssdInfoWrapper>
    ))}
    {showLine && <InnerLine />}
  </>
);

export default StepsList;
