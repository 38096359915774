import ChallengeHeaderUssd from "./ChallengeHeaderUSSD";
import ChallengeUssdDetails from "./ChallengeUssdDetails";

const ChallengeUssd = ({
  isLogo,
  currentState,
  reference,
  wholeNumber,
  currency,
  transactionIdRef,
  handleCopy,
  cancelUrl,
  canReturnToMethods,
  mobileMoneyPhone,
  selectedMethod,
  currentLocale,
  challenge,
  amount,
  selectedMethodChannel,
}) => {
  return (
    <>
      <ChallengeHeaderUssd
        challenge={challenge}
        isLogo={isLogo}
        currentState={currentState}
        reference={reference}
        wholeNumber={wholeNumber}
        transactionIdRef={transactionIdRef}
        handleCopy={handleCopy}
        cancelUrl={cancelUrl}
        canReturnToMethods={canReturnToMethods}
        selectedMethod={selectedMethod}
        currentLocale={currentLocale}
        mobileMoneyPhone={mobileMoneyPhone}
        selectedMethodChannel={selectedMethodChannel}
      />
      <ChallengeUssdDetails
        challenge={challenge}
        currency={currency}
        amount={amount}
        transactionIdRef={transactionIdRef}
        reference={reference}
        handleCopy={handleCopy}
      />
    </>
  );
};

export default ChallengeUssd;
