import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";
import { FormInput } from "../../styles";

export const DropdownLoading = styled.div`
  position: fixed;
  z-index: 201;
  background-color: ${COLORS.DEFAULT_WHITE};
  top: 48px;
  left: 0;
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownEmpty = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;

  span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: var(--regularFont);
    color: ${COLORS.DEFAULT_BLACK};
  }
`;

export const DropdownSearch = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  input {
    padding-right: 48px;
  }
`;

export const DropdownSearchIcon = styled.div`
  right: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
`;

export const SearchInput = styled(FormInput)`
  border: 1px solid ${COLORS.LIGHT_GRAY};
  box-shadow: "0 0 5px rgba(0, 0, 0, 0.2)";
  margin: 0;
`;
