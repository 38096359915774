import { useState } from "preact/hooks";
import { FormLoaderWrapper, BaseButton } from "../../styles";
import { GoBackButtonWrapper } from "./style";
import Trans from "../Trans";
import Loader from "../Loaders";
import ChallengeComplete from "./ChallengeComplete";
import useApmTransaction from "../../hooks/useApmTransaction";
import DataPairsViewBuilder from "./InfoViewBuilder";

const ChallengeP2C = ({ p2c, handleCopy }) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    setIsPaymentCompleted(true);

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  const config = [
    {
      title: "pan",
      key: "card_number",
      copyMessage: "panCopied",
    },
    {
      title: "bankName",
      key: "bank_name",
      copyMessage: "bankNameCopied",
      hasIcon: true,
    },
    {
      title: "cardHolder",
      key: "card_holder",
      copyMessage: "cardHolderCopied",
    },
  ];

  return p2c ? (
    <>
      {isPaymentCompleted ? (
        <ChallengeComplete />
      ) : (
        <>
          <DataPairsViewBuilder
            config={config}
            data={p2c}
            handleCopy={handleCopy}
            infoMessage="virtualAccountFooter"
            title="virtualAccountTitle"
          />
          <GoBackButtonWrapper>
            <BaseButton onClick={handlePaymentMade} marginBottom="22px">
              <Trans message="madePayment" />
            </BaseButton>
          </GoBackButtonWrapper>
        </>
      )}
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeP2C;
