import styled, { css, keyframes, createGlobalStyle } from "styled-components";
import { COLORS } from "../constants/themeConstants";

export const GlobalStyles = createGlobalStyle`

  ${(props) => {
    const theme = props.theme || {};
    const mainFont = theme?.mainFont;
    const mainNameFont = theme?.mainNameFont;
    const mainUrlFont = theme?.mainUrlFont;
    const regularFont = theme?.regularFont;
    const regularNameFont = theme?.regularNameFont;
    const regularUrlFont = theme?.regularUrlFont;
    const mediumFont = theme?.mediumFont;
    const mediumNameFont = theme?.mediumNameFont;
    const mediumUrlFont = theme?.mediumUrlFont;

    return `
    ${
      mainUrlFont
        ? `
    @font-face {
      font-family: ${mainNameFont};
      src: local(${mainNameFont}),
        url(${mainUrlFont}) format('truetype');
    }
    `
        : ``
    }

    ${
      regularUrlFont
        ? `
    @font-face {
      font-family: ${regularNameFont};
        src: local(${regularNameFont}),
          url(${regularUrlFont}) format('truetype');
    }
    `
        : ``
    }

    ${
      mediumUrlFont
        ? `
    @font-face {
      font-family: ${mediumNameFont};
        src: local(${mediumNameFont}),
          url(${mediumUrlFont}) format('truetype');
    }
    `
        : ``
    }
      

      :root {
        --mainFont: ${mainFont};
        --regularFont: ${regularFont};
        --boldFont: ${mediumFont};
        --runcPinkPM: #ff7e7e;
  }
    `;
  }}

html,
body {
  max-height: 100%;
  padding: 0;
  max-width: ${(props) => props.theme?.formMaxWidth};
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  background:  ${({ theme }) => theme?.bodyBackground};
  box-shadow: 0 0 15px 0 rgba(123, 148, 157, 0.25);
  font-family: var(--mainFont);
  font-weight: 400;
  color: #101820;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus-visible {
  outline: none;
}

body > div {
  min-width: 320px;
  max-width: ${(props) => props.theme?.formMaxWidth};
  width: 100%;
  background: ${(props) => props.theme?.mainBackGroundColor};
  position: relative;
}

h1,
p,
h2,
ul,
span {
  font-family: var(--mainFont);
  padding: 0;
  margin: 0;
  letter-spacing: 0.4px;
}

* {
  box-sizing: border-box;
}

#app {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;


  @media (min-width: 768px) and (min-height: 500px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 160px);
    border-radius:  ${({ theme }) => theme?.appBorderRadius};
  }

  @media  (max-height: 768px) and (min-width: 768px) {
    height: calc(100vh - 32px);
  }
  @media  (max-height: 500px) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

#app > *, #app  > * {
  flex-shrink: 0;
  user-select: none;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const WhiteOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const FormLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || "264px"};
  flex-direction: column;
`;

export const FormContainer = styled.div`
  max-width: ${(props) => props.theme?.formMaxWidth};

  @media (min-width: 768px) {
    width: 408px;
    margin: 0 auto;
  }
`;

export const FormWrapper = styled.div`
  margin: 0 16px 16px;

  @media (max-height: 768px) {
    margin: 0 16px;
  }
`;

export const IconCardImg = styled.img`
  height: 20.5px;
  width: 30px;
`;

export const InputFormActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  position: relative;
  max-width: 376px;
  margin: ${({ $margin, theme }) => {
    if (theme?.inputGap) {
      return theme.inputGap;
    }

    if ($margin) {
      return $margin;
    }

    return "";
  }};

  ${({ isNoEditable }) => {
    if (isNoEditable) {
      return `
        cursor: pointer;

        input {
          pointer-events: none;
        }
      `;
    }

    return "";
  }}

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 408px;
  }
`;

export const FormInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FormInput = styled.input`
  width: 100%;
  border-radius: ${({ theme }) => theme?.elementBorderRadius};
  font-size: 18px;
  border: ${({ theme }) => `1px solid ${theme?.inputBackgroundColor}`};
  color: ${({ theme }) => theme?.inputColor};
  background-color: ${({ theme, $focused }) =>
    $focused ? theme?.inputFocusedBackground : theme?.inputBackgroundColor};
  font-family: var(--boldFont);
  border: ${({ $focused, $errorCard, theme }) => {
    if ($focused) {
      return `1px solid  ${theme.mainColor}`;
    } else if ($errorCard) {
      return `1px solid ${theme?.errorTitleColor}`;
    }

    return "";
  }};
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  letter-spacing: 0.7px;
  box-shadow: ${({ $errorCard }) =>
    $errorCard ? "0 0 5px rgba(235, 0, 117, 0.5)" : ""};
  pointer-events: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  cursor: ${({ $inputBlocked }) => ($inputBlocked ? "default" : "text")};
  user-select: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  height: ${(props) => props.theme?.inputHeight};
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: white;
  }

  ${({ isDropdown }) => {
    if (isDropdown) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 24px;
      `;
    }

    return ``;
  }}
`;

export const LabelBrandWrapper = styled.div`
  position: absolute;
  top: 48%;
  right: 56px;
  transform: translateY(-50%);
  right: ${({ display }) => (display ? "20px" : "75px")};
`;

export const ChangeCardMobDesktopWrapper = styled.div`
  margin-top: 10px;
  margin-left: 6px;
  @media (min-width: 768px) {
    margin-left: 16px;
  }
`;

export const CircleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
`;

export const SavedCardMobileWrapper = styled.div`
  font-size: 14px;
  color: #0062eb;
  display: flex;
  padding: 4px 12px;
  position: absolute;
  right: 0;
  top: -21px;
  border-radius: 20px;
  width: ${({ width }) => width || " 130px"};
  font-family: var(--regularFont);
  cursor: pointer;
`;

export const InvalidCardErrorTitle = styled.p`
  position: absolute;
  top: ${({ theme }) => `${57 + parseInt(theme.bottomError, 10)}px`};
  left: 12px;
  color: ${({ theme }) => theme?.errorTitleColor};
  font-size: 12px;
  font-family: var(--regularFont);
  width: calc(100% - 24px);
  min-width: 230px;
`;

export const FormDescription = styled.span`
  color: ${({ $focused, theme }) =>
    $focused
      ? theme?.inputColorFocusedPlaceholder
      : theme?.inputColorPlaceholder};
  font-size: ${({ $fs, theme }) =>
    $fs ? $fs : theme?.inputPlaceholderFontSize};
  margin-left: ${({ theme }) => theme?.inputPlaceHolderMarginLeft};
  margin: ${({ $margin }) => ($margin ? $margin : "")};
  font-weight: ${({ $fw }) => ($fw ? $fw : "")};
  font-family: var(--mediumFont);
`;

export const FormInputContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const FormInputActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  position: relative;

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }
`;

export const FormInputDateCvvWrapper = styled.div`
  display: flex;
  min-width: 0px;
  justify-content: space-between;
`;

export const FormSelectWrapper = styled.div`
  border: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const FormInputDateWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    margin-right: 6px;
  }
`;

export const FormInputDate = styled.input`
  font-family: var(--boldFont);
  border-radius: ${(props) => props.theme?.elementBorderRadius};
  color: ${(props) => props.theme?.inputColor};
  background-color: ${({ theme, $focused }) =>
    $focused ? theme?.inputFocusedBackground : theme?.inputBackgroundColor};
  font-size: 18px;
  padding: 12px 16px;
  margin-top: 6px;
  height: ${(props) => props.theme?.inputHeight};
  border: 1px solid
    ${({ $focused, $errorDate, theme }) => {
      if ($focused && !$errorDate) {
        return `${theme.mainColor}`;
      } else if ($errorDate || ($focused && $errorDate)) {
        return `${theme?.errorTitleColor}`;
      }

      return `${theme?.inputBackgroundColor}`;
    }};
  box-shadow: ${({ errorDate }) =>
    errorDate ? "0 0 5px rgba(235, 0, 117, 0.5)" : ""};
  width: ${({ theme }) => theme?.inputCvvDateWidth};
`;

export const CvvTooltipMainTitle = styled.p`
  span {
    font-family: var(--boldFont);
    color: #fff;
    font-size: 14px;
    line-height: 16.03px;
  }
`;

export const CvvTooltipSubTitle = styled.p`
  span {
    font-family: var(--regularFont);
    color: #fff;
    font-size: 14px;
    line-height: 16.03px;
  }
`;

export const DateInvalidTitle = styled.p`
  position: absolute;
  top: ${({ theme }) => `${74 + parseInt(theme.bottomError, 10)}px`};
  left: 12px;
  color: ${({ theme }) => theme.errorTitleColor};
  font-size: 12px;
  font-family: var(--regularFont);
  width: 120px;
`;

export const FormSelectCvvWrapper = styled.div`
  border: none;
  flex-wrap: wrap;
  position: relative;

  width: 8px !important;
  margin-left: 10px;
  & > div {
    width: ${({ theme }) => theme?.inputCvvDateWidth};
  }
`;

export const FormErrorWrapper = styled.div`
  position: absolute;
  margin-top: 0;
  padding-top: 2px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  > div {
    position: absolute;
    width: auto;
    left: 37%;
    transform: translateX(-50%);
    top: 15px;
  }

  &::before,
  &::after {
    display: none;
  }
`;

export const FormErrorTitleCvv = styled.span`
  display: none;
`;

export const FormCvvWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipText = styled.div`
  width: 249px;
  font-size: 12px;
  top: -50px;
  background-color: #1a1a1a;
  color: #ffffff;
  margin-left: 7px;
  border-radius: 6px;
  padding: 12px 10px;
  position: absolute;
  z-index: 1;
  right: 40px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  @media (min-width: 320px) {
    right: 72px;
  }

  @media (min-width: 420px) {
    right: 40px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 12%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #1a1a1a;
  }
`;

export const TooltipCvvWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipDateWrapper = styled.div`
  display: inline-block;
`;

export const FormIconDateTooltip = styled.img`
  position: absolute;
  @media (min-width: 320px) {
    left: 85px;
  }
  @media (min-width: 420px) {
    left: 120px;
  }
  top: 38px;
`;

export const TooltipDateText = styled(TooltipText)`
  @media (min-width: 320px) {
    width: 180px;
    top: 25px;
    right: -139px;
  }

  @media (min-width: 420px) {
    width: 180px;
    top: 25px;
    right: -175px;
  }
  @media (min-width: 768px) {
    width: 180px;
    top: 25px;
    right: -168px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 20%;
    left: -5%;
    margin-top: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #1a1a1a;
    rotate: 180deg;
  }
`;

export const FormIconCvvLock = styled.img`
  position: absolute;
  @media (min-width: 320px) {
    left: 85px;
  }
  @media (min-width: 420px) {
    left: 120px;
  }
  top: -45px;
`;

export const InvalidCvvTitle = styled.p`
  position: absolute;
  top: ${({ theme }) => `${74 + parseInt(theme.bottomError, 10)}px`};
  left: 12px;
  color: ${({ theme }) => theme?.errorTitleColor};
  font-size: 12px;
  font-family: var(--regularFont);
  width: 120px;
`;

export const SavedCardWrapper = styled.div`
  position: relative;
`;

export const SavedCardInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme, height }) => height || theme?.managePopupElementHeight};
  flex-wrap: nowrap;
  padding: 9px 18px 5px 18px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#f0f4f5"};
  border-radius: 8px;
  transition: background-color 0.3s;
  height: ${({ height }) => height || ""};
  cursor: pointer;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor || "#d9dfe1"};
  }

  @media (min-width: 320px) {
    padding: 5px 5px 5px 5px;
  }

  @media (min-width: 390px) {
    padding: 9px 18px 5px 18px;
  }
`;

export const AnotherCardListContainer = styled.div`
  z-index: 200;
  position: fixed !important;
  bottom: 0 !important;
  top: auto !important;
  background-color: #fff;
  width: 100%;
  max-width: 440px;
  padding: 20px 20px 40px 20px;
  border-radius: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  display: none;
  flex-direction: column;

  ${(props) =>
    props.isOpenCardListStatus &&
    css`
      animation: ${fadeIn} 0.3s;
      display: flex;
      opacity: 1;
      max-height: 216px;
      overflow-y: auto;
    `}

  ${(props) =>
    props.isOpenCardListFade &&
    css`
      animation: ${fadeOut} 0.3s;
      display: flex;
      opacity: 0;
    `}
`;

export const CardLabelNumberContainer = styled.div`
  width: 165px;
  flex-shrink: 0;
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const SavedCardPopUpTitle = styled.p`
  font-size: 20px;
  font-family: var(--regularFont);
  color: ${({ theme }) => theme?.popupTextTitleColor};
  margin-right: 16px;
`;

export const CardLabelNumber = styled.span`
  font-family: var(--boldFont);
  white-space: nowrap;
  color: ${({ color }) => color || COLORS.DEFAULT_BLACK};
  font-weight: 500;

  @media (min-width: 320px) {
    margin-left: ${({ ml }) => ml || "7px"};
    font-size: 14px;
    width: 165px;
  }

  @media (min-width: 390px) {
    margin-left: ${({ ml }) => ml || "14px"};
    font-size: 16px;
    width: 190px;
  }

  @media (min-width: 420px) {
    width: 215px;
  }
`;

export const CardLabelDate = styled.div`
  margin-left: 24px;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--mainFont);
  color: ${COLORS.GRAY};
`;

export const DeleteCardPopupTitle = styled.span`
  span {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--mainFont);
    // MERGE TODO: check colors
    /* color: ${COLORS.DEFAULT_BLACK}; */
    color: ${({ theme }) => theme?.popupTextTitleColor};
  }
`;

export const ActionButtonCardEditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 16px;
`;

export const ManageCardWrapper = styled.div`
  background-color: ${({ theme }) => theme?.managePopupBackgroundColor};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ActionButtonCardList = styled.button`
  background: ${({ background }) => background || "#e5eaeb"};
  padding: 10px 16px;
  border: none;
  border-radius: ${({ theme }) => theme?.elementBorderRadius};
  max-width: ${({ maxWidth }) => maxWidth || ""};
  width: ${({ width }) => width || ""};
  text-transform: ${({ textTransform }) => textTransform || ""};
  cursor: pointer;

  span {
    font-family: var(--boldFont);
    font-size: ${({ fontSize }) => fontSize || " 18px;"};
    font-weight: ${({ fontWeight }) => fontWeight || 500};
    color: ${({ color }) => color || ""};
    white-space: nowrap;
  }
`;

export const EditCardActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  img {
    cursor: pointer;
    margin-left: auto;
    height: 24px;
    width: 24px;
  }
`;

export const ManageCardActionTitle = styled.span`
  margin-right: 16px;

  span {
    font-size: 20px;
    font-family: var(--boldFont);
    font-weight: 500;
    color: ${COLORS.DEFAULT_BLACK};
    letter-spacing: 0;
  }
`;

export const ManageCardBlockTitle = styled.span`
  text-align: ${({ textCenter }) => textCenter};
  font-size: 16px;

  span {
    text-align: ${({ textCenter }) => textCenter};
    color: ${COLORS.BLUE};
    font-size: 16px;
    font-family: var(--boldFont);
    font-weight: 500;
    cursor: pointer;
  }
`;

export const PopupWrapper = styled.div`
  z-index: 200;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme?.managePopupBackgroundColor};
  width: 100%;
  max-width: 440px;
  max-height: 100%;
  height: auto;
  min-height: ${({ $isFullView }) => ($isFullView ? "calc(100% - 0px)" : "0")};
  padding: 20px 0 40px;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 16px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const PopupHeaderContainer = styled.div`
  flex-shrink: 0;
  display: ${({ withHeader }) => (withHeader ? "block" : "none !important")};
`;

export const PopupChildrenContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LanguageModalMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PopupMainWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  &:hover {
    img {
      cursor: pointer;
    }
  }
`;

export const PopupTitle = styled.span`
  span {
    font-size: 18px;
    font-family: var(--boldFont);
    font-weight: ${({ hasSubtitle }) => (hasSubtitle ? 600 : 500)};
    line-height: 32px;
  }
`;

export const SubtitlePopup = styled.span`
  font-size: 16px;
`;

export const PopupContentActionWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 10px;
  height: 48px;
  background-color: ${({ isActive }) =>
    isActive ? COLORS.BlUE_LIGHT : "#f0f4f5"};
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.BlUE_LIGHT};
  }
`;

export const PopupIcon = styled.div`
  width: ${({ iconWidth }) => iconWidth};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
  }
`;

export const PopupActionTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-family: var(--regularFont);
  text-transform: capitalize;

  span {
    font-size: 14px;
    font-weight: 700;
    font-family: var(--regularFont);
    text-transform: capitalize;
  }
  display: flex;
  align-items: center;
`;

export const PopupActionSubTitle = styled(PopupActionTitle)`
  margin-left: 8px;
`;

export const Container = styled.div`
  max-width: 440px;
  margin: 0 auto;
  flex-shrink: 0;
  width: 100%;
`;

export const FormAmountOfPaymentWrapper = styled.div`
  text-align: center;
`;

export const OuterBlockHeader = styled.div`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "440px")};
  margin: 0 auto;
  position: relative;
`;

export const OuterInnerBlock = styled.div`
  background: ${(props) =>
    props.theme?.headerGradient ||
    "radial-gradient(at top, #0062eb, #0062eb 92%, transparent 50%)"};
  height: 78px;
  position: absolute;
  width: 100%;
  /* z-index: -1; */
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "440px")};
  margin: 0 auto;
  margin-top: ${({ $position }) => ($position < 100 ? "-30px" : "-1px")};

  @media (min-width: 320px) and (max-width: 340px) {
    height: 40px;
  }

  @media (min-width: 768px) {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "440px")};
  }
`;

export const StyledCurrentLanguageIcon = styled.img`
  border: 1px solid white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const FormHeaderTitle = styled.p`
  color: #fff;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
`;

export const HeaderLogoWrapper = styled.div`
  background-color: ${(props) =>
    props.theme?.formHeaderBackground || "#0062eb"};
  margin: 0 auto;
  padding: ${({ pt, pb }) => `${pt || "24px"} 32px ${pb || "24px"}`};
  text-align: center;
  position: relative;
  width: 100%;
  align-items: ${({ alignItems }) => alignItems || "center"};
  display: ${({ display }) => display || "flex"};
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  ${({ $hasGradient, theme, maxWidth }) => {
    if ($hasGradient) {
      return `
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: calc(100% - 38px);
          background: ${
            theme?.headerGradient ||
            "radial-gradient(at top, #0062eb, #0062eb 92%, transparent 50%)"
          };
          z-index: -1;
          height: 78px;
          width: 100%;
          max-width: ${maxWidth || "440px"};
        }
      `;
    }
  }}

  @media (min-width: 768px) {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  @media (max-height: 768px) {
    padding: ${({ pt, pb }) => `${pt || "16px"} 32px ${pb || "8px"}`};
    gap: 8px;
  }
`;

export const GoBackActionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
  flex-shrink: 0;
  gap: 8px;
  height: 26px;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-left: ${({ ml }) => ml || "0"};
  }
`;

export const GoBackActionTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  cursor: pointer;
  font-family: var(--boldFont);

  & span {
    font-family: var(--boldFont);
    font-size: 14px;
  }
`;

export const LogoLangWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 100%;

  ${({ $hasLogo }) =>
    !$hasLogo &&
    `
        width: 48px;
    `};

  /* @media (min-width: 768px) {
    padding: 0 32px;
  } */
`;

export const InnerHeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $padding }) => $padding || "0px 16px 16px"};
  margin-top: auto;
  background: ${({ theme }) => theme?.infoBlockBackground};
  box-shadow: ${({ theme, $noShadow }) => ($noShadow ? "" : theme?.boxShadow)};
  border-radius: 15px;
  margin: 0 auto;
  padding-top: ${({ $pt }) => $pt || ""};
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 30;
  gap: ${({ $gap }) => $gap || ""};

  @media (min-width: 768px) {
    margin-left: ${({ theme }) => theme?.infoBlockMargin};
    margin-right: ${({ theme }) => theme?.infoBlockMargin};
  }

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }
`;

export const LogoBrandHeader = styled.img`
  max-width: 320px;
  height: 36px;
`;

export const StyledLanguageButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

export const DetailsActionWrapper = styled.div`
  display: flex;
  width: 110px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const DetailsTitle = styled.p`
  color: #0062eb;
  font-family: var(--regularFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: ease 0.3s;

  &:hover {
    color: #101820;
  }
`;

export const TotalInfoWrapper = styled.div`
  margin-top: ${({ $mt }) => $mt || ""};
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: center;

  @media (max-height: 768px) {
    padding: 8px 12px;
  }
`;

export const ContainerDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || ""};
  padding-bottom: ${({ pb }) => pb || ""};
`;

export const DetailToggler = styled.a`
  color: ${(props) => props?.color || props.theme?.buttonColor};
  font-family: var(--boldFont);
  cursor: pointer;
  padding-bottom: 12px;
`;

export const BlockDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ $mb }) => $mb || "12px"};
  align-items: center;
  width: ${({ width }) => width || ""};
`;

export const BankTransferAfricaInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 20px 0px 20px;

  span {
    text-align: center;
  }
`;

export const DetailsTransactionTitle = styled.span`
  font-style: normal;
  font-weight: ${({ fw }) => fw || "400"};
  font-size: ${({ titleFs, theme }) =>
    theme?.subMainInfoTexFontSize || titleFs || "14px"};
  line-height: 16px;
  margin: ${({ mb }) => mb || ""};
  color: ${(props) =>
    props.color ||
    props.theme?.subMainInfoTextColor ||
    props.theme?.headerTitleColor ||
    "#5A747A"};
  margin-bottom: ${({ $mb }) => $mb || ""};
  font-family: var(--regularFont);
  text-align: ${(props) => (props?.$controlledLength ? "left" : "right")};
  width: ${(props) => {
    if (props?.$controlledLength) {
      return "110px";
    }

    if (props.$width) {
      return props.$width;
    }

    return "";
  }};
  word-wrap: break-word;
  span {
    font-family: ${(props) =>
      props.$bold ? "var(--boldFont)" : "var(--mediumFont)"};
  }
`;

export const DetailsTransactionSubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ titleFs, theme }) =>
    theme?.subMainInfoTexFontSize || titleFs || "14px"};
  line-height: 16px;
  margin-right: ${(props) => props.$mr || ""};
  color: ${(props) =>
    props.theme?.mainTextColor ||
    props.color ||
    props.theme?.headerTitleColor ||
    "#101820"};
  font-family: ${(props) =>
    props.bold ? "var(--boldFont)" : "var(--regularFont)"};
  text-align: ${(props) => (props?.$controlledLength ? "left" : "right")};
  width: ${(props) => (props?.$controlledLength ? "110px" : "")};
  span {
    font-family: ${(props) =>
      props.$bold ? "var(--boldFont)" : "var(--mediumFont)"};
  }
`;

export const SuccessWrapperKombine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #031938;
`;

export const DetailTransactionIDTitle = styled(DetailsTransactionTitle)`
  width: 138px;
  text-align: justify;
`;

export const DetailsTransactionSubTitleWrapper = styled.span`
  display: ${(props) => (props?.flex ? "flex" : "")};
  text-align: ${(props) => (props?.flex ? "center" : "end")};
`;

export const DetailsTransactionTitleBankTransferArgentina = styled(
  DetailsTransactionTitle,
)`
  width: 138px;
  text-align: justify;
  font-weight: ${({ theme }) => theme?.subMainInfoTexFontWeight || "600"};
`;

export const CopyWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const CopyActionWrapper = styled.span`
  cursor: pointer;
`;

export const TransactionSumWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TransactionSumContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ $hasError, theme }) =>
    $hasError ? theme?.notificationColorError : null};
`;

export const InfoCopyBoldTitle = styled.span`
  margin-left: 6px;
  font-weight: 600;
`;

export const MethodIconInfoWrapper = styled.div`
  margin: 20px 0px 16px 0px;

  img {
    max-height: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 16px;
`;

export const ListItemInfoCopyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TransactionDecimalTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, 5vw, 24px);
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const DetailsTransactionVirtualAccTitle = styled.span`
  color: ${(props) => props.theme?.mainColor};
  font-size: 24px;
  margin-right: 8px;
  font-family: var(--boldFont);
`;

export const InnerLine = styled.div`
  width: 100%;
  margin-top: ${({ $mt }) => $mt || ""};
  height: 1px;
  background: ${({ theme }) => theme?.innerLineBackground};
  width: 100%;
  margin-bottom: 16px;
`;

export const TransactionSumTitle = styled.span`
  font-style: normal;
  font-size: clamp(16px, 5vw, 24px);
  line-height: 24px;
  font-family: var(--boldFont);
  color: ${({ color }) => color};
  white-space: nowrap;
  font-weight: 500;

  span {
    font-family: var(--boldFont);
  }
`;

export const ChallengeInputWrapper = styled.div`
  margin: 0 auto;
  padding: 0px 16px;
`;

export const ResendSpan = styled.span`
  color: ${(props) => props.theme.mainColor};
  cursor: ${(props) =>
    props.isButtonDisabled || props.isResendClicked
      ? "not-allowed"
      : "pointer"};
  opacity: ${(props) =>
    props.isButtonDisabled || props.isResendClicked ? 0.5 : 1};
  span {
    font-family: var(--boldFont);
  }
`;

export const TransactionIDTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fs }) => fs || "14px"};
  line-height: 16px;
  font-family: var(--regularFont);
  color: ${({ color }) => color || "#5a747a"};
`;

export const TransactionCurrencyTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: clamp(16px, 5vw, 24px);
  line-height: 27px;
  text-align: right;
  font-family: var(--regularFont);
  margin-left: ${(props) => props.$ml || ""};
  color: ${({ color }) => color || "#5a747a"};
`;

export const KombineErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TransactionRefTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fs }) => fs || "14px"};
  font-family: var(--boldFont);
  line-height: 16px;
  color: ${({ color }) => color || "#101820"};
  margin-right: 8px;
`;

export const ErrorPopupIconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
  background: inherit;
  img {
    cursor: pointer;
  }
`;

export const CloseIcon = styled.svg`
  cursor: pointer;
  fill: #1a1a1a;
`;

export const VideoLink = styled.a`
  color: ${(props) => props.theme?.buttonColor};
  cursor: pointer;
  font-family: var(--boldFont);
  font-size: 15px;
  text-decoration: none;
  transition: ease 0.3s;
  margin-bottom: 12px;

  &:hover {
    color: #101820;
  }
`;

export const PaymentErrorWrapper = styled.div`
  width: 100%;
  background: #ffe0eb;
  padding: 16px;
  margin: 0 auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ErrorTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentErrorMainTitle = styled.span`
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: var(--boldFont);
  text-align: left;
`;

export const PaymentErrorTitle = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: initial;
  font-family: var(--regularFont);
`;

export const PaymentErrorListItem = styled.li`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: initial;
  margin-left: 12px;
  font-family: var(--regularFont);
`;

export const SuccessPageBlockWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SuccessPageWrapper = styled.div`
  margin: 0 auto;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 440px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TransactionInfoFirstBlock = styled.div`
  background-color: ${(props) =>
    props.theme.successPageBackground || "#f0f4f5"};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${({ padding }) => padding || "40px 24px 24px"};
  text-align: center;
`;

export const PaymentTitle = styled.p`
  @media (min-width: 320px) {
    font-family: var(--boldFont);
    font-style: normal;
    font-weight: 500;
    font-size: ${({ fs }) => fs || "25px"};
    line-height: 30px;
    text-align: center;
    color: ${(props) => props.theme.successTitleColor};
    margin-bottom: ${({ mb }) => mb || "28px"};
  }
  margin-top: 21px;
  font-weight: bold;
  margin-bottom: ${({ mb }) => mb || "16px"};
  color: ${({ $color }) => $color || "#101820"};
  width: ${({ width }) => width || ""};
`;

export const TransactionInfoSecondBlock = styled.div`
  background-color: ${({ theme, background }) =>
    background || theme?.managePopupBackgroundColor};
  height: ${({ height }) => height || "100%"};
  padding: ${({ padding }) => padding || "27px 12px 20px"};
  margin: ${({ margin }) => margin || ""};
  border-radius: ${({ borderRadius }) => borderRadius || ""};
  display: flex;
  flex-direction: column;
`;

export const DownloadReceiptBlock = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  width: 160px;
  margin: 0 auto;
`;

export const PendingIcon = styled.img`
  display: block;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding-bottom: 25px;
  }
`;

export const DownloadReceiptTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-family: var(--regularFont);
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.mainColor};
`;

export const TooltipTextCvv = styled.div`
  top: -14px;
  width: 184px;
  right: 26px;
  z-index: 12;
`;

export const TooltipCvvText = styled.p`
  font-size: 12px;
  margin-bottom: 8px;
`;

export const TooltipImg = styled.img`
  display: block;
  width: 100px;
  height: 48px;
`;

export const WrapperRememberMe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 28px;
`;
export const GoogleApplePayTestButton = styled.div`
  height: 40px;
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  & > img {
    margin: 0 3px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  padding: 7px 10px;
  height: 40px;
  border: ${({ isAmountBelowMin }) =>
    isAmountBelowMin ? "1px solid #EB0075" : "1px solid white"};
  box-shadow: ${({ isAmountBelowMin }) =>
    isAmountBelowMin ? "0px 0px 5px 0px #EB007580" : "none"};
`;

export const CurrencySymbol = styled.div`
  background-color: black;
  color: white;
  border-radius: 15px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 100%;
  max-width: 85px;
  font-size: 14px;
  font-weight: 500;

  letter-spacing: 0.1px;
  font-size: clamp(11px, 14px - 1vw, 14px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 320px) and (max-width: 390px) {
    max-width: 75px;
    font-size: 11px;
  }
`;

export const AmountInput = styled.input`
  border: none;
  outline: none;
  border-radius: 20px;
  flex-grow: 1;
  padding: 5px;
  text-align: end;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0.1px;
  font-family: var(--boldFont);

  @media (min-width: 320px) and (max-width: 390px) {
    font-size: 14px;
  }
`;

export const AltMethodsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ $hasAltMethod }) => ($hasAltMethod ? "" : "column")};
  max-width: 400px;
  width: 100%;
  margin: ${({ $hasAltMethod }) =>
    $hasAltMethod ? "20px auto 12px" : "0 auto"};
  gap: 15px;
  padding: 0 12px;
  & > div {
    flex: ${({ $hasAltMethod }) => ($hasAltMethod ? "1" : "")};
  }

  @media (max-height: 768px) {
    margin: ${({ $hasAltMethod }) => ($hasAltMethod ? "8px auto" : "0 auto")};
  }
`;

export const ApplePayButtonWrapper = styled.div`
  width: 100%;
  height: 40px;
  text-align: center;
`;

export const PaymentInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => mb || "27px"};
  align-items: ${({ alignItems }) => alignItems || ""};
`;

export const QrCodeWrapper = styled.div`
  border-radius: 20px;
  padding: 10px;
  background-color: #fff;
  width: 220px;
  margin: 0 auto;
  margin-bottom: 23px;
`;

export const CenteredDivWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const BaseButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: ${(props) => props.width || "100%"};
  max-width: 376px;
  height: 40px;
  border-radius: ${(props) => props.theme.elementBorderRadius || ""};
  background: ${(props) => props.theme.buttonColor || ""};
  border: ${(props) =>
    props.theme.buttonColor ? `1px solid ${props.theme.buttonColor}` : "none"};
  color: ${(props) => props.theme.buttonTextColor || ""};
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0 auto;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  font-family: var(--regularFont);
  transition: ease 0.3s;
  cursor: pointer;
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};
  &:hover {
    background-color: #101820;
    color: #fff;
  }
`;

export const LoaderCenteredWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChallengeTimerTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-family: var(--regularFont);
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  margin-bottom: 24px;
`;

export const ResendTimeTitle = styled.span`
  font-family: var(--boldFont);
  color: #101820;
  span {
    font-family: var(--boldFont);
  }
`;

export const ChallengeFormMainWrapper = styled.div`
  align-items: center;
  flex-direction: column;
`;

export const ChallengeMainWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 24px;
`;

export const PaymentInfoWrapper = styled.div`
  background: #ffffff;
  border-radius: 15px;
  padding: 12px 9px 17px 12px;
  width: 376px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 32px;
  span {
    line-height: 17.17px;
    letter-spacing: 0.5px;
  }
`;

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 24, 32, 0.5);
  z-index: 199;
  display: block;
`;

export const ChallengeButtonWrapper = styled.div`
  display: ${({ pdf_url }) => (pdf_url ? "" : "flex")};
  width: ${({ width }) => width || ""};
  margin: 0 auto;
  justify-content: space-between;
`;
export const TransactionTitle = styled.span`
  font-style: normal;
  font-family: var(--boldFont);
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${({ $color }) => $color || "#5A747A"};
`;

export const BolettoDetailsSubTitle = styled.span`
  color: #000000;
  margin: 0 auto;
  font-size: 12px;
  line-height: 16px;
  width: 363px;
  letter-spacing: 0.5px;
  cursor: pointer;
`;

export const TransactionInfoErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 24px;
  background: #ffe0eb;
`;

export const TransactionInfoErrorBlockKomb = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin: 0 16px;
  border-radius: 8px;
  margin-bottom: ${({ mb }) => mb || ""};
  background: ${({ background }) => background};
  & svg {
    g {
      circle {
        fill: #e53956;
      }
    }
  }
`;

export const ErrorListItem = styled.li`
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
`;

export const ErrorTitle = styled.p`
  font-style: normal;
  font-family: var(--regularFont);
  font-weight: 600;
  font-size: 14px;
  text-align: ${({ textAlign }) => textAlign || ""};
  line-height: 16px;
  margin-left: ${({ ml }) => ml || ""};
  margin-bottom: ${({ mb }) => mb || ""};
  color: ${({ color }) => color || "#101820"};
`;

export const FormPayButton = styled.button`
  margin-top: 24px;
  display: block;
  background: ${(props) => props.theme.buttonColor || "#353645"};
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: var(--regularFont);
  font-size: ${({ theme }) => theme.mainFormFontSize};
  letter-spacing: normal;
  line-height: normal;
  width: calc(100% - 32px);
  border-radius: ${(props) => props.theme.elementBorderRadius || ""};
  outline: 0;
  padding: 8px 0;
  appearance: none;
  margin: ${(props) => props.theme.buttonMargin || "auto auto 16px"};
  max-width: 392px;
  transition: ease 0.3s;

  & + div {
    margin-top: 0;
  }

  &:hover {
    background-color: #101820;
  }

  & span {
    font-family: var(--regularFont);
    font-size: ${({ theme }) => theme.mainFormFontSize};
  }

  &:disabled {
    background-color: #7b949d;
    pointer-events: none;
    opacity: ${(props) => props.theme.disableButtonOpacity || "1"};
  }
`;

export const PendingPageTitle = styled.p`
  color: ${({ theme }) => theme?.successTitleColor};
  font-size: 20px;
`;

export const FormPayTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  span {
    font-family: var(--boldFont);
  }
`;

export const InnerDataBoletto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 7px;
  position: relative;
  margin-top: auto;
  width: 376px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(123, 148, 157, 0.25);
  border-radius: 15px;
  top: -18px;
  margin: 0 auto;
`;

export const IconColorWrapper = styled.div`
  position: relative;
  margin-left: ${({ ml }) => (ml ? ml : "")};
  margin-right: ${({ mr }) => (mr ? mr : "")};
  svg {
    path {
      fill: ${(props) =>
        props.theme?.iconCopyColorIcon || props.color || props.theme.mainColor};
    }
  }
`;

// Loader Gooey Effect

const rotate = keyframes`

    0% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: 66px center;
  }
  25% {
    margin-left: -25px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: 66px center;
  }
  50% {
    margin-left: -33px;
    transform: translateY(-50%) rotate(-360deg);
    transform-origin: 66px center;
  }
  50.1% {
    transform-origin: -12px center;
  }
  75% {
    margin-left: -36px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: -12px center;
  }
  75.01% {
    margin-left: -66px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: -12px center;
  }
  100% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: -12px center;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px) contrast(30);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Ball = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 66px center;
  margin-left: 40px;

  &:before {
    content: "";
    width: 68px;
    height: 68px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #f00;
  }
`;

export const Ball1 = styled(Ball)`
  margin-left: -100px;
`;

export const Ball2 = styled(Ball)`
  margin-left: 25px;
  transform: translateY(-50%) rotate(180deg);
  margin-left: -100px;
  animation: ${rotate} 1.7s linear infinite;
  z-index: 2;
`;

export const Ball3 = styled(Ball)`
  margin-left: 100px;
  z-index: 1;
`;

const firstDotKeyframes = keyframes`
  0%, 50% {
    transform-origin: 15px center;
    transform: rotateZ(180deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  51%, 100% {
    transform-origin: center;
    transform: rotateZ(180deg) translateX(-22px);
  }
  100% {
    transform: rotateZ(180deg) translateX(0);
  }
`;

const secondDotKeyframes = keyframes`
  0%, 50% {
    transform: rotateZ(-180deg);
  }
  51%, 100% {
    transform-origin: -8px center;
    transform: rotateZ(-180deg);
  }
`;

export const Loader4 = styled.div`
  &::before,
  &::after {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: red;
    top: calc(50% - 4px);
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &::before {
    left: 0;
    animation-name: ${firstDotKeyframes};
  }

  &::after {
    right: 0;
    opacity: 0.8;
    animation-name: ${secondDotKeyframes};
  }
`;

export const ErrorPageWrapper = styled.div`
  padding: 20px 0 0;
`;

export const HelperForChooseMethod = styled.p`
  font-size: 16px;
  font-family: var(--regularFont);
  width: 70%;
  margin: 0 auto;
`;

export const RemoveCardManageWrapper = styled.div`
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin: 0 0 0 auto;
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SavedCardInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ flexDirection }) => flexDirection || ""};
  margin-left: ${({ ml }) => ml || ""};
`;
