import { createContext } from "preact";
import { useEffect, useState, useCallback, useRef } from "preact/hooks";
import {
  logger,
  socket,
  state,
  theme,
  testData,
  crypto,
  processing,
  i18n,
  api,
  localStorageService,
} from "./services";
import { useData, useColorScheme, useConnection } from "./services/hooks";
import { route } from "preact-router";
import {
  AUTO_PROCESSED_PAYMENT_METHODS,
  METHODS_WITH_TRANSACTION_ON_SELECT,
} from "./constants/paymentMethods";
import { GlobalStyles, WhiteOverlay } from "./styles";
import { ThemeProvider } from "styled-components";
import Routes from "./components/Routes";
import brand from "./services/brand";
import LoaderLine from "./components/Loaders/LoaderLine";
import StatusLoader from "./components/Loaders/StatusLoader";
import useApmTransaction from "./hooks/useApmTransaction";
import ConnectionProvider from "./providers/ConnectionProvider";
import UIProvider from "./providers/UIProvider";
import useHotjar from "./hooks/useHotjar";
import useInitApp from "./hooks/useInitApp";

let count = 0;
const MOUNT_TIME = 3000;
const RECONNECT_TIME = 5000;

export const Context = createContext({
  matchesData: null,
  style: null,
  themeConfig: null,
  currentState: null,
  isTestMode: false,
  handleRoute: () => {},
  onGoBackButtonClick: (_cancelUrl, _options) => {},
});

const App = () => {
  const [isBrandLoading, setIsBrandLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [matchesData, setMatchesData] = useState({ matches: {} });
  const [socketIsReadyMount, setSocketIsReadyMount] = useState(true);
  const [style, setStyle] = useState(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [currentState, setCurrentState] = useState({});
  const [currentCryptoState, setCurrentCryptoState] = useState({});
  const lastStatus = useRef(null);
  const brandData = brand.getBrand();
  const { type, signature, sessionId, url } = matchesData?.matches || {};
  const isTestMode = testData.isTestMode(sessionId, signature);

  const { status, actualRoute, options } = currentState || {};
  const { colorTheme } = useColorScheme(options?.theme);
  const { createSpan, endTransaction } = useApmTransaction("PaymentFlow");

  useHotjar(process.env.HOTJAR_SITE_ID, sessionId);
  useInitApp();

  const onGoBackButtonClick = (cancelUrl, options) => {
    if (options?.canReturnToMethods) {
      const clearSelectedMethodSpan = createSpan("ClearSelectedMethod");

      state.onPaymentMethodSelected({ id: null }).finally(() => {
        clearSelectedMethodSpan?.end();
      });

      state.set({ cryptoConverted: null });

      return;
    }

    if (isTestMode) {
      console.log(`REDIRECT TO: ${cancelUrl}`);
      testData.reset();
      state.reset();

      return;
    }

    if (typeof window === "undefined") {
      return;
    }

    const cancelUrlSpan = createSpan("CancelUrl");

    setTimeout(() => {
      cancelUrlSpan?.end();
      endTransaction();

      const sessionReferrer = localStorageService.getSessionReferrer();
      const currentSessionReferrer =
        sessionReferrer.sessionId === sessionId
          ? sessionReferrer.referrer
          : null;

      try {
        if (cancelUrl) {
          state.changeLocation(cancelUrl, true);
        } else {
          logger.error("No redirect url provided!", {
            state: state.get(),
            url: cancelUrl,
          });

          if (currentSessionReferrer) {
            state.changeLocation(currentSessionReferrer, true);
          }
        }
      } catch (error) {
        logger.error("Go back redirect failed!", {
          error,
        });

        if (currentSessionReferrer) {
          state.changeLocation(currentSessionReferrer, true);
        }
      }
    }, 1000);
  };

  const getFormattedAmountParts = (amount) => {
    if (amount == null) {
      return {
        wholeNumber: "",
        decimal: "",
      };
    }

    const formattedAmount = amount?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const parts = formattedAmount.toString().split(".");
    const wholeNumber = parts[0];
    const decimal = parts[1] || "";

    return {
      wholeNumber,
      decimal,
    };
  };

  const newState = useData(state.get());
  const hasConnection = useConnection();

  const init = ({ type, sessionId, signature, withPolling = false }) =>
    new Promise((resolve) => {
      if (status === "error") {
        setCurrentState(state.get());
      }

      if (status === "error" && state?.get()?.error?.goBackMethod) {
        return resolve();
      }

      return resolve(
        state.checkStateAndRoute({
          type,
          sessionId,
          signature,
          withPolling,
        }),
      );
    });

  const createConnection = () => {
    const data = state.get();

    setTimeout(async () => {
      if (!data?.identifier && count < 3) {
        logger.error("Polling didn't send data", {
          data,
        });

        return init({ type, sessionId, signature }).then(() => {
          createConnection();
        });
      }

      if (data?.identifier || count === 3) {
        return init({ type, sessionId, signature, withPolling: true });
      }

      logger.error("Polling didn't send data and shown error", {
        data,
      });
    }, RECONNECT_TIME);
  };

  const checkData = (res) => {
    const data = state.get();

    if (!data?.identifier) {
      logger.error("Socket didn't send data", {
        data,
      });

      if (count < 2) {
        try {
          res?.close({
            reason: "Connection closed by client",
          });
        } catch (error) {
          logger.error("Socket close error", {
            error,
          });
        }

        setTimeout(() => {
          checkData(res);
        }, RECONNECT_TIME);
      } else if (count === 2) {
        createConnection();
      }

      count++;
    } else {
      count = 0;
    }
  };

  const preparationBeforeSocketConnection = () => {
    const data = state.get();
    const currentTheme = sessionStorage.getItem("currentTheme");

    state.setLoading({ status: true, eventName: "app_init" });

    if (currentTheme !== "undefined" && currentTheme !== data?.options?.theme) {
      const newStateData = {};

      newStateData.options = {
        ...(data?.options || {}),
        theme: data?.options?.theme || currentTheme,
      };

      state.set(newStateData);
    }
  };

  const socketConnection = () => {
    const isSocketDisabled =
      window?.location?.search?.includes("socket=disabled");

    if (isTestMode || isSocketDisabled) {
      return init({ type, sessionId, signature, withPolling: true });
    }

    return socket
      .run({ type, sessionId, signature, init })
      .then((res) => {
        setSocketIsReadyMount(false);

        return res;
      })
      .then((res) => {
        if (hasConnection) {
          setTimeout(() => {
            checkData(res);
          }, RECONNECT_TIME);
        }
      })
      .catch((e) => console.log(e?.response, "catch"));
  };

  const cryptoConnection = () => {
    return crypto
      .initCrypto({ sessionId, signature })
      .then((data) => {
        setCurrentCryptoState({ ...data });

        if (data?.locale) {
          i18n.setLocaleIfPresent(data.locale);
        }
      })
      .catch((error) => {
        console.error("Error initializing crypto:", error);
      });
  };

  useEffect(() => {
    if (newState) {
      const currentTheme = sessionStorage.getItem("currentTheme");

      const newStateData = {};

      if (
        currentTheme !== "undefined" &&
        currentTheme !== newState?.options?.theme
      ) {
        newStateData.options = {
          ...(newState?.options || {}),
        };

        setCurrentState({
          ...newState,
          ...newStateData,
        });
      } else {
        setCurrentState(newState);
      }

      if (newState?.options?.theme) {
        sessionStorage.setItem("currentTheme", newState?.options?.theme);
      }
    }
  }, [newState]);

  useEffect(() => {
    if (sessionId && signature) {
      api.getBrandInfo(sessionId, signature).finally(() => {
        setIsBrandLoading(false);
      });
    }
  }, [sessionId, signature]);

  useEffect(() => {
    if (!hasConnection) {
      count = 0;
      setSocketIsReadyMount(true);
    } else if (
      sessionId &&
      signature &&
      socketIsReadyMount &&
      type === "payment"
    ) {
      socketConnection();
    } else if (type === "crypto-address") {
      cryptoConnection();
    }
  }, [hasConnection]);

  useEffect(() => {
    const fetchData = () => {
      if (sessionId && signature && socketIsReadyMount && type === "payment") {
        state.setSession({ type, sessionId, signature });
        preparationBeforeSocketConnection();
        socketConnection();
      } else if (type === "crypto-address") {
        cryptoConnection()
          .then(() => theme.getStyle(state.getTheme(), sessionId, signature))
          .then((themeStyle) => setStyle(themeStyle))
          .then(() => theme.getThemeConfig(state.getTheme()))
          .then((themeConfig) => setThemeConfig(themeConfig))
          .catch((error) => {
            console.error("Error setting theme after init crypto:", error);
          });
      }
    };

    fetchData();
  }, [sessionId, signature, type]);

  useEffect(() => {
    if (sessionId && signature) {
      const sessionReferrer = localStorageService.getSessionReferrer();
      const referrer = document?.referrer;

      if (
        sessionReferrer.sessionId !== sessionId &&
        referrer &&
        referrer !== window?.location?.href
      ) {
        localStorageService.setSessionReferrer({ sessionId, referrer });
      }
    }
  }, [sessionId, signature]);

  useEffect(() => {
    if (
      (!type || !sessionId || !signature) &&
      Object.keys(matchesData).length > 1
    ) {
      if (isMounted) {
        new Promise((resolve) => {
          state.setCurrentSession({ type, sessionId, signature, resolve });
        });
      } else {
        setTimeout(() => {
          setIsMounted(true);
        }, MOUNT_TIME);
      }
    }
  }, [matchesData, isMounted]);

  useEffect(() => {
    const data = state.get();
    const selectedMethod = state.getSelectedPaymentMethod();

    if (data.status === "new" && lastStatus.current !== "new") {
      const trackOpenSpan = createSpan("TrackOpen");

      state.trackOpen().finally(() => {
        trackOpenSpan?.end();
      });
    }

    if (
      ["opened", "failed_retry"].includes(data.status) &&
      AUTO_PROCESSED_PAYMENT_METHODS.includes(selectedMethod?.method)
    ) {
      processing.processFormData({
        method: selectedMethod?.method,
      });
    }

    if (
      data.status === "expired" &&
      (!data.error || (data.error && Object.keys(data.error).length === 0))
    ) {
      state.expiredStatus();
    }

    if (["success", "failed"].includes(data.status)) {
      if (!data?.identifier && !isTestMode) {
        state.setLoading({ status: true, eventName: "no_identifier" });
      }
    }

    lastStatus.current = data.status;
  }, [status, actualRoute?.url]);

  useEffect(() => {
    const data = state.get();

    if (data.status) {
      Promise.resolve()
        .then(() =>
          theme.getStyle(state.getTheme(), sessionId, signature, brandData),
        )
        .then((themeStyle) => setStyle(themeStyle))
        .then(() => theme.getThemeConfig(state.getTheme()))
        .then((themeConfig) => setThemeConfig(themeConfig));
    }
  }, [status, actualRoute?.url, options?.theme]);

  useEffect(() => {
    if (actualRoute && actualRoute.url !== url) {
      route(actualRoute.url);
    }
  }, [actualRoute, url]);

  /*
    when status is opened automatically selects payment method
    based on options.method field value if method is not auto
    or even for auto method based on only available payment method if available_methods.length is 1
    in test mode also listens options.method changes and mocks all available methods
  */
  useEffect(() => {
    const { status, selected_method_id, options } = newState || {};
    const { method } = options || {};
    const isReadyToRun =
      ["opened", "failed_retry"].includes(status) &&
      (isTestMode || !selected_method_id);
    const methods = state.getAvailablePaymentMethods();
    const nonErrorMethods = methods.filter((availableMethod) => {
      return !METHODS_WITH_TRANSACTION_ON_SELECT.find((methodConfig) => {
        const dataArray = newState?.[methodConfig.dataArrayName];
        const currentMethod = dataArray?.find((item) => {
          if (!item.payment_method_id) {
            return methodConfig.name === availableMethod.method;
          }

          return item.payment_method_id === availableMethod.id;
        });

        return currentMethod?.error_code;
      });
    });
    const onlyAvailableMethod =
      nonErrorMethods.length === 1 ? nonErrorMethods[0] : null;

    const testAutoMethod =
      isTestMode && selected_method_id && method === "auto"
        ? { id: null }
        : null;
    const methodToSelect = onlyAvailableMethod || testAutoMethod;

    if (isReadyToRun && methodToSelect) {
      const methodSelectSpan = createSpan("AutoSelectMethod");

      state
        .onPaymentMethodSelected({
          id: methodToSelect.id,
        })
        .finally(() => {
          methodSelectSpan?.end();
        });
    }
  }, [newState?.options?.method, newState?.status]);

  logger.initApm();

  const [preloaded, setPreloaded] = useState(true);

  const onPreloadDefaultRoute = useCallback(
    (routesConfig) => {
      if (
        preloaded &&
        routesConfig?.active?.[routesConfig?.active.length - 1]?.type?.preload
      ) {
        routesConfig.active[routesConfig.active.length - 1].type.preload?.();
        setPreloaded(false);
      }
    },
    [preloaded],
  );

  const handleRoute = useCallback(
    (routesConfig) => {
      onPreloadDefaultRoute(routesConfig);
      setMatchesData(routesConfig?.active[0]?.props);
    },
    [onPreloadDefaultRoute],
  );

  const value = {
    matchesData,
    style,
    themeConfig,
    currentState,
    isTestMode,
    handleRoute,
    getFormattedAmountParts,
    onGoBackButtonClick,
    currentCryptoState,
    setCurrentCryptoState,
  };
  console.log("Current env:", process.env.NODE_ENV);

  return (
    <ThemeProvider theme={colorTheme}>
      <UIProvider>
        {isBrandLoading ? (
          <WhiteOverlay>
            <LoaderLine />
          </WhiteOverlay>
        ) : null}
        <GlobalStyles />
        <Context.Provider value={value}>
          <StatusLoader />
          <ConnectionProvider>
            <Routes />
          </ConnectionProvider>
        </Context.Provider>
      </UIProvider>
    </ThemeProvider>
  );
};

export default App;
