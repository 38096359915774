import { withTransaction } from "@elastic/apm-rum-react";
import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { i18n } from "../../services";
import CryptoWidget from "../../components/CryptoWidgetForm";
import MainPage from "../../components/MainPage/MainPage";
import { ContextUI } from "../../providers/UIProvider";

const Main = ({ type, sessionId, signature }) => {
  const {
    currentState = {},
    currentCryptoState,
    setCurrentCryptoState,
  } = useContext(Context);

  const { setNotificationData } = useContext(ContextUI);

  const { reference } = currentState;

  const { locale } = currentCryptoState;

  const isCryptoWidget =
    type === "crypto-address" && currentCryptoState?.address;

  // TODO: move
  const handleCopy = (value, title) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setNotificationData({ text: title, status: "success", isOpen: true });
  };

  const onChangeLanguage = (locale) => {
    setCurrentCryptoState((state) => ({
      ...state,
      locale,
    }));

    if (locale) {
      i18n.setLocaleIfPresent(locale);
    }
  };

  if (isCryptoWidget) {
    return (
      <CryptoWidget
        locale={locale || "en"}
        currentState={currentCryptoState}
        handleCopy={handleCopy}
        reference={reference}
        onChangeLanguage={onChangeLanguage}
      />
    );
  }

  return (
    <MainPage
      sessionId={sessionId}
      signature={signature}
      handleCopy={handleCopy}
    />
  );
};

export default withTransaction("MainPage", "component")(Main);
