import CustomerInput from "../CustomerInput";
import CardHolderInput from "../CardHolderInput";
import InputCard from "../inputCard";
import InputDate from "../inputDate";
import InputDoubleDates from "../inputDoubleDates";
import InputCvv from "../InputCvv";
import Dropdown from "../Dropdown";

const Field = ({
  name,
  label,
  autocomplete,
  autofocus,
  prefix,
  filterPattern,
  pattern,
  validationMessage,
  nonRequired,
  values,
  setValues,
  errors,
  setErrors,
  checkedCard,
  setCheckedCard,
  inputRefs,
  nextStep,
  cvvStep,
  cardHolderStep,
  theme,
  hiddenRememberMe,
  themeConfig,
  placeholder,
  inputOptions,
  options,
  staticDropdown,
  setRememberMe,
  onChange,
}) => {
  const inputRef = inputRefs && inputRefs.length ? inputRefs[0] : null;

  if (inputOptions?.type === "dropdown-async") {
    return (
      <Dropdown
        nameKey={name}
        placeholder={placeholder}
        label={label}
        autocomplete={autocomplete}
        autofocus={autofocus}
        pattern={pattern}
        nonRequired={nonRequired}
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        inputRef={inputRef}
        inputOptions={inputOptions}
        options={options}
        staticDropdown={staticDropdown}
        onChange={onChange}
      />
    );
  }

  switch (name) {
    case "cardHolderName":
      return (
        <CardHolderInput
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          inputRef={inputRef}
        />
      );
    case "cardNumber":
      return (
        <InputCard
          values={values}
          errors={errors}
          setErrors={setErrors}
          setValues={setValues}
          nextStep={nextStep}
          cvvStep={cvvStep}
          cardHolderStep={cardHolderStep}
          checkedCard={checkedCard}
          setCheckedCard={setCheckedCard}
          theme={theme}
          hiddenRememberMe={hiddenRememberMe}
          themeConfig={themeConfig}
          setRememberMe={setRememberMe}
        />
      );
    case "cardExpire":
      return (
        <InputDate
          values={values}
          errors={errors}
          setErrors={setErrors}
          setValues={setValues}
          inputRefMonth={inputRef}
          nextStep={nextStep}
          checkedCard={checkedCard}
        />
      );
    case "cardExpireDouble":
      return (
        <InputDoubleDates
          values={values}
          errors={errors}
          setErrors={setErrors}
          setValues={setValues}
          inputRefMonth={inputRefs[0]}
          inputRefYear={inputRefs[1]}
          nextStep={nextStep}
          checkedCard={checkedCard}
        />
      );
    case "cardCvv":
      return (
        <InputCvv
          values={values}
          errors={errors}
          setErrors={setErrors}
          setValues={setValues}
          inputRef={inputRef}
          nextStep={nextStep}
        />
      );
    default:
      return (
        <CustomerInput
          nameKey={name}
          placeholder={placeholder}
          label={label}
          autocomplete={autocomplete}
          autofocus={autofocus}
          prefix={prefix}
          filterPattern={filterPattern}
          pattern={pattern}
          validationMessage={validationMessage}
          nonRequired={nonRequired}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          inputRef={inputRef}
        />
      );
  }
};

export default Field;
