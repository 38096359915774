import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const CheckboxContent = styled.span`
  font-family: var(--regularFont);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  cursor: pointer;

  & > a {
    font-family: var(--regularFont);
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    letter-spacing: 0.0025em;
    text-transform: none;
    text-decoration: none;
    color: ${COLORS.BLUE};

    &:focus,
    &:target {
      color: ${COLORS.DEFAULT_BLACK};
    }
  }
`;

export const PrivacyPolicyCheckboxWrapper = styled.div`
  margin-top: 8px;
`;
