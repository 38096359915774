import { state } from "../../services";
import { PATTERNS } from "../../services/constants";

const challengeFormConfig = {
  pin: {
    label: "pin",
    name: "pin",
    type: "text",
    pattern: /^\d{4,12}$/,
    validationMessage: "validation.pin",
    supportedChallengeTypes: ["pin"],
  },
  card_pin: {
    label: "card_pin",
    name: "card_pin",
    type: "text",
    pattern: /^\d{4,12}$/,
    validationMessage: "validation.pin",
    supportedChallengeTypes: ["card_pin"],
  },
  otp: {
    label: "OTP",
    name: "otp",
    type: "text",
    pattern: /^\d{1,32}$/,
    supportedChallengeTypes: ["otp"],
  },
  country: {
    label: "country",
    name: "country",
    type: "text",
    pattern: /^[a-zA-Z0-9\s,\-/.]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  state_code: {
    label: "state_code",
    name: "state_code",
    type: "text",
    pattern: /^[a-zA-Z0-9\s]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  city: {
    label: "city",
    name: "city",
    type: "text",
    pattern: /^[a-zA-Z\s]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  address: {
    label: "address",
    name: "address",
    type: "text",
    pattern: /^[a-zA-Z0-9\s,\-/.]{1,64}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  zip_code: {
    label: "zip_code",
    name: "zip_code",
    type: "text",
    pattern: /^\d{1,16}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  phone_verification: {
    label: "phone",
    name: "phone",
    type: "tel",
    pattern: PATTERNS.PHONE,
    supportedChallengeTypes: ["phone_verification"],
  },
  blik_code: {
    label: "blikCodeLabel",
    name: "blik_code",
    type: "text",
    pattern: /^\d{6}$/,
    validationMessage: "blikCodeInvalid",
    placeholder: "123456",
    supportedChallengeTypes: ["blik_code"],
  },
  bank_code: {
    label: "choose_receiver_bank",
    name: "bank_code",
    type: "dropdown",
    validationMessage: "required",
    supportedChallengeTypes: ["bank_code"],
    inputOptions: {
      type: "dropdown",
      // TODO: Need remove 'from'
      from: "payment/bank/africa/ng",
      key: "code",
      showBankIcon: true,
      getOptions: ({ challenge }) => {
        return challenge?.bank_code?.bank_code || [];
      },
      onChange: ({ option, setValues }) => {
        setValues((prev) => ({
          ...prev,
          bank_code: option,
        }));
      },
      getMethodNameForIcon: () => {
        return state.getSelectedPaymentMethod()?.method;
      },
      display: ({ option }) => option?.name || "",
      inputDisplay: (value) => value?.name || "",
      search: ({ option, search }) => {
        if (search) {
          return (
            option.name.toLowerCase().includes(search.toLowerCase()) ||
            option.code.toLowerCase().includes(search.toLowerCase())
          );
        }

        return true;
      },
    },
  },
};

export default challengeFormConfig;
