export const COUNTRIES = [
  { name: "AT", subName: "Austria" },
  { name: "BE", subName: "Belgium" },
  { name: "CY", subName: "Cyprus" },
  { name: "DE", subName: "Germany" },
  { name: "DK", subName: "Denmark" },
  { name: "EE", subName: "Estonia" },
  { name: "ES", subName: "Spain" },
  { name: "FI", subName: "Finland" },
  { name: "FR", subName: "France" },
  { name: "GB", subName: "Great Britain" },
  { name: "HR", subName: "Croatia" },
  { name: "IE", subName: "Ireland" },
  { name: "IT", subName: "Italy" },
  { name: "LT", subName: "Lithuania" },
  { name: "LU", subName: "Luxembourg" },
  { name: "LV", subName: "Latvia" },
  { name: "NL", subName: "Netherlands" },
  { name: "NZ", subName: "New Zealand" },
  { name: "NO", subName: "Norway" },
  { name: "PT", subName: "Portugal" },
  { name: "SE", subName: "Sweden" },
  { name: "SI", subName: "Slovenia" },
  { name: "SK", subName: "Slovakia" },
  { name: "RO", subName: "Romania" },
  { name: "PL", subName: "Poland" },
  { name: "HU", subName: "Hungary" },
];
