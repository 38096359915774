import styled from "styled-components";

interface TooltipTextProps {
  $width?: string;
  $background?: string;
  $textAlign?: string;
  $fs?: string;
  $bottom?: string;
  $top?: string;
  $left?: string;
  $leftArrow?: string;
  $rotate?: string;
  $show: boolean;
}

export const TooltipWrapper = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const TooltipText = styled.span<TooltipTextProps>`
  visibility: hidden;
  width: ${({ $width }) => $width || "auto"};
  background-color: ${({ $background }) => $background || "black"};
  color: white;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  border-radius: 12px;
  padding: 12px;
  position: absolute;
  font-size: ${({ $fs }) => $fs || "16px"};
  z-index: 1;
  bottom: ${({ $bottom }) => $bottom || "140%"};
  left: ${({ $left }) => $left || "50%"};
  transform: translateX(-50%);
  transition: opacity 0.3s;
  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  opacity: ${({ $show }) => ($show ? "1" : "0")};
  transition:
    opacity 0.3s,
    visibility 0.3s;
  &:after {
    content: "";
    position: absolute;
    top: ${({ $top }) => $top || "100%"};
    left: ${({ $leftArrow }) => $leftArrow || "50%"};
    rotate: ${({ $rotate }) => $rotate || ""};
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ $background }) =>
      `${$background || "black"} transparent transparent transparent`};
  }
`;

export const TooltipIcon = styled.span`
  display: inline-block;
`;
