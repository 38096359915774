import asquadFooterIcon from "../../assets/icons/asquadFooterIcon.png";
import asquadLogoFooter from "../../assets/icons/asquadLogoFooter.png";
import kombineFooterIcon from "../../assets/icons/kombineFooterIcon.svg";
import { useColorScheme } from "../../services/hooks";
import { state } from "../../services";
import { FooterContainer } from "./style";

const Footer = ({ margin, background }) => {
  const options = state.get().options;

  const { colorTheme } = useColorScheme(options?.theme);

  const footerIconConfig = {
    default: asquadFooterIcon,
    kombine: kombineFooterIcon,
  };

  return (
    <FooterContainer $margin={margin} $background={background}>
      <img
        width="180px"
        height="24px"
        src={`${footerIconConfig[options?.theme] || asquadFooterIcon} `}
      />
      {colorTheme?.showSecurityIcon && (
        <img width="123px" height="24px" src={`${asquadLogoFooter}`} />
      )}
    </FooterContainer>
  );
};

export default Footer;
