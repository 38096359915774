import { state, logger } from "../services";
import controlFormHeader from "./FormHeader/FormHeaderControl";
import { useEffect, useContext, useRef } from "preact/hooks";
import { Container, FormAmountOfPaymentWrapper } from "../styles";
import { Context } from "../app";

const FormHeader = ({
  amount,
  currency,
  description,
  reference,
  detailedInfo,
  titleError,
  handleCopy,
  hasGradient,
  hasData,
  selectedMethod,
}) => {
  const { getFormattedAmountParts } = useContext(Context);

  const notificationRef = useRef(null);

  const renderAmount = () => {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return "";
  };

  const { wholeNumber, decimal } = getFormattedAmountParts(amount);

  const options = state.get().options;
  const ControlFormHeader =
    controlFormHeader[options?.theme] || controlFormHeader.default;

  useEffect(() => {
    if (!ControlFormHeader) {
      logger.error("Component not found");
    }
  }, []);

  return (
    <Container>
      <div>
        <FormAmountOfPaymentWrapper>
          <ControlFormHeader
            selectedMethod={selectedMethod}
            amount={amount}
            currency={currency}
            description={description}
            reference={reference}
            renderAmount={renderAmount}
            detailedInfo={detailedInfo}
            handleCopy={handleCopy}
            wholeNumber={wholeNumber}
            decimal={decimal}
            notificationRef={notificationRef}
            cancelUrl={options?.cancel_url}
            titleError={titleError}
            hasGradient={hasGradient}
            hasData={hasData}
            theme={options?.theme}
          />
        </FormAmountOfPaymentWrapper>
      </div>
    </Container>
  );
};

export default FormHeader;
