import { useEffect, useRef } from "preact/hooks";
import { state } from "../services";
import { PAYMENT_METHODS } from "../constants/paymentMethods";

interface IProps {
  status: boolean;
  action: () => void;
  timeout?: number;
  repeatTrigger?: boolean;
}

const MS = 1000;

const useTimer = ({
  status,
  action,
  timeout = 60 * MS,
  repeatTrigger = false,
}: IProps) => {
  const timerValueRef = useRef(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const selectedMethod = state.getSelectedPaymentMethod();

  if (selectedMethod === PAYMENT_METHODS.OPEN_BANKING) {
    timeout = 20 * MS;
  }

  const clearTimer = () => {
    clearInterval(timerRef.current!);
    timerValueRef.current = 0;
    timerRef.current = null;
  };

  const setTimer = () => {
    timerRef.current = setInterval(() => {
      if (timerValueRef.current >= timeout / MS - 1) {
        action();

        clearTimer();
      } else {
        timerValueRef.current += 1;
      }
    }, MS);
  };

  useEffect(() => {
    status && action && typeof action === "function"
      ? setTimer()
      : clearTimer();

    return () => clearTimer();
  }, [status, timeout]);

  useEffect(() => {
    if (repeatTrigger && timerRef.current == null && status) {
      setTimer();
    }
  }, [repeatTrigger, status]);
};

export default useTimer;
