import styled from "styled-components";

export const InfoBlockContainer = styled.div`
  background-color: #e5f0ff;
  margin-top: ${({ mt }) => mt || ""};
  padding: 12px 12px 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    text-align: center;
    font-weight: 700;
    font-family: var(--boldFont);
    color: ${(props) => props.theme?.mainColor};
  }
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const InfoBlockImage = styled.div`
  & svg {
    circle {
      fill: ${(props) => props.theme.mainColor};
    }
  }
`;

export const InfoBlockMainTitle = styled.span`
  margin-bottom: ${(props) => props.$mb || ""};
  margin-top: ${(props) => props.$mt || ""};
  width: 100%;
  font-family: var(--mediumFont);
  color: #101820;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.3px;
  letter-spacing: 0.01px;

  span {
    font-family: var(--boldFont);
    color: #101820;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.3px;
    letter-spacing: 0.01px;
  }

  display: ${(props) => (props.bankNameIcon ? "flex" : "")};
  align-items: center;
`;

export const FormFieldsTitle = styled.div`
  font-family: var(--mediumFont);
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  padding: 16px;
  margin-right: 16px;
  margin-right: 16px;

  @media (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
