import IconCopyKombine from "../../../assets/icons/iconCopyCombine";
import Trans from "../../Trans";
import {
  OuterBlockHeader,
  HeaderLogoWrapper,
  OuterInnerBlock,
  InnerHeaderDataWrapper,
  TotalInfoWrapper,
  ContainerDetailsWrapper,
  BlockDetailsWrapper,
  DetailsTransactionTitle,
  IconColorWrapper,
  TransactionSumTitle,
  TransactionDecimalTitle,
  TransactionCurrencyTitle,
  TransactionSumWrapper,
  TransactionSumContainer,
  CopyActionWrapper,
  DetailToggler,
  FormHeaderTitle,
} from "../../../styles";
import PaymentMethodsLogo from "../../PaymentMethods/PaymentMethodsLogo";
import { DISPLAY_PAYMENT_METHODS } from "../../../constants/paymentMethods";
import { PaymentMethodWrapper, PaymentMethodTitle } from "../style";
import Tooltip from "../../Tooltip/Tooltip";
import GoBackAction from "../../GoBackButton";
import { state, theme } from "../../../services";
import headerConfig from "../ChallengeHeaderFieldsConfig";
import ChallengeHeaderField from "../ChallengeHeaderField";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { truncateText } from "../../../services/utils";
import { Context } from "../../../app";

const ChallengeHeader = ({
  currentState,
  reference,
  wholeNumber,
  decimal,
  currency,
  handleCopy,
  canReturnToMethods,
  mobileMoneyPhone,
  selectedMethod,
  type,
  customer,
}) => {
  const [height, setHeight] = useState(0);

  const { onGoBackButtonClick } = useContext(Context);

  const ref = useRef(null);

  const options = state.get().options;
  const { cancel_url } = options || {};
  const currentTheme = theme.getThemeName(options?.theme);
  const { first_name, last_name, itn } = customer;

  const { isHideTransactionId, isHideDescription, isShowCurrencyCopy } =
    currentTheme || {};

  const headerFieldData = {
    type,
    currentState,
    first_name,
    last_name,
    itn,
    reference,
    mobileMoneyPhone,
    wholeNumber,
    decimal,
    currency,
    isHideTransactionId,
    isHideDescription,
    isShowCurrencyCopy,
  };

  useEffect(() => {
    setHeight(ref.current?.clientHeight || 0);
  }, [ref.current]);
  const [isDetailedInfo, setIsDetailedInfo] = useState(false);
  const [isTruncateText, setIsTruncateText] = useState(false);

  const onToggleDetailsClick = (event) => {
    event.preventDefault();

    setIsDetailedInfo(!isDetailedInfo);
  };

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper alignItems={"center"} pb="26px" display="flex">
        {cancel_url || canReturnToMethods ? (
          <GoBackAction
            onClick={onGoBackButtonClick}
            cancelUrl={cancel_url}
            canReturnToMethods={canReturnToMethods}
            cancelText={<Trans message="goBack" />}
            style={{ marginTop: "6px", position: "absolute" }}
          />
        ) : null}
        <FormHeaderTitle>Deposit</FormHeaderTitle>
      </HeaderLogoWrapper>
      <OuterInnerBlock $position={height} />
      <InnerHeaderDataWrapper $padding="20px" ref={ref}>
        <TotalInfoWrapper>
          <ContainerDetailsWrapper>
            <PaymentMethodWrapper>
              <PaymentMethodsLogo coloredIcon selectedMethod={selectedMethod} />
              <PaymentMethodTitle color="#FFFFFFE5">
                {DISPLAY_PAYMENT_METHODS[selectedMethod]}
              </PaymentMethodTitle>
            </PaymentMethodWrapper>

            {isTruncateText ? (
              <DetailToggler
                color="#FFFFFFE5"
                style={{ margin: "0 auto" }}
                onClick={onToggleDetailsClick}
              >
                {isDetailedInfo ? (
                  <Trans message="hideDetails" />
                ) : (
                  <Trans message="showDetails" />
                )}
              </DetailToggler>
            ) : null}
            {headerConfig.fields.map((field) => (
              <ChallengeHeaderField
                colorMainTitle="#FFFFFF99"
                titleFs="12px"
                amountFs="14px"
                colorSubtitle="#FFFFFFE5"
                key={field.key}
                field={field}
                data={headerFieldData}
                handleCopy={handleCopy}
                showAnotherIconCopy
                truncateText={(text) => truncateText(text, setIsTruncateText)}
                isDetailedInfo={isDetailedInfo}
              />
            ))}

            <BlockDetailsWrapper $mb="0">
              <DetailsTransactionTitle fw="500" $controlledLength>
                <Trans message="amount" />
              </DetailsTransactionTitle>
              <TransactionSumWrapper>
                <TransactionSumContainer>
                  <TransactionSumTitle fs="20px" color="#fff">
                    {wholeNumber}
                  </TransactionSumTitle>
                  {decimal && (
                    <TransactionDecimalTitle fs="20px" color="#fff">
                      .{decimal}
                    </TransactionDecimalTitle>
                  )}
                  <TransactionCurrencyTitle
                    fs="20px"
                    color="#FFFFFF99"
                    $ml="8px"
                  >
                    {currency}{" "}
                  </TransactionCurrencyTitle>
                </TransactionSumContainer>
                {isShowCurrencyCopy ? (
                  <CopyActionWrapper
                    onClick={() =>
                      handleCopy(
                        `${wholeNumber}${decimal ? `.${decimal}` : ""}`,
                        "amountCopy",
                      )
                    }
                  >
                    <IconColorWrapper>
                      <Tooltip text={"Copy"}>
                        <IconCopyKombine />
                      </Tooltip>
                    </IconColorWrapper>
                  </CopyActionWrapper>
                ) : null}
              </TransactionSumWrapper>
            </BlockDetailsWrapper>
          </ContainerDetailsWrapper>
        </TotalInfoWrapper>
      </InnerHeaderDataWrapper>
    </OuterBlockHeader>
  );
};

export default ChallengeHeader;
