import { memo } from "preact/compat";
import { useContext } from "preact/hooks";
import { Router } from "preact-router";

import Main from "../routes/main";
import Pending from "../routes/pending";
import Success from "../routes/success";
import Fail from "../routes/fail";
import Repay from "../routes/repay";
import Error from "../routes/error";
import { Context } from "../app";

const Routes = () => {
  const { handleRoute } = useContext(Context);

  return (
    <Router onChange={handleRoute}>
      <Main path="/:type/:sessionId/:signature/" />
      <Pending path="/:type/:sessionId/:signature/pending/" />
      <Success path="/:type/:sessionId/:signature/success/" />
      <Fail path="/:type/:sessionId/:signature/fail/" />
      <Repay path="/:type/:sessionId/:signature/repay/" />
      <Error path="/:type?/:sessionId?/:signature?/error/" default />
    </Router>
  );
};

export default memo(Routes);
