import { CurrencyCardContainer, InnerCardWrapper } from "./style";
import PaymentMethodsLogo from "./PaymentMethodsLogo";
import {
  DISPLAY_PAYMENT_METHODS,
  DISPLAY_PAYMENT_PROVIDERS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import { CHANNEL_BANK_NAME } from "../../constants/channelBankName";
import useApmTransaction from "../../hooks/useApmTransaction";
import { useContext } from "preact/hooks";
import { METHOD_TYPE_UNIQ_OPERATIONS } from "../../services/methodOperations";
import { state } from "../../services";
import { Context } from "../../app";
import { ContextUI } from "../../providers/UIProvider";
import ModalItem from "../Modals/ModalComponents/ModalItem";

const PaymentMethodsSelection = ({
  paymentLevel,
  data,
  groupedMethods,
  openModal,
  selectedMethod,
  setSelectedMethod,
}) => {
  const { createSpan } = useApmTransaction("PaymentFlow");

  const { currentState } = useContext(Context);
  const { handleCloseModal } = useContext(ContextUI);

  const onPaymentMethodSelected = ({ id }) => {
    const selectMethodSpan = createSpan("SelectMethod");

    state
      ?.onPaymentMethodSelected({
        id,
      })
      .finally(() => {
        selectMethodSpan?.end();
      });
  };

  const getPaymentMethodInfo = (item) => {
    const { channel, provider, currency, code, method } = item || {};

    if (channel) {
      return {
        title:
          channel?.name ||
          CHANNEL_BANK_NAME[channel?.ui_code] ||
          channel?.ui_code,
        name: channel.ui_code,
      };
    }

    if (provider && ![PAYMENT_METHODS.CRYPTO].includes(method)) {
      return {
        title: DISPLAY_PAYMENT_PROVIDERS[provider?.name] || provider.name,
        name: provider.name,
      };
    }

    if (code) {
      return {
        title: item.name,
        name: code,
      };
    }

    return {
      title: currency,
      name: currency,
    };
  };

  const onMethodClick = (methodType) => {
    let methodData = [];

    const filterFromMethod = METHOD_TYPE_UNIQ_OPERATIONS[methodType];

    if (filterFromMethod && state.get()?.status !== "failed_retry") {
      methodData = METHOD_TYPE_UNIQ_OPERATIONS[methodType].filter({
        currentState,
        data: groupedMethods[methodType],
      });
    } else {
      methodData = groupedMethods[methodType];
    }

    const content = methodData.map((item) => {
      const { id, currency, provider, channel, code, method } = item || {};
      const { title, name } = getPaymentMethodInfo(item);

      return {
        id,
        title,
        currency,
        name,
        isProvider: provider?.name,
        channel,
        isBankCode: code,
        hideIcon: method === "card",
        method,
      };
    });

    if (content.length === 1) {
      onPaymentMethodSelected({
        id: content[0].id,
      });
    } else {
      setSelectedMethod(methodType);
      openModal(content);
    }
  };

  const onProviderClick = (providerIdentifier) => {
    if (selectedMethod) {
      const providerData = groupedMethods[selectedMethod].filter(
        (item) =>
          item.provider && item.provider.identifier === providerIdentifier,
      );
      const content = providerData.map((item) => ({
        id: item.id,
        title: item.currency,
      }));

      openModal(content, providerIdentifier, selectedMethod);
    }
  };

  const handleClick = (id, title, currency) => {
    onPaymentMethodSelected({ id });

    if (currency !== title) {
      onProviderClick(id);
    }

    handleCloseModal();
  };

  return (
    <>
      {paymentLevel === "method"
        ? data.map((methodType) => (
            <CurrencyCardContainer
              key={methodType?.id}
              onClick={() => onMethodClick(methodType)}
              data-transaction-name="PaymentMethodSelected"
              disabled={currentState?.loadingData?.status}
            >
              <InnerCardWrapper>
                <PaymentMethodsLogo coloredIcon selectedMethod={methodType} />
                <span>{DISPLAY_PAYMENT_METHODS[methodType]}</span>
              </InnerCardWrapper>
            </CurrencyCardContainer>
          ))
        : data.map(
            ({
              id,
              title,
              currency,
              name,
              hideIcon,
              isBankCode,
              method,
              channel,
            }) => (
              <ModalItem
                key={id}
                data-transaction-name="ProviderCurrencySelected"
                handleClick={() => handleClick(id, title, currency)}
                title={title}
                name={name}
                withoutIcon={hideIcon}
                isBankCode={isBankCode}
                method={method}
                channel={channel}
              />
            ),
          )}
    </>
  );
};

export default PaymentMethodsSelection;
