import Trans from "../Trans";
import {
  SuccessPageBlockWrapper,
  SuccessPageWrapper,
  TransactionInfoFirstBlock,
  PaymentTitle,
  TransactionInfoSecondBlock,
  BaseButton,
  LogoBrandHeader,
} from "../../styles";
import colorSchema from "../../colorThemes/colorSchemes";
import brand from "../../services/brand";

const SuccessPageDefault = ({ onButtonClick, countdown }) => {
  const brandData = brand?.getBrand();
  const successIcon = colorSchema?.[brandData?.color_scheme]?.successIcon;

  return (
    <SuccessPageBlockWrapper>
      <SuccessPageWrapper>
        <TransactionInfoFirstBlock>
          <div style={{ position: "relative" }}>
            <LogoBrandHeader
              style={{
                width: "64px",
                height: "64px",
              }}
              src={`${successIcon}`}
            />
          </div>

          <PaymentTitle>
            <Trans message="success" />
          </PaymentTitle>
        </TransactionInfoFirstBlock>

        <TransactionInfoSecondBlock>
          <BaseButton
            textColor="#fff"
            data-role="back_to_store_button"
            onClick={onButtonClick}
            style={{ marginTop: "auto" }}
          >
            <Trans message="backToWebsite" /> {countdown && `(${countdown})`}
          </BaseButton>
        </TransactionInfoSecondBlock>
      </SuccessPageWrapper>
    </SuccessPageBlockWrapper>
  );
};

export default SuccessPageDefault;
