import FormContainer from "./FormContainer";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import Scroll from "../Scroll";

const MainPage = ({ sessionId, signature, handleCopy }) => {
  const isDesktop = false;

  return (
    <Scroll style={{ display: "flex", flexDirection: "column" }}>
      {isDesktop ? <PaymentMethods /> : null}
      <FormContainer
        sessionId={sessionId}
        signature={signature}
        handleCopy={handleCopy}
      />
    </Scroll>
  );
};

export default MainPage;
