// Checkbox.styles.js
import styled from "styled-components";

export const CustomCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 24px;
  padding-top: 8px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? "#fff" : "transparent")};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid ${(props) => (props.checked ? "#FFFFFF" : "#FFFFFF99")};
  cursor: pointer;
  position: relative;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #0e76a8;
  }

  svg {
    position: absolute;
    top: -1px;
    left: 0px;
    fill: none;
    stroke: black;
    stroke-width: 3px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const CheckboxLabel = styled.label`
  margin-left: 8px;
  color: white;
  cursor: pointer;
`;
