export const ITN_LABELS = {
  wallet_tpaga: "CC",
  wallet_chek: "RUN",
  wallet_mach: "RUN",
  wallet_todito: "CURP",
  cash_chile: "RUN",
  bank_transfer_khipu: "RUN",
  bank_transfer_chile: "RUN",
  bank_transfer_peru: "DNI",
  bank_transfer_argentina: "CUIL / CUIT / CDI",
  card_redirect: "ITN",
  mefete: "ITN",
  bank_transfer: "ITN",
  bank_transfer_turkey: "ITN",
  papara: "ITN",
  parolapara: "ITN",
  moneytolia: "ITN",
};
