import { useState } from "preact/hooks";
import { i18n } from "../../services";
import searchIcon from "../../assets/icons/search.svg";
import { DropdownSearch, DropdownSearchIcon, SearchInput } from "./style";

const Search = ({ onSearch }) => {
  const [search, setSearch] = useState("");

  const onInput = (e) => {
    setSearch(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <DropdownSearch>
      <SearchInput
        value={search}
        onInput={onInput}
        type={"text"}
        placeholder={i18n.getMessage({ message: "search" })}
      />
      <DropdownSearchIcon>
        <img src={searchIcon} alt="search" />
      </DropdownSearchIcon>
    </DropdownSearch>
  );
};

export default Search;
