import { InstructionStepDataSubtext } from "../../../components/Instruction/style";
import { api } from "../../../services";
import { IPaymentInstructions } from "./interfaces";

const accountRequisites: IPaymentInstructions = {
  title: "accountRequisitesTitleMakePayment",
  instructionList: [
    {
      title: "accountRequisitesCopyAccountDetails",
      getPaymentData: ({ data = {} }) => data?.account,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account,
        text: "accountRequisitesCopyAccountDetailsCopied",
      }),
    },
    {
      title: "accountRequisitesMakeTransfer",
      getPaymentData: ({ state = {} }) => (
        <>
          <InstructionStepDataSubtext>
            {state?.currency}
          </InstructionStepDataSubtext>{" "}
          {state?.amount}
        </>
      ),
      getCopyInfo: ({ state = {} }) => ({
        copy: state?.amount,
        text: "amountCopy",
      }),
    },
    // TODO: Refactor to use a translation function for handling text localization.
    {
      title: "accountRequisitesPressButton",
      secondaryTitle: {
        text: "paymentConfirmationSubtitleMadePayment",
        bold: true,
      },
    },
  ],
  getInfo: ({ data = {} }) =>
    !!data?.instruction ? "accountRequisitesMadeTransfer" : null,
  getExpirationDate: () => {
    const expirationDate = sessionStorage.getItem(
      "account_requisites_expiration_date",
    );

    if (!expirationDate) {
      const currentTime = new Date();
      const expirationTime = new Date(currentTime.getTime() + 10 * 60 * 1000);

      sessionStorage.setItem(
        "account_requisites_expiration_date",
        expirationTime.toISOString(),
      );
    }

    return sessionStorage.getItem("account_requisites_expiration_date");
  },
  submitAction: api.submitAccountRequisites,
};

export default accountRequisites;
