import { useState, useRef, useEffect, useContext, useMemo } from "preact/hooks";
import { logger, processing, state, api } from "../../services";
import FormFields, { getFields } from "./FormFields";
import Trans from "../Trans";
import RememberMe from "../ui/remember_me";
import { Context } from "../../app";
import {
  FormContainer,
  FormWrapper,
  FormInputActionWrapper,
  FormInputDateCvvWrapper,
  FormPayButton,
  TransactionSumTitle,
  TransactionDecimalTitle,
  FormPayTitle,
  FormLoaderWrapper,
} from "../../styles";
import {
  MANUALLY_PROCESSED_PAYMENT_METHODS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import Loader from "../Loaders";
import useApmTransaction from "../../hooks/useApmTransaction";
import { INFORMATION_ABOUT_PAYMENT_METHODS } from "../../constants/paymentMethodsInfo";
import useTimer from "../../hooks/useTimer";
import { ContextUI } from "../../providers/UIProvider";
import { themes } from "../../services/theme";
import PrivacyPolicyCheckbox from "../PrivacyPolicyCheckbox";
import ExchangeBlock from "../ExchangeBlock";

const Form = ({ onSubmit, fieldSources }) => {
  const {
    getFormattedAmountParts = () => {},
    themeConfig = {},
    currentState = {},
    onGoBackButtonClick,
  } = useContext(Context);

  const { notificationData, setNotificationData } = useContext(ContextUI);

  const {
    options,
    amount,
    customer,
    upi,
    virtual_account_request,
    mobile_money,
    hasSelectedMethod,
    currency,
    status,
    isPrivacyPolicyAgreed,
  } = currentState || {};

  const isFailedRetryStatus = useMemo(() => {
    return status === "failed_retry";
  }, [status]);

  const defaultCardHolder =
    customer?.last_name && customer?.first_name
      ? `${customer?.last_name} ${customer?.first_name}`
      : "";

  const selectedMethod = state.getSelectedPaymentMethod();

  const isMethodForAutoSubmit = ![
    PAYMENT_METHODS.APPLE_PAY,
    PAYMENT_METHODS.GOOGLE_PAY,
    PAYMENT_METHODS.CARD,
  ].includes(selectedMethod?.method);

  const areAllFieldsActive = isFailedRetryStatus && isMethodForAutoSubmit;

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [checkedCard, setCheckedCard] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const { createSpan } = useApmTransaction("PaymentFlow");
  const [values, setValues] = useState({
    cardHolder: defaultCardHolder,
    card: "",
    month: "",
    year: "",
    cvv: "",
    date: "",
    brand: "",
  });

  const { card, theme, cancel_url } = options || {};
  const { enabled_remember_me } = card || {};

  const hiddenRememberMe = !enabled_remember_me;
  const {
    replacedFormPaymentAmountButton,
    formButtonTitle,
    formButtonCurrency,
  } = themeConfig || {};
  const { wholeNumber, decimal } = getFormattedAmountParts(amount);

  const publicFieldsData = useMemo(
    () =>
      selectedMethod?.public_fields?.reduce((acc, item) => {
        acc = { ...acc, [item.name]: item.value };

        return acc;
      }, {}),
    [selectedMethod],
  );

  const { exchange_to, privacy_policy_link, terms_of_service_link } =
    publicFieldsData || {};

  const checkPrivacyPolicy = useMemo(
    () =>
      ((privacy_policy_link || terms_of_service_link) &&
        isPrivacyPolicyAgreed) ||
      (!privacy_policy_link && !terms_of_service_link),
    [isPrivacyPolicyAgreed, privacy_policy_link, terms_of_service_link],
  );

  const handleTimeout = () => {
    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "error",
      text: "cancelPaymentText",
      isCloseAutomatically: false,
      buttonConfig: {
        hasButton: true,
        buttonText: "cancelPayment",
        buttonAction: () => onGoBackButtonClick(cancel_url),
      },
    }));
  };

  useEffect(() => {
    if (!isLoading) {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: false,
        isCloseAutomatically: true,
        buttonConfig: null,
      }));
    }
  }, [isLoading]);

  useTimer({
    status: isLoading,
    action: handleTimeout,
    repeatTrigger: !notificationData?.isOpen,
  });

  const fields = getFields({
    sources: fieldSources,
    selectedMethod: selectedMethod?.method,
    selectedProvider: selectedMethod?.provider,
    theme,
  });

  const refSubmit = useRef();

  const processFormData = () => {
    let data = {};

    state.setLoading({
      status: true,
      withBlur:
        currentState?.available_methods?.length > 1 ||
        (currentState?.available_methods?.length === 1 &&
          !isMethodForAutoSubmit),
      eventName: "submit",
    });

    setIsDisabledButton(true);

    if (selectedMethod?.method === PAYMENT_METHODS.CARD) {
      if (checkedCard) {
        data.card = {
          cvv: values.cvv,
          token: checkedCard.token,
        };
      } else {
        data.card = {
          cvv: values.cvv,
          exp_month: values.date.slice(0, 2),
          exp_year: `20${values.date.slice(2, 4)}`,
          pan: values.card.replace(/\s/g, ""),
          brand: values.brand,
          holder_name: processing.sanitizeCardHolderName(values.cardHolder),
        };

        if (rememberMe) {
          data.card = { ...data.card, remember_me: true };
        }
      }
    } else {
      data = fields.reduce((acc, field) => {
        const fieldValue = values[field.name];

        if (!fieldValue) return acc;

        const key = field?.apiSourceName || field?.source;

        if (!acc[key]) {
          acc[key] = {};
        }

        if (typeof field?.formatBeforeSend === "function") {
          acc[key] = { ...acc[key], ...field.formatBeforeSend({ values }) };
        } else {
          acc[key][field.name] = fieldValue;
        }

        return acc;
      }, {});
    }

    if (selectedMethod?.method === PAYMENT_METHODS.BANK_CODE) {
      data.bank_code = selectedMethod?.code;

      if (data?.bank_code) {
        api.sendChallengeBankCodeData({
          bank_code: data.bank_code,
        });
      }
    }

    onSubmit({
      method: selectedMethod?.method,
      ...data,
    });

    return processing
      .processFormData({
        method: selectedMethod?.method,
        ...data,
      })
      .finally(() => {
        setIsDisabledButton(false);

        state.setLoading({ status: false, eventName: "submit" });
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const formSubmitSpan = createSpan("FormSubmit");

    if (!MANUALLY_PROCESSED_PAYMENT_METHODS.includes(selectedMethod?.method)) {
      logger.error("onFormSubmit: unsupported payment method", {
        method_id: selectedMethod?.id,
        method: selectedMethod?.method,
      });

      formSubmitSpan?.end();

      return;
    }

    if (
      processing.validate({
        values,
        errors,
        method: selectedMethod?.method,
        fields,
        isManuallyTriggered: true,
        areAllFieldsActive,
      }) &&
      checkPrivacyPolicy
    ) {
      processFormData().finally(() => {
        formSubmitSpan?.end();
      });
    } else {
      formSubmitSpan?.end();

      processing
        .getFieldsErrors({
          fields,
          values,
          areAllFieldsActive,
        })
        .forEach(({ key, value }) => {
          setErrors((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
    }
  };

  useEffect(() => {
    if (fields?.length && selectedMethod?.method !== "card") {
      const newValues = fields.reduce((acc, field) => {
        const sourceData = fieldSources[field.source];
        const alternativeSourceData = fieldSources[field.alternativeSource];

        if (sourceData) {
          acc[field.name] = sourceData[field.name];
        } else if (alternativeSourceData) {
          acc[field.name] = alternativeSourceData[field.name];
        }

        return acc;
      }, {});

      setValues((prev) => ({ ...prev, ...newValues }));
    }
  }, [
    JSON.stringify(fields),
    selectedMethod?.method,
    JSON.stringify(customer),
    JSON.stringify(upi),
    JSON.stringify(virtual_account_request),
    JSON.stringify(mobile_money),
  ]);

  useEffect(() => {
    const getDisableButtonStatus = themes[theme]?.getDisableButtonStatus;

    if (
      getDisableButtonStatus &&
      typeof getDisableButtonStatus === "function"
    ) {
      setIsDisabledButton(getDisableButtonStatus({ values, errors }));
    }
  }, [values, errors]);

  useEffect(() => {
    setIsLoading(!currentState?.loadingData?.status && !hasSelectedMethod);
  }, [hasSelectedMethod, currentState?.loadingData?.status]);

  // auto-submit
  useEffect(() => {
    if (!exchange_to && checkPrivacyPolicy) {
      const isFormCompleted = processing.validate({
        values,
        errors,
        method: selectedMethod?.method,
        fields,
        isManuallyTriggered: false,
      });

      if (
        hasSelectedMethod &&
        isFormCompleted &&
        isMethodForAutoSubmit &&
        !isFailedRetryStatus
      ) {
        processFormData();
      }
    }
  }, [hasSelectedMethod]);

  if (isLoading) {
    return (
      <FormLoaderWrapper>
        <Loader />
      </FormLoaderWrapper>
    );
  }

  return (
    <>
      <FormContainer>
        <FormWrapper>
          <form onSubmit={onFormSubmit}>
            {exchange_to ? <ExchangeBlock data={publicFieldsData} /> : null}

            <FormFields
              method={selectedMethod}
              fieldSources={fieldSources}
              area="general"
              refSubmit={refSubmit}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              checkedCard={checkedCard}
              setCheckedCard={setCheckedCard}
              theme={theme}
              hiddenRememberMe={hiddenRememberMe}
              themeConfig={themeConfig}
              setRememberMe={setRememberMe}
              isMethodForAutoSubmit={isMethodForAutoSubmit}
              isFailedRetryStatus={isFailedRetryStatus}
            />

            {selectedMethod?.method === PAYMENT_METHODS.CARD ? (
              <FormInputActionWrapper>
                <FormInputDateCvvWrapper>
                  <FormFields
                    method={selectedMethod}
                    area="dateCvv"
                    fieldSources={fieldSources}
                    refSubmit={refSubmit}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                    checkedCard={checkedCard}
                    setCheckedCard={setCheckedCard}
                    theme={theme}
                    hiddenRememberMe={hiddenRememberMe}
                    themeConfig={themeConfig}
                    setRememberMe={setRememberMe}
                  />
                </FormInputDateCvvWrapper>

                {!hiddenRememberMe &&
                selectedMethod?.method === PAYMENT_METHODS.CARD &&
                themeConfig?.innerInputRememberMeShow ? (
                  <RememberMe
                    checkedCard={checkedCard}
                    checked={rememberMe}
                    setRememberMe={setRememberMe}
                  />
                ) : null}
              </FormInputActionWrapper>
            ) : null}

            {privacy_policy_link || terms_of_service_link ? (
              <PrivacyPolicyCheckbox data={publicFieldsData} />
            ) : null}
          </form>
        </FormWrapper>

        {!hiddenRememberMe &&
        selectedMethod?.method === PAYMENT_METHODS.CARD &&
        !themeConfig?.innerInputRememberMeShow ? (
          <RememberMe
            checkedCard={checkedCard}
            checked={rememberMe}
            setRememberMe={setRememberMe}
          />
        ) : null}
      </FormContainer>

      <FormPayButton
        data-role="pay_button"
        name="cardFormPayButton"
        type="submit"
        disabled={isDisabledButton || !hasSelectedMethod || !checkPrivacyPolicy}
        onClick={onFormSubmit}
        ref={refSubmit}
        data-transaction-name="FormPayButton"
      >
        {!["card", "crypto"].includes(selectedMethod?.method) ? (
          <FormPayTitle>
            <Trans
              message={
                INFORMATION_ABOUT_PAYMENT_METHODS[selectedMethod?.method]
                  ?.payButtonText
                  ? INFORMATION_ABOUT_PAYMENT_METHODS[selectedMethod?.method]
                      ?.payButtonText
                  : "buttonPayTextDefault"
              }
            />
          </FormPayTitle>
        ) : (
          <>
            <FormPayTitle>
              <Trans message={formButtonTitle} />{" "}
            </FormPayTitle>
            {replacedFormPaymentAmountButton && (
              <span>
                <TransactionSumTitle>{wholeNumber}</TransactionSumTitle>
                {decimal && (
                  <TransactionDecimalTitle>.{decimal}</TransactionDecimalTitle>
                )}{" "}
                {formButtonCurrency && (
                  <TransactionDecimalTitle>{currency}</TransactionDecimalTitle>
                )}
              </span>
            )}
          </>
        )}
      </FormPayButton>
    </>
  );
};

export default Form;
