import InactiveRememberMeIcon from "../../assets/icons/js/InactiveRememberMeIcon.js";
import ActiveRememberMeIcon from "../../assets/icons/js/ActiveRememberMeIcon.js";
import { IconColorWrapper, WrapperRememberMe } from "../../styles";

const RememberMeIconDefault = ({ setRememberMe, checked, checkedCard }) => {
  return (
    <WrapperRememberMe
      style={{ cursor: checkedCard ? "initial" : "pointer" }}
      onClick={() => {
        if (checkedCard) {
          return;
        }

        setRememberMe((prev) => !prev);
      }}
    >
      <IconColorWrapper>
        {checkedCard || checked ? (
          <ActiveRememberMeIcon />
        ) : (
          <InactiveRememberMeIcon />
        )}
      </IconColorWrapper>
    </WrapperRememberMe>
  );
};

export default RememberMeIconDefault;
