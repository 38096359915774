import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const ScrollContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const ScrollContent = styled.div`
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollBar = styled.div`
  position: absolute;
  right: 4px;
  height: 100%;
  top: 0;
  width: 8px;
  background-color: transparent;
  opacity: ${({ $isHide }) => ($isHide ? "0" : "1")};
  display: ${({ $isNotExist }) => ($isNotExist ? "none" : "flex")};
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const ScrollThumb = styled.div`
  background-color: ${COLORS.DEFAULT_BLACK};
  width: ${({ $isDragging }) => ($isDragging ? "8px" : "4px")};
  border-radius: 4px;
  position: absolute;
  left: calc(100% - 4px);
  transition:
    width 0.3s,
    opacity 0.3s;

  &:hover {
    width: 8px;
    cursor: pointer;
  }
`;
