import { useContext, useEffect, useState } from "preact/hooks";
import CryptoForm from "../CryptoWidgetForm/CryptoForm";
import { Context } from "../../app";
import { state } from "../../services";
import { formatNumberWithSpaces } from "../CryptoWidgetForm/CryptoContent";
import { FormCryptoFieldsWrapper } from "./style";

interface IExchangeBlockProps {
  data: {
    exchange_to?: string;
  };
}

interface ICurrentState {
  amount: string;
  currency: string;
}

interface ICryptoDataState {
  amount?: string;
  currency?: string;
  getCurrency?: string;
  getAmount?: number;
  isAmountBelowMin?: boolean;
}

const EUR_TO_USDT_RATE = 1.056;
const FIXED_RISK_RATE = 2.5 * EUR_TO_USDT_RATE;

const ExchangeBlock = ({ data }: IExchangeBlockProps) => {
  const { currentState = {} as ICurrentState } = useContext(Context);

  const { amount, currency } = currentState || {};
  const { exchange_to } = data || {};

  const [cryptoData, setCryptoData] = useState<ICryptoDataState>({
    amount,
    currency,
    getCurrency: exchange_to,
    getAmount: 0,
  });

  useEffect(() => {
    if (!cryptoData?.getAmount && exchange_to) {
      state
        .getAndSaveConversion(
          {
            conversion_currency: currency,
            conversion_amount: amount,
            pay_currency: exchange_to,
          },
          { isInverted: false },
        )
        .then((res) => {
          setCryptoData({
            amount,
            currency,
            getCurrency: exchange_to,
            getAmount: (res as { data: { pay_amount: number } })?.data
              ?.pay_amount,
          });
        });
    }
  }, [currency, amount, exchange_to]);

  const getAmountCalculate = ({ amountInUsdt }: { amountInUsdt: number }) => {
    const variableRiskRate = 0.05 * amountInUsdt;
    const riskRate = Math.max(FIXED_RISK_RATE, variableRiskRate);
    const finalAmount = amountInUsdt - riskRate;

    return finalAmount;
  };

  return (
    <FormCryptoFieldsWrapper>
      <CryptoForm
        pay_currency={currency}
        currency={exchange_to}
        userInputData={{
          currency_amount: getAmountCalculate({
            amountInUsdt: cryptoData.getAmount || 0,
          }),
          pay_amount: amount,
        }}
        formatNumberWithSpaces={formatNumberWithSpaces}
        options={{
          copySendAmountEnabled: false,
          sendLabel: "youPay",
          receiveLabel: "youGet",
        }}
        handleInputChange={null}
        isAmountBelowMin={null}
        isLoading={{
          pay_amount: false,
          currency_amount: false,
        }}
      />
    </FormCryptoFieldsWrapper>
  );
};

export default ExchangeBlock;
