import {
  OuterBlockHeader,
  HeaderLogoWrapper,
  LogoLangWrapper,
  LogoBrandHeader,
} from "../../styles";
import engIcon from "../../assets/icons/enIcon.svg";
import uaIcon from "../../assets/icons/uaIcon.svg";
import ruIcon from "../../assets/icons/ruIcon.svg";
import ptIcon from "../../assets/icons/ptIcon.svg";
import kzIcon from "../../assets/icons/kzIcon.svg";
import { useLogo } from "../../services/hooks";
import { state } from "../../services";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import LanguageModal from "../Modals/LanguageModal";
import LANGUAGE_LIST from "../LanguageModalConfig";

const languageIcons = {
  en: engIcon,
  uk: uaIcon,
  ua: uaIcon,
  ru: ruIcon,
  pt: ptIcon,
  kz: kzIcon,
};

const CryptoHeader = ({ locale, onChangeLanguage }) => {
  const { setModalData } = useContext(ContextUI);
  const image = languageIcons[locale];
  const options = state.get().options;
  const logoIcon = useLogo(options?.theme);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: (
        <LanguageModal currentLocale={locale} onChange={onChangeLanguage} />
      ),
      title: "chooseLanguage",
      isFullView: LANGUAGE_LIST.length > 4,
    });
  };

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper>
        <LogoLangWrapper $hasLogo={logoIcon}>
          <LogoBrandHeader src={`${logoIcon}`} />
          <img onClick={handleOpen} src={`${image}`} />
        </LogoLangWrapper>
      </HeaderLogoWrapper>
    </OuterBlockHeader>
  );
};

export default CryptoHeader;
