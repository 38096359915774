import deleteCardIcon from "../../assets/icons/deleteCardIconAsquad.svg";
import {
  CardLabelNumberContainer,
  CardLabelNumber,
  CardLabelDate,
} from "../../styles";
import ModalItem from "../Modals/ModalComponents/ModalItem";
import { CARD_BRANDS } from "../../constants/cardBrand";

const CardListDefault = ({
  handleCheckSavedCard,
  card,
  isManage,
  setIsPrevRemoveCard,
  setCurrentCard,
  index,
}) => {
  return (
    <ModalItem
      icon={CARD_BRANDS[card?.brand]}
      arrowIcon={isManage && deleteCardIcon}
      dataRole={`card_${index + 1}`}
      arrowAction={
        isManage
          ? () => {
              setIsPrevRemoveCard(true);
              setCurrentCard(card);
            }
          : null
      }
      handleClick={() => handleCheckSavedCard(card)}
      iconWidth="32px"
    >
      <CardLabelNumberContainer>
        <CardLabelNumber>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
          ?.toString()
          ?.slice(4, 6)}** **** ${card?.last4}`}</CardLabelNumber>
      </CardLabelNumberContainer>
      <CardLabelDate>{`${card?.exp_month}/${
        card.exp_year?.length === 4
          ? card?.exp_year?.toString()?.slice(2, 4)
          : card.exp_year
      }`}</CardLabelDate>
    </ModalItem>
  );
};

export default CardListDefault;
