import Trans from "../Trans";
import {
  OuterBlockHeader,
  PaymentInfoWrapper,
  BaseButton,
  TransactionRefTitle,
  QrCodeWrapper,
} from "../../styles";
import { Context } from "../../app";
import { useContext } from "preact/hooks";

const ChallengeQrDefault = ({
  currentState,
  handleCopy,
  cancelUrl,
  selectedMethod,
}) => {
  const { challenge } = currentState;
  const { onGoBackButtonClick } = useContext(Context);

  const infoBlockTitle = {
    pix: "scanQrTitlePix",
    wallet_opay: "scanQrTitleOpay",
    wallet_opay_qr: "scanQrTitleOpay",
  };

  return (
    <>
      <OuterBlockHeader>
        <QrCodeWrapper>
          <img
            style={{ width: "200px" }}
            src={challenge?.qr?.image}
            alt="QR Code"
          />
        </QrCodeWrapper>
        <BaseButton
          onClick={() => handleCopy(challenge.qr?.code, "Code copied!")}
          marginTop="0"
          marginBottom="12px"
          textColor={"#fff"}
        >
          <Trans message="Copy" />
        </BaseButton>
        {cancelUrl && (
          <BaseButton
            data-role="back_to_store_button"
            marginBottom="22px"
            onClick={() => onGoBackButtonClick(cancelUrl)}
          >
            <Trans message="goBack" />
          </BaseButton>
        )}
        <PaymentInfoWrapper>
          <TransactionRefTitle>
            <Trans message={infoBlockTitle[selectedMethod]} />
          </TransactionRefTitle>
        </PaymentInfoWrapper>
      </OuterBlockHeader>
    </>
  );
};

export default ChallengeQrDefault;
