import { i18n } from "../services";

const Trans = ({ message, children = null, withoutWrapper = false }) => {
  const content = i18n.getMessage({ message, children });

  if (withoutWrapper) {
    return content;
  }

  return <span>{content}</span>;
};

export default Trans;
