import styled, { keyframes } from "styled-components";

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -100% 0;
  }
`;

const LoadingLine = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(to left, #00aaff, #00aaff 40%, #fff 50%, #fff);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 2s infinite linear;
  z-index: 9999;
`;

const LoaderLine = () => {
  return <LoadingLine />;
};

export default LoaderLine;
