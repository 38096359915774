import visa from "../assets/icons/visaBlue.svg";
import mastercard from "../assets/icons/newMc.svg";
import prostir from "../assets/icons/prostir.svg";
import amex from "../assets/icons/amex.svg";
import dinersclub from "../assets/icons/dinersClub.svg";
import verve from "../assets/icons/verveIcon.svg";
import jcb from "../assets/icons/jcb.png";

export const CARD_BRANDS = {
  visa,
  mastercard,
  prostir,
  verve,
  amex,
  dinersclub,
  jcb,
};
