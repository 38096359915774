export const FIELD_SOURCES = [
  "customer",
  "upi",
  "virtual_account_request",
  "mobile_money",
  "bank_transfer_africa",
  "bank_account",
  "wallet",
  "blik",
  "wallet_m10",
];
