import Trans from "../Trans";

const InputDoubleDateMono = ({
  style,
  focused,
  values,
  inputRefMonth,
  onInput,
  setFocused,
  onBlur,
  refYear,
}) => {
  return (
    <>
      <div class={style.formInputWrapper}>
        <label class={style.formLabelTitle}>
          <Trans message="monthTitle" />
        </label>
        <input
          class={`${style.formInput}  ${focused && style.formInputFocused}`}
          name="expireMM"
          id="expireMM"
          value={values.month}
          onInput={(e) => onInput(e, "month")}
          onFocus={() => setFocused(true)}
          onBlur={() => onBlur("month")}
          maxlength="2"
          max="12"
          placeholder="00"
          size="10"
          ref={inputRefMonth}
        />
      </div>

      <div class={style.formInputWrapper}>
        <label class={style.formLabelTitle}>
          {" "}
          <Trans message="yearTitle" />
        </label>
        <input
          class={`${style.formInput}  ${focused && style.formInputFocused}`}
          name="expireYY"
          id="expireYY"
          placeholder="00"
          maxlength="2"
          size="10"
          value={values.year}
          onInput={(e) => onInput(e, "year")}
          onFocus={() => setFocused(true)}
          onBlur={() => onBlur("year")}
          required
          ref={refYear}
        />
      </div>
    </>
  );
};

export default InputDoubleDateMono;
