import { TotalInfoWrapper, InnerHeaderDataWrapper } from "../../styles";
import {
  ChallengeMainTitle,
  StkChallengeSubTitle,
  StyledInfoImage,
  ChallengeInfoWrapper,
} from "./style";
import InfoIcon from "../../assets/icons/infoIcon";
import Trans from "../Trans";

const ChallengeStk = () => {
  return (
    <InnerHeaderDataWrapper
      style={{ alignItems: "start" }}
      $top="0"
      $padding="20px"
    >
      <ChallengeMainTitle $mb="16px">
        <Trans message="mobileMoneyMainTitle" />
      </ChallengeMainTitle>

      <TotalInfoWrapper>
        <StkChallengeSubTitle>
          <Trans message="mobileMoneySubTitle" />
        </StkChallengeSubTitle>
      </TotalInfoWrapper>
      <ChallengeInfoWrapper>
        <StyledInfoImage>
          <InfoIcon />
        </StyledInfoImage>
        <ChallengeMainTitle>
          <Trans message="mobileMoneyInfoTitle" />
        </ChallengeMainTitle>
      </ChallengeInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default ChallengeStk;
