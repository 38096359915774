import Trans from "../Trans";
import InfoIcon from "../../assets/icons/infoCombineIcon";
import {
  FormDescription,
  FormCvvWrapper,
  FormInputDate,
  InvalidCvvTitle,
} from "../../styles";
import Tooltip from "../Tooltip/Tooltip";

const InputCvvKombine = ({
  focused,
  errorChecking,
  onCvvBlur,
  onCvvInput,
  setFocused,
  values,
  inputRef,
  errors,
}) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <FormDescription display="flex" $focused={focused}>
          <Trans message="CVV code:" />
        </FormDescription>
        <div style={{ marginLeft: "4px" }}>
          <Tooltip
            background="#10366B"
            width="220px"
            fs="12px"
            bottom="-380%"
            textAlign="start"
            left="-300%"
            leftArrow="74%"
            top="-15%"
            rotate="180deg"
            text="Secure protocol with 256-bit encryption, located on the back of your card"
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
      <FormCvvWrapper>
        <FormInputDate
          data-role="card_cvv"
          $focused={focused}
          $errorDate={errorChecking()}
          type="password"
          autocomplete="cc-csc"
          value={values.cvv}
          id="cvv"
          onInput={onCvvInput}
          placeholder="CVV"
          onFocus={() => setFocused(true)}
          onBlur={onCvvBlur}
          pattern="[0-9\s]{3}"
          maxlength="3"
          size="3"
          ref={inputRef}
          data-transaction-name="InputCvv"
        />
      </FormCvvWrapper>

      {errorChecking() && (
        <InvalidCvvTitle>
          <Trans message={errors?.cvv} />
        </InvalidCvvTitle>
      )}
    </>
  );
};

export default InputCvvKombine;
