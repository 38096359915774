import BankInfo from "../../BankInfo/BankInfo";

const ChallengeP2cPhone = ({ data, handleCopy }) => {
  const { bank_name, phone, receiver_customer_name } = data;
  const bankNameIcon = `${bank_name?.toLowerCase()?.replace(/\s+/g, "_")}`;

  return (
    <>
      <BankInfo
        phone={phone}
        receiverName={receiver_customer_name}
        bankName={bank_name}
        handleCopy={handleCopy}
        bankNameIcon={bankNameIcon}
        mainTitle="bankAccountInfoTitle"
        hideInfo
      />
    </>
  );
};

export default ChallengeP2cPhone;
