import ApplePayTestButton from "../../components/ApplePayTestBtn";
import GooglePayTestButton from "../../components/GooglePayTestBtn";
import ApplePay from "../../components/applePayButton";
import GooglePay from "../../components/googlePayButton";
import { applePay, testData, deepEqual, state } from "../../services";
import { memo } from "preact/compat";
import { useEffect } from "preact/hooks";
import { AltMethodsWrapper } from "../../styles";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import Trans from "../Trans";
import { AltMethodsPaymentSubtitle } from "../PaymentMethods/style";

const AltMethods = memo(
  ({
    amount,
    currency,
    description,
    reference,
    method,
    options,
    status,
    themeConfig,
    apple_pay,
    google_pay,
    sessionId,
    signature,
    showForm,
  }) => {
    const { card } = options || {};
    const { enabled_apple_pay, enabled_google_pay } = card || {};
    const availablePaymentMethods = state.getAvailablePaymentMethods();
    const isTestMode = testData.isTestMode(sessionId, signature);

    const isShowAltMethods = ["opened", "failed_retry"].includes(status);

    const hiddenApplePay = themeConfig?.hiddenApplePay;
    const hiddenGooglePay = themeConfig?.hiddenGooglePay;

    // apple pay

    const hasApplePayForMethodSelect =
      availablePaymentMethods.some(
        (currentMethod) => currentMethod.method === PAYMENT_METHODS.APPLE_PAY,
      ) && !method;

    const hasGooglePayForMethodSelect =
      availablePaymentMethods.some(
        (currentMethod) => currentMethod.method === PAYMENT_METHODS.GOOGLE_PAY,
      ) && !method;

    const hasApplePayButton =
      (method === PAYMENT_METHODS.CARD && enabled_apple_pay) ||
      method === PAYMENT_METHODS.APPLE_PAY ||
      hasApplePayForMethodSelect;

    const isAllowedToShowApplePayButton = applePay.isAllowedToShow();

    const hasApplePayError =
      hasApplePayButton &&
      (!isAllowedToShowApplePayButton || (apple_pay && apple_pay.error));

    const applePayIdentity =
      apple_pay &&
      !hasApplePayError &&
      apple_pay.identity &&
      apple_pay.identity.data;

    const applePaySession =
      apple_pay && apple_pay.session && apple_pay.session.data;

    // google pay

    const hasGooglePayButton =
      (method === PAYMENT_METHODS.CARD && enabled_google_pay) ||
      method === PAYMENT_METHODS.GOOGLE_PAY ||
      hasGooglePayForMethodSelect;

    const hasGooglePayError = google_pay && google_pay.error;

    const hasAltMethod = hasGooglePayButton && hasApplePayButton;

    const googlePayIdentity =
      google_pay &&
      !hasGooglePayError &&
      google_pay.identity &&
      google_pay.identity.data;

    const currentLocale = options?.locale;

    const localesMapApple = {
      en: "en-US",
      ru: "ru-RU",
      ua: "uk-UA",
      pt: "pt-PT",
    };
    const localesMapGoogle = {
      en: "en",
      ru: "ru",
      ua: "uk",
      pt: "pt",
    };

    const appleLocale = localesMapApple[currentLocale] || "en-US";

    const googleLocale = localesMapGoogle[currentLocale] || "en";

    const isNeedToShowApplePayButton =
      hasApplePayButton && !hiddenApplePay && isShowAltMethods;

    useEffect(() => {
      if (
        isNeedToShowApplePayButton &&
        isAllowedToShowApplePayButton &&
        applePayIdentity &&
        !isTestMode
      ) {
        applePay.initializeApplePay();
      }
    }, [
      isNeedToShowApplePayButton,
      isAllowedToShowApplePayButton,
      applePayIdentity,
      isTestMode,
    ]);

    const ApplePayButton = () => {
      if (isTestMode) {
        return <ApplePayTestButton />;
      }

      return (
        <ApplePay
          locale={appleLocale}
          amount={amount}
          currency={currency}
          description={description}
          identity={applePayIdentity}
          reference={reference}
          session={applePaySession}
        />
      );
    };

    const GooglePayButton = () => {
      if (isTestMode) {
        return <GooglePayTestButton />;
      }

      return (
        <GooglePay
          amount={amount}
          locale={googleLocale}
          currency={currency}
          description={description}
          identity={googlePayIdentity}
        />
      );
    };

    return (
      <>
        <AltMethodsWrapper $hasAltMethod={hasAltMethod && method !== "card"}>
          {isNeedToShowApplePayButton &&
          ((isAllowedToShowApplePayButton && applePayIdentity) ||
            isTestMode) ? (
            <ApplePayButton />
          ) : null}

          {isShowAltMethods &&
            hasGooglePayButton &&
            googlePayIdentity &&
            !hiddenGooglePay && <GooglePayButton />}
        </AltMethodsWrapper>
        {((isNeedToShowApplePayButton && applePayIdentity) ||
          (hasGooglePayButton && googlePayIdentity)) &&
          showForm && (
            <AltMethodsPaymentSubtitle>
              <Trans message="orPayWithCard" />
            </AltMethodsPaymentSubtitle>
          )}
      </>
    );
  },
  deepEqual,
);

export default AltMethods;
