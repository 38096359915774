export const CHANNEL_BANK_NAME = {
  banco_estado: "BancoEstado",
  banco_falabella: "Banco Falabella",
  banco_santander: "Banco Santander",
  banco_de_chile: "Banco de Chile (Edwards Citi)",
  banco_bci: "Banco BCI",
  scotiabank: "Scotiabank",
  banco_itau: "Banco Itaú",
  coopeuch: "Coopeuch",
  banco_ripley: "Banco Ripley",
  banco_bice: "Banco BICE",
  banco_security: "Banco Security",
  banco_consorcio: "Banco Consorcio",
  banco_bice_empresas: "Banco BICE Empresas",
  prepago_los_héroes: "Prepago Los Héroes",
  banco_consorcio_empresas: "Banco Consorcio Empresas",
  banco_de_chile_empresas: "Banco de Chile Empresas",
  bancoestado_empresas: "BancoEstado Empresas",
  banco_internacional_empresas: "Banco Internacional Empresas",
  banco_santander_office_banking: "Banco Santander Office Banking",
  banco_security_empresas: "Banco Security Empresas",
  bci_empresarios: "BCI Empresarios",
  bci_empresas: "BCI Empresas",
  itau_empresas: "Itaú Empresas",
  scotiabank_empresas: "Scotiabank Empresas",
  wema_bank: "Wema bank",
  wema: "Wema",
  globus_bank: "Globus bank",
  providus_bank: "Providus bank",
  mpesa: "MPESA",
  airtel: "Airtel",
  mtn: "MTN",
  tigo: "TIGO",
  vodafone: "Vodafone",
  equitel: "Equitel",
  cepbank: "Cepbank",
  aninda_havale: "Anında Havale",
  vip_havale: "VIP Havale",
  express_havale: "Express Havale",
  access_bank: "Access Bank",
  "asosavings_&_loan": "ASOSavings & Loan",
  "nigeria_int'l_bank": "Nigeria Int'l Bank (CITIGROUP",
  diamond_bank: "Diamond Bank",
  ecobank_nigeria: "Ecobank Nigeria Plc",
  enterprise_bank: "Enterprise Bank",
  first_city_monument_bank: "FCMB (First City Monument Bank PLC)",
  fidelity_bank: "Fidelity Bank",
  first_bank_of_nigeria: "First Bank Of Nigeria",
  guaranty_trust_bank: "Guaranty Trust Bank",
  heritage_banking: "Heritage Banking",
  jaiz_bank: "Jaiz Bank Plc",
  keystone_bank: "Keystone Bank",
  parkway_bank: "Parkway Bank",
  polaris_bank: "Polaris Bank Plc",
  "stanbic-ibtc_bank": "Stanbic-ibtc Bank Plc",
  standard_chartered_bank: "Standard Chartered Bank",
  sterling_bank: "Sterling Bank",
  united_bank_for_africa: "United Bank For Africa",
  union_bank_of_nigeria: "Union Bank Of Nigeria",
  unity_bank: "Unity Bank Plc",
  zenith_bank: "Zenith Bank",
  "e-money": "E-Money",
  free_money: "Free-Money",
  orange: "Orange Money",
  wave: "Wave",
  mobi_cash: "Mobi Cash",
  moov_money: "Moov Money",
};
