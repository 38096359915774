import { COLORS } from "../../../constants/themeConstants";

const ArrowIcon = ({ color = COLORS.BLUE }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.45085 5.48919C2.73296 5.1859 3.20752 5.16873 3.51081 5.45085L8 9.62658L12.4892 5.45085C12.7925 5.16873 13.267 5.1859 13.5492 5.48919C13.8313 5.79248 13.8141 6.26705 13.5108 6.54916L8.51081 11.2C8.22292 11.4678 7.77709 11.4678 7.48919 11.2L2.48919 6.54916C2.1859 6.26705 2.16873 5.79248 2.45085 5.48919Z"
      fill={color}
    />
  </svg>
);

export default ArrowIcon;
