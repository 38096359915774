import Trans from "../Trans";
import { useState } from "preact/hooks";
import infoIcon from "../../assets/icons/dateTooltipIcon.svg";
import {
  FormSelectWrapper,
  FormDescription,
  FormInputDateWrapper,
  FormInputDate,
  DateInvalidTitle,
  TooltipDateWrapper,
  TooltipDateText,
  FormIconDateTooltip,
  CvvTooltipMainTitle,
} from "../../styles";

const InputDateDefault = ({
  errorChecking,
  addMask,
  onDateInput,
  setFocused,
  onDateBlur,
  inputRefMonth,
  focused,
  values,
  errors,
  showDateTooltip,
  currentTheme,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <FormSelectWrapper style={{ display: "block" }}>
        <FormDescription $focused={focused}>
          <Trans message={currentTheme?.inputDateLabel} />
        </FormDescription>
        <FormInputDateWrapper>
          <FormInputDate
            data-role="card_expire_date"
            $focused={focused}
            $errorDate={errorChecking()}
            type="text"
            autocomplete="cc-exp"
            value={addMask(values.date)}
            id="date"
            onInput={onDateInput}
            onFocus={() => setFocused(true)}
            onBlur={onDateBlur}
            placeholder="MM/YY"
            maxlength="10"
            size="10"
            ref={inputRefMonth}
            data-transaction-name="DateInput"
          />
        </FormInputDateWrapper>
        {showDateTooltip && (
          <TooltipDateWrapper
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            <FormIconDateTooltip src={infoIcon} />

            {tooltipVisible && (
              <TooltipDateText>
                <CvvTooltipMainTitle>
                  <Trans message="dateTooltip" />
                </CvvTooltipMainTitle>
              </TooltipDateText>
            )}
          </TooltipDateWrapper>
        )}

        {errorChecking() && (
          <DateInvalidTitle>
            <Trans message={errors?.date} />
          </DateInvalidTitle>
        )}
      </FormSelectWrapper>
    </>
  );
};

export default InputDateDefault;
