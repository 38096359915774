import { useState, useEffect } from "preact/hooks";
import { state, i18n, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import controlsCvv from "./InputCvv/InputCvvControl";
import controlCvvTooltip from "./InputCvvTooltip/InputCvvTooltipControl";

import {
  FormSelectCvvWrapper,
  FormErrorWrapper,
  FormErrorTitleCvv,
} from "../styles";

const REQUIRED_LENGTH = 3;

const InputCvv = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  nextStep,
}) => {
  const [focused, setFocused] = useState(false);

  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);
  const ControlCvv = controlsCvv[options?.theme] || controlsCvv.default;
  const ControlCvvTooltip =
    controlCvvTooltip[currentTheme] || controlCvvTooltip.default;

  useEffect(() => {
    if (!ControlCvv || !ControlCvvTooltip) {
      logger.error("Component not found");
    }
  }, []);

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev.cvv;

      return prev;
    });
  };

  const onCvvInput = (e) => {
    let val = e.target.value.replace(/\s/g, "").replace(/[^\d]/g, "");

    if (val.length === REQUIRED_LENGTH) {
      clearErrors();

      setTimeout(() => {
        nextStep && nextStep.current && nextStep.current.focus();
      }, 0);
    }

    setValues((prev) => ({ ...prev, cvv: val }));
  };

  const onCvvBlur = () => {
    setFocused(false);

    if (values.cvv.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        cvv: CONST_ERROR_LIST.required,
      }));
    }

    if (values.cvv.length < REQUIRED_LENGTH) {
      return setErrors((prev) => ({
        ...prev,
        cvv: CONST_ERROR_LIST.wrong_cvv,
      }));
    }

    clearErrors();
  };

  const errorChecking = () => {
    if (!errors.cvv) {
      return false;
    }

    if (focused) {
      return false;
    }

    return true;
  };

  const renderCvvTooltip = () => <ControlCvvTooltip />;

  return (
    <FormSelectCvvWrapper>
      <ControlCvv
        focused={focused}
        errorChecking={errorChecking}
        onCvvBlur={onCvvBlur}
        onCvvInput={onCvvInput}
        setFocused={setFocused}
        values={values}
        inputRef={inputRef}
        renderCvvTooltip={renderCvvTooltip}
        errors={errors}
        currentTheme={currentTheme}
      />
      {errorChecking() && (
        <FormErrorWrapper>
          <div>
            <FormErrorTitleCvv>
              {i18n.getMessage({ message: errors.cvv })}
            </FormErrorTitleCvv>
          </div>
        </FormErrorWrapper>
      )}
    </FormSelectCvvWrapper>
  );
};

export default InputCvv;
