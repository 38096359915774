import CustomerInput from "../CustomerInput";
import Timer from "./ChallengeSubmitFormTimer";
import { ChallengeInputWrapper } from "../../styles";
import ChallengeFormSubmitButton from "./ChallengeFormSubmitButton";
import { state } from "../../services";
import Dropdown from "../Dropdown";
import {
  PAYMENT_METHOD_PROVIDERS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";

const ChallengeFormInputs = ({
  challengeFormConfig,
  challengeType,
  values,
  setValues,
  errors,
  setErrors,
  sessionId,
  signature,
  handleSubmit,
  challenge,
  isDisabled,
}) => {
  const selectedMethod = state.getSelectedPaymentMethod();

  const isTimerVisible = (challengeType) => {
    const excludedMethods = [PAYMENT_METHODS.BANK_ACCOUNT_AFRICA];
    const excludedProviders = [
      PAYMENT_METHOD_PROVIDERS.OPAY,
      PAYMENT_METHOD_PROVIDERS.PAYMENTO,
    ];

    return (
      challengeType === "otp" &&
      !excludedMethods.includes(selectedMethod?.method) &&
      !excludedProviders.includes(selectedMethod?.provider?.name)
    );
  };

  return (
    <ChallengeInputWrapper>
      {Object.keys(challengeFormConfig).map((fieldName) => {
        const fieldConfig = challengeFormConfig[fieldName];

        const { name, placeholder, label, inputOptions } = fieldConfig || {};

        if (
          fieldConfig.supportedChallengeTypes &&
          !fieldConfig.supportedChallengeTypes.includes(challengeType)
        ) {
          return null;
        }

        if (fieldConfig.type === "dropdown") {
          return (
            <div key={fieldName}>
              <Dropdown
                nameKey={name}
                placeholder={placeholder}
                label={label}
                inputOptions={inputOptions}
                options={inputOptions?.getOptions({ challenge })}
                staticDropdown
                showBankIcon={inputOptions?.showBankIcon}
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
              />
            </div>
          );
        }

        return (
          <div key={fieldName}>
            <CustomerInput
              nameKey={fieldConfig.name}
              label={fieldConfig.label}
              type={fieldConfig.type}
              pattern={fieldConfig.pattern}
              validationMessage={fieldConfig.validationMessage}
              placeholder={fieldConfig.placeholder}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
        );
      })}
      {isTimerVisible(challengeType) && (
        <Timer
          initialSeconds={30}
          challengeType={challengeType}
          sessionId={sessionId}
          signature={signature}
        />
      )}
      <ChallengeFormSubmitButton
        handleSubmit={handleSubmit}
        isDisabled={isDisabled}
      />
    </ChallengeInputWrapper>
  );
};

export default ChallengeFormInputs;
