import { changeLocale } from "../../services/state";
import LANGUAGE_LIST from "../LanguageModalConfig";
import { useContext, useEffect, useState } from "preact/hooks";
import ModalItem from "./ModalComponents/ModalItem";
import { ContextUI } from "../../providers/UIProvider";
import { LanguageItemWrapper } from "./style";

const LanguageModal = ({ onChange, currentLocale }) => {
  const [activeLocale, setActiveLocale] = useState(null);
  const { handleCloseModal } = useContext(ContextUI);

  useEffect(() => {
    setActiveLocale(currentLocale);
  }, [currentLocale]);

  const setLocale = (locale) => {
    if (onChange) {
      onChange(locale);
    } else {
      changeLocale(locale);
    }

    setActiveLocale(locale);
    handleCloseModal();
  };

  return (
    <>
      {LANGUAGE_LIST.map(({ locale, icon, title }) => (
        <LanguageItemWrapper key={locale}>
          <ModalItem
            isActive={activeLocale === locale}
            handleClick={() => setLocale(locale)}
            title={title}
            icon={icon}
          />
        </LanguageItemWrapper>
      ))}
    </>
  );
};

export default LanguageModal;
