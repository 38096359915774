import { FormLoaderWrapper, BaseButton, ButtonWrapper } from "../../styles";
import Loader from "../Loaders";
import Trans from "../Trans";
import BankInfo from "../BankInfo/BankInfo";
import { useContext } from "preact/hooks";
import { Context } from "../../app";

const VirtualAccount = ({ selectedVirtualAccount, handleCopy }) => {
  const { virtual_account } = selectedVirtualAccount || {};
  const { provider_bank_name, name, number } = virtual_account || {};
  const { currentState = {}, onGoBackButtonClick } = useContext(Context);
  const { options } = currentState || {};
  const cancelUrl = options?.redirect_url;

  const bankNameIcon = `${provider_bank_name
    ?.toLowerCase()
    ?.replace(/\s+/g, "_")}`;

  return provider_bank_name ? (
    <>
      <BankInfo
        bankNameIcon={bankNameIcon}
        bankName={provider_bank_name}
        accountName={name}
        accountNumber={number}
        handleCopy={handleCopy}
        mainTitle="virtualAccountTitle"
      />
      {cancelUrl ? (
        <ButtonWrapper>
          <BaseButton
            data-role="back_to_store_button"
            onClick={() => onGoBackButtonClick(cancelUrl)}
          >
            <Trans message="goBack" />
          </BaseButton>
        </ButtonWrapper>
      ) : null}
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default VirtualAccount;
