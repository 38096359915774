import Trans from "../Trans";
import {
  FormInputContainer,
  FormInput,
  LabelBrandWrapper,
  InvalidCardErrorTitle,
} from "../../styles";
import inputSavedCardControl from "../InputSavedCardAction/InputSavedCardControl";

const InputCardDefault = ({
  errorChecking,
  focused,
  conditionForShowArrow,
  onCardBlur,
  values,
  onCardInput,
  setFocused,
  generateIconCard,
  openModal,
  errors,
  placeholder,
  themeName,
}) => {
  const currentScreenWidth = window.innerWidth;

  const ControlInputSavedCard =
    inputSavedCardControl[themeName] || inputSavedCardControl.default;

  const smallMobileButton =
    (currentScreenWidth >= 320 && currentScreenWidth <= 340) ||
    conditionForShowArrow;

  return (
    <>
      <FormInputContainer>
        <FormInput
          data-role="card_number"
          $focused={focused}
          $errorCard={errorChecking("card")}
          value={values.card}
          onInput={onCardInput}
          onFocus={() => setFocused(true)}
          onBlur={onCardBlur}
          id="ccn"
          type="tel"
          inputmode="numeric"
          pattern="[0-9*\s]{16,22}"
          autocomplete="cc-number"
          maxlength="22"
          size="22"
          placeholder={placeholder}
          data-transaction-name="CardInput"
        />
        <LabelBrandWrapper display={smallMobileButton}>
          {generateIconCard(values.brand)}
        </LabelBrandWrapper>
      </FormInputContainer>

      <ControlInputSavedCard
        conditionForShowArrow={conditionForShowArrow}
        smallMobileButton={smallMobileButton}
        openModal={openModal}
      />

      {errorChecking("card") && (
        <InvalidCardErrorTitle>
          <Trans message={errors?.card} />
        </InvalidCardErrorTitle>
      )}
    </>
  );
};

export default InputCardDefault;
