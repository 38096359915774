import Header from "../../Header";
import PaymentDetails from "../../PaymentDetails";

const ChallengeHeaderUssd = () => {
  return (
    <>
      <Header hasGradient />
      <PaymentDetails />
    </>
  );
};

export default ChallengeHeaderUssd;
