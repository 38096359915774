import { useState, useRef, useEffect } from "preact/hooks";
import { state, processing, i18n, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import controlDateInput from "../components/InputDoubleDate/InputDoubleDateControl";

const InputDoubleDates = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRefMonth,
  nextStep,
  style,
}) => {
  const [focused, setFocused] = useState(false);
  const refYear = useRef();
  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);
  const ControlInputDate =
    controlDateInput[currentTheme] || controlDateInput.default;

  useEffect(() => {
    if (!ControlInputDate) {
      logger.error("Component not found");
    }
  }, []);

  const onInput = (e, name) => {
    let val = e.target.value.replace(/\s/g, "").replace(/[^\d]/g, "");

    if (
      name === "year" ||
      name === "month" ||
      (val.length === 2 && processing.checkInterval(val))
    ) {
      setValues((prev) => ({ ...prev, [name]: val }));
    }

    if (name === "month" && val.length === 2) {
      if (!processing.checkInterval(val)) {
        return setErrors((prev) => ({
          ...prev,
          month: CONST_ERROR_LIST.wrong_date,
        }));
      }

      setTimeout(() => {
        refYear && refYear.current && refYear.current.focus();
      }, 0);
    }

    if (
      (name === "month" && val.length === 2 && values.year.length === 2) ||
      (name === "year" && val.length === 2 && values.month.length === 2)
    ) {
      if (
        !processing.checkDates(
          name === "month" ? val : values.month,
          name === "year" ? val : values.year,
        )
      ) {
        return setErrors((prev) => ({
          ...prev,
          month: CONST_ERROR_LIST.wrong_date,
          year: CONST_ERROR_LIST.wrong_date,
        }));
      }

      setTimeout(() => {
        nextStep && nextStep.current && nextStep.current.focus();
      }, 0);

      return setErrors((prev) => {
        delete prev.month;
        delete prev.year;

        return prev;
      });
    }
  };

  useEffect(() => {
    const valuesDataStr = `${values.month}${values.year}`;

    if (
      values.month &&
      values.year &&
      valuesDataStr !== values.date &&
      valuesDataStr.length === 4
    ) {
      setValues((prev) => ({
        ...prev,
        date: `${values.month}${values.year}`,
      }));
    }
  }, [values.month, values.year]);

  useEffect(() => {
    if (
      values.date.length === 4 &&
      (values.date.slice(0, 2) !== values.month ||
        values.date.slice(2, 4) !== values.year)
    ) {
      setValues((prev) => ({
        ...prev,
        month: values.date.slice(0, 2),
        year: values.date.slice(2, 4),
      }));
    }
  }, [values.date]);

  const onBlur = (name) => {
    setFocused(false);

    if (values[name].length === 0) {
      return setErrors((prev) => ({
        ...prev,
        [name]: CONST_ERROR_LIST.required,
      }));
    }

    if (values[name].length < 2) {
      return setErrors((prev) => ({
        ...prev,
        [name]: CONST_ERROR_LIST.wrong_date,
      }));
    }

    if (values.month.length === 2 && !processing.checkInterval(values.month)) {
      return setErrors((prev) => ({
        ...prev,
        month: CONST_ERROR_LIST.wrong_date,
      }));
    }

    if (values.month.length === 2 && values.year.length === 2) {
      if (!processing.checkDates(values.month, values.year)) {
        return setErrors((prev) => ({
          ...prev,
          [name]: CONST_ERROR_LIST.wrong_date,
        }));
      }

      return setErrors((prev) => {
        delete prev.month;
        delete prev.year;

        return prev;
      });
    }

    return setErrors((prev) => {
      delete prev[name];

      return prev;
    });
  };

  const errorChecking = () => {
    if (errors.month || errors.year) {
      if (focused) {
        return (
          (values.month.length === 2 &&
            !processing.checkInterval(values.month)) ||
          (values.month.length === 2 && values.year.length === 2)
        );
      }

      return true;
    }

    return false;
  };

  return (
    <div class={style.formSelectWrapper}>
      <ControlInputDate
        style={style}
        errorChecking={errorChecking}
        setFocused={setFocused}
        inputRefMonth={inputRefMonth}
        focused={focused}
        values={values}
        options={options}
        onBlur={onBlur}
        onInput={onInput}
        refYear={refYear}
      />
      {errorChecking() && (
        <div
          class={`${style.formErrorWrapper} ${style.formErrorMonoDateWrapper}`}
        >
          <span class={style.formErrorTitle}>
            {errors.month
              ? i18n.getMessage({ message: errors.month })
              : i18n.getMessage({ message: errors.year })}
          </span>
        </div>
      )}
    </div>
  );
};

export default InputDoubleDates;
